import React, {
  Fragment,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';

import ComboBox from '../../../../../../../../../../components/ComboBox';
import ItemsPicker from '../../../../../../../../../../components/ItemsPicker';
import { selectYieldDataset } from '../../../../../../../../../field/fieldSelectors';
import {
  selectCleanTargetAttribute,
  selectCleanMinMaxConditions,
  selectCleanExcludedAttributes,
  selectDatasetUuid,
} from '../../../../../../../cleanCalibrateSelectors';
import {
  createFullAttributeItem,
  getComboBoxOptionSelected,
  transformCleanMinMaxConditions,
} from '../../../../../../../helpers/functions/ui';
import type { FullAttributeItem } from '../../../../../../../types/ui';
import type { FullAttribute } from '../../../../../../../../../../helpers/types/dataset';
import {
  addEmptyCleanMinMaxCondition,
  setCleanTargetAttribute,
  setCleanExcludedAttributes,
  updateCleanMinMaxCondition,
  removeCleanMinMaxCondition,
  initCleanMinMaxConditions,
  resetCleanMinMaxConditions,
} from '../../../../../../../cleanCalibrateSlice';
import MinMaxConditions from '../../../../../../../components/MinMaxConditions';
import ToggleAdvancedConfigButton from '../../../../../../../components/ToggleAdvancedConfigButton';
import { useAppSelector } from '../../../../../../../../../../app/store/helpers/functions';
import { populateMinMaxCleanConditions } from '../../../../../../../helpers/functions/conditions';
import WarningPanel from '../../../../../../../components/WarningPanel';

export default function CleanTabSmartContent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) => selectYieldDataset(state, datasetUuid));
  const cleanAttribute = useAppSelector(selectCleanTargetAttribute);
  const cleanExcludedAttributes = useAppSelector(selectCleanExcludedAttributes);
  const cleanConditions = useAppSelector(selectCleanMinMaxConditions);
  const [configExpanded, setConfigExpanded] = useState(!!cleanExcludedAttributes?.length || !!cleanConditions.length);

  const allAttributesItems = useMemo<FullAttributeItem[]>(() => {
    return (dataset?.fullAttributes || []).map((fullAttribute: FullAttribute) => {
      return createFullAttributeItem(fullAttribute);
    });
  }, [dataset]);

  const cleanAttributeItem = useMemo<FullAttributeItem | undefined>(() => {
    return allAttributesItems.find((item) => item.value === cleanAttribute);
  }, [allAttributesItems, cleanAttribute]);

  const excludedAttributesValues = useMemo<Set<string>>(() => {
    return new Set(cleanExcludedAttributes);
  }, [cleanExcludedAttributes]);

  const handleCleanAttributeChange = (_e: unknown, item: FullAttributeItem) => {
    dispatch(setCleanTargetAttribute(item.value));
  };

  const handleToggleConfigClick = () => {
    setConfigExpanded(!configExpanded);

    if (configExpanded) {
      dispatch(resetCleanMinMaxConditions());
    } else {
      dispatch(initCleanMinMaxConditions());
    }
  };

  const handleExcludedAttributesChange = (itemsValues: Set<string>) => {
    dispatch(setCleanExcludedAttributes([...itemsValues]));
  };

  const handleAddCondition = () => {
    dispatch(addEmptyCleanMinMaxCondition());
  };

  const handleConditionAttributeChange = (attributeItem: FullAttributeItem, index: number) => {
    const condition = populateMinMaxCleanConditions(
      { cleanAttribute: attributeItem?.value ?? null },
      dataset?.statistics,
    );

    dispatch(updateCleanMinMaxCondition({
      index,
      condition,
    }));
  };

  const handleConditionMinChange = (min: number | null, index: number) => {
    dispatch(updateCleanMinMaxCondition({
      index,
      condition: { min },
    }));
  };

  const handleConditionMaxChange = (max: number | null, index: number) => {
    dispatch(updateCleanMinMaxCondition({
      index,
      condition: { max },
    }));
  };

  const handleConditionDelete = (index: number) => {
    dispatch(removeCleanMinMaxCondition(index));
  };

  return (
    <>
      <ComboBox
        title={t('clean-calibrate.yield-popup.tabs.clean.configure.yield-attribute')}
        placeholder={t('general.controls.select')}
        options={allAttributesItems}
        value={cleanAttributeItem}
        getOptionSelected={getComboBoxOptionSelected}
        disableCloseOnSelect={false}
        disableClearable
        required
        onChange={handleCleanAttributeChange}
      />
      {
        !cleanAttribute
          && (
            <WarningPanel
              text={t('clean-calibrate.yield-popup.tabs.clean.configure.no-cleaning-attribute-warning')}
            />
          )
      }
      {
        configExpanded
          ? (
            <>
              <Divider className="clean-tab-content__content-divider" />
              <DialogContentText>
                {t('clean-calibrate.yield-popup.tabs.clean.configure.attributes-instruction')}
              </DialogContentText>
              <ItemsPicker
                availableItemsTitle={t('clean-calibrate.yield-popup.attributes')}
                addedItemsTitle={t('clean-calibrate.yield-popup.tabs.clean.configure.excluded-attributes')}
                items={allAttributesItems}
                addedItemsValues={excludedAttributesValues}
                emptyAddedItemsLabel={t('clean-calibrate.yield-popup.no-added-attributes')}
                onAddedItemsChange={handleExcludedAttributesChange}
              />
              <Divider className="clean-tab-content__content-divider" />
              <DialogContentText>
                {t('clean-calibrate.yield-popup.tabs.clean.configure.conditions-instruction')}
              </DialogContentText>
              <MinMaxConditions
                title={t('clean-calibrate.yield-popup.attributes')}
                attributesItems={allAttributesItems}
                conditions={transformCleanMinMaxConditions(cleanConditions)}
                statistics={dataset?.statistics || []}
                onAddConditionClick={handleAddCondition}
                onConditionAttributeChange={handleConditionAttributeChange}
                onConditionMinChange={handleConditionMinChange}
                onConditionMaxChange={handleConditionMaxChange}
                onConditionDelete={handleConditionDelete}
              />
            </>
          )
          : <Fragment />
      }
      <ToggleAdvancedConfigButton
        value={configExpanded}
        showLabelI18nKey="clean-calibrate.yield-popup.show-advanced-config"
        hideLabelI18nKey="clean-calibrate.yield-popup.hide-advanced-config"
        onClick={handleToggleConfigClick}
      />
    </>
  );
}
