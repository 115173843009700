import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../app/store/helpers/functions';
import { selectField, selectIsFieldVamapsDatasetsPinsGroupsLoaded } from '../../../field/fieldSelectors';
import { getAssetByUuid } from '../../../../helpers/functions/entities/lookup';
import { selectAsset } from '../fieldWorkflowSlice';
import { AssetGroupType } from '../../../../helpers/constants/entities/asset';

/**
 * Custom hook to select asset based on router params.
 */
export default function useRouterParamsAsset() {
  const dispatch = useAppDispatch();
  const { itemUuid, assetGroup } = useParams<{ itemUuid: string; assetGroup: AssetGroupType }>();
  const datasetsLoaded = useAppSelector(selectIsFieldVamapsDatasetsPinsGroupsLoaded);
  const field = useAppSelector(selectField);
  const asset = getAssetByUuid(itemUuid, field);

  useEffect(() => {
    if (!itemUuid || !assetGroup || !asset) {
      return;
    }

    dispatch(selectAsset({
      groupType: assetGroup,
      asset,
    }));
  }, [
    assetGroup,
    itemUuid,
    datasetsLoaded,
    asset,
    dispatch,
  ]);
}
