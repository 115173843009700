import { API, graphqlOperation } from '@aws-amplify/api';

import { isValidUrl, appendApiKey } from '../../helpers/functions/utils/url';
import { transformBoundaryFeatures } from './helpers/functions/features';
import {
  is3dMap,
  isAsAppliedDataset,
  isAsAppliedVectorAnalysis,
  isEquationMap,
  isMultiLayerVectorAnalysis,
  isSatelliteVectorAnalysis,
  isSoilDataset,
  isSoilVectorAnalysis,
  isTopographyMap,
  isTopographyVectorAnalysis,
  isVectorAnalysis,
  isYieldDataset,
  isYieldVectorAnalysis,
  isZonesOperationsIntersectVectorAnalysis,
} from '../../helpers/functions/entities/assets';
import { AssetGroupType, AssetType } from '../../helpers/constants/entities/asset';
import {
  transformAsAppliedDatasets,
  transformEquationMaps,
  transformFarms,
  transformFields,
  transformSoilDatasets,
  transformTopographyMaps,
  transformVectorAnalysisMaps,
  transformYieldDatasets,
} from './helpers/functions/assets';
import { fetchAllSatelliteImages } from '../satelliteImages/satelliteImagesAPI';
import {
  transform as transformSatelliteImages,
} from '../satelliteImages/helpers/functions/satelliteImages';
import getSatelliteImagesQuery from '../satelliteImages/graphql/queries/getSatelliteImages.gql';
import getVamapsQuery from './graphql/queries/getVamaps.gql';
import getEquationMapsQuery from './graphql/queries/getEquationMaps.gql';
import getSoilDatasetsQuery from './graphql/queries/getSoilDatasets.gql';
import getYieldDatasetsQuery from './graphql/queries/getYieldDatasets.gql';
import getAsAppliedDatasetsQuery from './graphql/queries/getAsAppliedDatasets.gql';
import getTopographyMapsQuery from './graphql/queries/getTopographyMaps.gql';
import getFieldDataQuery from './graphql/queries/getFieldData.gql';
import getFieldAllAssetsQuery from './graphql/queries/getFieldAllAssets.gql';
import getFieldsDatasetsQuery from './graphql/queries/getFieldsDatasets.gql';
import getFieldVamapsQuery from './graphql/queries/getFieldVamaps.gql';
import getField from './graphql/queries/getField.gql';
import getVamapsAttributesJsonQuery from './graphql/queries/getVamapsAttributesJson.gql';
import registerFieldMutation from './graphql/mutations/registerField.gql';
import saveVectorAnalysisMapMutation from './graphql/mutations/saveVectorAnalysisMap.gql';
import saveSoilDatasetMutation from './graphql/mutations/saveSoilDataset.gql';
import saveYieldDatasetMutation from './graphql/mutations/saveYieldDataset.gql';
import saveAsAppliedDatasetMutation from './graphql/mutations/saveAsAppliedDataset.gql';
import saveTopographyDatasetMutation from './graphql/mutations/saveTopographyDataset.gql';
import save3dMapMutation from './graphql/mutations/save3dMap.gql';
import saveEquationMapMutation from './graphql/mutations/saveEquationMap.gql';
import deleteVectorAnalysisMapMutation from './graphql/mutations/deleteVectorAnalysisMap.gql';
import deleteSoilDatasetMutation from './graphql/mutations/deleteSoilDataset.gql';
import deleteYieldDatasetMutation from './graphql/mutations/deleteYieldDataset.gql';
import deleteAsAppliedDatasetMutation from './graphql/mutations/deleteAsAppliedDataset.gql';
import deleteTopographyDatasetMutation from './graphql/mutations/deleteTopographyDataset.gql';
import delete3dMapMutation from './graphql/mutations/delete3dMap.gql';
import deleteEquationMapMutation from './graphql/mutations/deleteEquationMap.gql';
import getFieldVamapPinsQuery from './graphql/queries/getFieldVamapPins.gql';
import getVamapAssetsQuery from './graphql/queries/getVamapAssets.gql';
import getAssetsUrlsQuery from './graphql/queries/getAssetsUrls.gql';
import orderPlanetImageMutation from './graphql/mutations/orderPlanetImage.gql';
import getVamapsGeojsonQuery from './graphql/queries/getVamapsGeojson.gql';
import getReportsQuery from './graphql/queries/getReports.gql';
import generateReportsMutation from './graphql/mutations/generateReports.gql';
import { RemoteAssetStatus } from './helpers/constants/remoteAsset';
import { AreaUnit } from '../user/helpers/constants/user';
import { transformFieldAssetsResponse, isTokenExpiredSoonError } from './helpers/functions/api';
import type {
  GenerateReportsResponse,
  GetAsAppliedDatasetsResponse,
  GetAssetsUrlsQueryResponse,
  GetEquationMapsResponse,
  GetFieldAssetsResponse,
  GetFieldDataResponse,
  GetFieldVamapPinsResponse,
  GetFieldVamapsResponse,
  GetReportsResponse,
  GetSoilDatasetsResponse,
  GetTopographyMapsResponse,
  GetVamapAssetsResponse,
  GetVamapsAttributesJsonResponse,
  GetVamapsGeojsonResponse,
  GetVamapsResponse,
  GetYieldDatasetsResponse,
  OrderPlanetImageResponse,
  RegisterFieldResponse,
  SaveEquationMapMutationResponse,
  TransformedFieldAssetsResponse,
} from './types/api';
import { TransformedVectorAnalysisMap } from '../../helpers/types/vectorAnalysisMap';
import { prepareZonesMapJson } from '../../helpers/analysis';
import { refreshUserSession } from '../ui/authentication/helpers/functions/authentication';
import { SatelliteImagesResponse } from '../satelliteImages/types/api';
import { SatelliteImage } from '../satelliteImages/types/satelliteImage';
import { GeoFormat, BufferSize } from '../../helpers/constants/api';
import { TransformedField } from './types/field';
import { TreeNodeEntity } from '../../helpers/constants/entities/treeNodeEntity';
import {
  captureException,
  CustomError,
} from '../../helpers/functions/utils/errorHandling';
import { VectorAnalysisMapType } from '../../helpers/constants/entities/vectorAnalysisMap';
import { TransformedAsset } from '../../helpers/types';

const fetchFieldAssets = async <T extends GetFieldAssetsResponse = GetFieldAssetsResponse>({
  query,
  farmUuid,
  fieldUuid,
  areaUnit,
}: {
  query: string,
  farmUuid: string,
  fieldUuid: string,
  areaUnit: AreaUnit,
}): Promise<TransformedFieldAssetsResponse> => {
  let transformedResponse: TransformedFieldAssetsResponse;

  try {
    const response = await API.graphql(graphqlOperation(query, {
      farmUuids: [farmUuid],
      fieldUuids: [fieldUuid],
      areaUnit,
    })) as T;

    transformedResponse = transformFieldAssetsResponse(response);
  } catch (error) {
    const typedError = error as GetFieldAssetsResponse;

    if (typedError.data) {
      transformedResponse = transformFieldAssetsResponse(typedError);
    } else {
      captureException({
        error: new CustomError('[Field] fetchFieldAssets', {
          cause: error,
        }),
      });

      throw error;
    }
  }

  return transformedResponse;
};

export const fetchAllAssets = async ({
  farmUuid,
  fieldUuid,
  areaUnit,
}: {
  farmUuid: string,
  fieldUuid: string,
  areaUnit: AreaUnit,
}) => {
  return fetchFieldAssets({
    farmUuid,
    fieldUuid,
    areaUnit,
    query: getFieldAllAssetsQuery,
  });
};

export const fetchFieldDatasets = async ({
  farmUuid,
  fieldUuid,
  areaUnit,
}: {
  farmUuid: string,
  fieldUuid: string,
  areaUnit: AreaUnit,
}) => {
  return fetchFieldAssets({
    farmUuid,
    fieldUuid,
    areaUnit,
    query: getFieldsDatasetsQuery,
  });
};

export const fetchAssetsGroup = async ({
  assetsGroupType,
  farmUuid,
  fieldUuid,
  uuids,
  areaUnit,
}: {
  assetsGroupType: AssetGroupType,
  farmUuid: string,
  fieldUuid: string,
  uuids?: string[],
  areaUnit?: AreaUnit,
}) => {
  const input = {
    farmUuids: [farmUuid],
    fieldUuids: [fieldUuid],
  };
  if (assetsGroupType === AssetGroupType.satelliteImages) {
    const response = await API.graphql(graphqlOperation(getSatelliteImagesQuery, {
      ...input,
      imagesUuids: uuids,
    })) as SatelliteImagesResponse<SatelliteImage>;
    const data = response.data?.getFarms[0].fields[0].satelliteImages;

    return transformSatelliteImages(data);
  } if (assetsGroupType === AssetGroupType.vectorAnalysisMaps) {
    const response = await API.graphql(graphqlOperation(getVamapsQuery, {
      ...input,
      vectorAnalysisMapUuids: uuids,
      areaUnit,
    })) as GetVamapsResponse;
    const data = response.data?.getFarms[0].fields[0].vectorAnalysisMaps;

    return transformVectorAnalysisMaps(data);
  } if (assetsGroupType === AssetGroupType.equationMaps) {
    const response = await API.graphql(graphqlOperation(getEquationMapsQuery, {
      ...input,
      equationMapUuids: uuids,
      areaUnit,
    })) as GetEquationMapsResponse;
    const data = response.data?.getFarms[0].fields[0].equationMaps;

    return transformEquationMaps(data);
  } if (assetsGroupType === AssetGroupType.soilDatasets) {
    const response = await API.graphql(graphqlOperation(getSoilDatasetsQuery, {
      ...input,
      datasetUuids: uuids,
      areaUnit,
    })) as GetSoilDatasetsResponse;
    const data = response.data?.getFarms[0].fields[0].soilDatasets;

    return transformSoilDatasets(data);
  } if (assetsGroupType === AssetGroupType.yieldDatasets) {
    const response = await API.graphql(graphqlOperation(getYieldDatasetsQuery, {
      ...input,
      datasetUuids: uuids,
      areaUnit,
    })) as GetYieldDatasetsResponse;
    const data = response.data?.getFarms[0].fields[0].yieldDatasets;

    return transformYieldDatasets(data);
  } if (assetsGroupType === AssetGroupType.asAppliedDatasets) {
    const response = await API.graphql(graphqlOperation(getAsAppliedDatasetsQuery, {
      ...input,
      datasetUuids: uuids,
      areaUnit,
    })) as GetAsAppliedDatasetsResponse;
    const data = response.data?.getFarms[0].fields[0].asAppliedDatasets;

    return transformAsAppliedDatasets(data);
  } if (assetsGroupType === AssetGroupType.topographyMaps) {
    const response = await API.graphql(graphqlOperation(getTopographyMapsQuery, {
      ...input,
      datasetUuids: uuids,
    })) as GetTopographyMapsResponse;
    const data = response.data?.getFarms[0].fields[0].topographyMaps;

    return transformTopographyMaps(data);
  }
  throw new CustomError(`[Field] fetchAssetsGroup: unknown AssetGroupType ${assetsGroupType}`);
};

export const fetchFieldData = async ({
  farmUuid,
  fieldUuid,
  areaUnit,
}: {
  farmUuid: string,
  fieldUuid: string,
  areaUnit: AreaUnit,
}) => {
  let result: TransformedField;

  try {
    const response = await API.graphql(graphqlOperation(getFieldDataQuery, {
      farmUuids: [farmUuid],
      fieldUuids: [fieldUuid],
      areaUnit,
    })) as GetFieldDataResponse;
    const fields = transformFields(response.data?.getFarms[0].fields);

    [result] = fields;
  } catch (error) {
    const typedError = error as GetFieldDataResponse;

    if (typedError.data) {
      const fields = transformFields(typedError.data?.getFarms[0].fields);

      [result] = fields;
    } else {
      captureException({
        error: new CustomError('[Field] fetchFieldData', {
          cause: error,
        }),
      });

      throw error;
    }
  }

  return result;
};

export const fetchFieldVamaps = async (
  areaUnit: AreaUnit,
  farmUuid: string,
  fieldUuid: string,
) => {
  const response = await API.graphql(graphqlOperation(getFieldVamapsQuery, {
    farmUuids: [farmUuid],
    fieldUuids: [fieldUuid],
    areaUnit,
  })) as GetFieldVamapsResponse;
  const farms = response.data?.getFarms;

  return transformFarms(farms)[0].fields[0];
};

export const fetchFieldWithSatellites = async ({
  farmUuid,
  fieldUuid,
  areaUnit,
}: {
  farmUuid: string,
  fieldUuid: string,
  areaUnit: AreaUnit,
}) => {
  return Promise.all([
    API.graphql(graphqlOperation(getField, {
      farmUuids: [farmUuid],
      fieldUuids: [fieldUuid],
      areaUnit,
    })),
    fetchAllSatelliteImages(farmUuid, fieldUuid),
  ])
    .then(([fieldResponse, images]) => {
      const [{
        fields,
      }] = fieldResponse.data.getFarms;

      fields[0].satelliteImages = images;

      const [field] = transformFields(fieldResponse.data.getFarms[0].fields);

      return field;
    });
};

export const fetchBoundary = async (boundaryUrl: string, apiKey: string) => {
  const response = await fetch(appendApiKey(boundaryUrl, apiKey));
  const { features } = await response.json();

  return transformBoundaryFeatures(features);
};

export const saveBoundary = async ({
  uuid,
  farmUuid,
  name,
  geojson,
  labels,
}: {
  uuid: string,
  farmUuid: string,
  name: string,
  geojson: string,
  labels?: string,
}) => {
  const response = await API.graphql(graphqlOperation(registerFieldMutation, {
    input: {
      uuid,
      farmUuid,
      name,
      creationType: 'GEOJSON',
      geojson,
      labels,
    },
  })) as RegisterFieldResponse;

  return response.data?.registerField;
};

export const saveEquationMap = async ({
  uuid,
  fieldUuid,
  name,
  type,
}: {
  uuid: string,
  fieldUuid: string,
  name: string,
  type: VectorAnalysisMapType,
}) => {
  const response = await API.graphql(graphqlOperation(saveEquationMapMutation, {
    input: {
      uuid,
      fieldUuid,
      name,
      type,
    },
  })) as SaveEquationMapMutationResponse;

  return response.data?.saveEquationMap;
};

export const renameAsset = async (
  asset: TransformedAsset,
  name: string,
  fieldUuid: string,
) => {
  let mutation: string;
  let assetGroupType: AssetGroupType;

  switch (true) {
    case isVectorAnalysis(asset):
      mutation = saveVectorAnalysisMapMutation;
      assetGroupType = AssetGroupType.vectorAnalysisMaps;
      break;
    case isSoilDataset(asset):
      mutation = saveSoilDatasetMutation;
      assetGroupType = AssetGroupType.soilDatasets;
      break;
    case isYieldDataset(asset):
      mutation = saveYieldDatasetMutation;
      assetGroupType = AssetGroupType.yieldDatasets;
      break;
    case isAsAppliedDataset(asset):
      mutation = saveAsAppliedDatasetMutation;
      assetGroupType = AssetGroupType.asAppliedDatasets;
      break;
    case isTopographyMap(asset):
      mutation = saveTopographyDatasetMutation;
      assetGroupType = AssetGroupType.topographyMaps;
      break;
    case is3dMap(asset):
      mutation = save3dMapMutation;
      assetGroupType = AssetGroupType.threeDimensionalMaps;
      break;
    case isEquationMap(asset):
      mutation = saveEquationMapMutation;
      assetGroupType = AssetGroupType.equationMaps;
      break;
    default:
      throw new CustomError(`[Field] renameAsset: unknown asset ${asset}`);
  }

  await API.graphql(graphqlOperation(mutation, {
    input: {
      name,
      uuid: asset.uuid,
      fieldUuid,
    },
  }));

  return {
    name,
    assetGroupType,
    uuid: asset.uuid,
  };
};

export const deleteAsset = async (
  asset: TransformedAsset,
  fieldUuid: string,
) => {
  let query: string;
  let assetGroupType: AssetGroupType;

  switch (true) {
    case isVectorAnalysis(asset):
      query = deleteVectorAnalysisMapMutation;
      assetGroupType = AssetGroupType.vectorAnalysisMaps;
      break;
    case isSoilDataset(asset):
      query = deleteSoilDatasetMutation;
      assetGroupType = AssetGroupType.soilDatasets;
      break;
    case isYieldDataset(asset):
      query = deleteYieldDatasetMutation;
      assetGroupType = AssetGroupType.yieldDatasets;
      break;
    case isAsAppliedDataset(asset):
      query = deleteAsAppliedDatasetMutation;
      assetGroupType = AssetGroupType.asAppliedDatasets;
      break;
    case isTopographyMap(asset):
      query = deleteTopographyDatasetMutation;
      assetGroupType = AssetGroupType.topographyMaps;
      break;
    case is3dMap(asset):
      query = delete3dMapMutation;
      assetGroupType = AssetGroupType.threeDimensionalMaps;
      break;
    case isEquationMap(asset):
      query = deleteEquationMapMutation;
      assetGroupType = AssetGroupType.equationMaps;
      break;
    default:
      throw new CustomError(`[Field] deleteAsset: unknown asset ${asset}`);
  }

  await API.graphql(graphqlOperation(query, {
    input: {
      uuid: asset.uuid,
      fieldUuid,
    },
  }));

  return {
    assetGroupType,
    uuid: asset.uuid,
  };
};

export const fetchFieldVamapPins = async ({
  farmUuid,
  fieldUuid,
  uuid,
  areaUnit,
}: {
  farmUuid: string,
  fieldUuid: string,
  uuid: string,
  areaUnit: AreaUnit,
}) => {
  const response = await API.graphql(graphqlOperation(getFieldVamapPinsQuery, {
    farmUuid,
    fieldUuid,
    zonesMapUuid: uuid,
    areaUnit,
  })) as GetFieldVamapPinsResponse;

  return transformFarms(response.data?.getFarms)[0].fields[0];
};

const getVamapRequiredDataUuids = (vamap: TransformedVectorAnalysisMap) => {
  const result: {
    satelliteImageUuids?: string[]
    vectorAnalysisMapUuids?: string[]
    soilDatasetUuids?: string[]
    yieldDatasetUuids?: string[]
    asAppliedDatasetUuids?: string[]
    topographyMapUuids?: string[]
  } = {};

  if (isSatelliteVectorAnalysis(vamap)) {
    result.satelliteImageUuids = vamap.satelliteImages?.map(({ uuid }) => uuid);
  } else if (isSoilVectorAnalysis(vamap)) {
    result.soilDatasetUuids = vamap.soilDataset?.uuid ? [vamap.soilDataset.uuid] : [];
  } else if (isYieldVectorAnalysis(vamap)) {
    result.yieldDatasetUuids = vamap.yieldDataset?.uuid ? [vamap.yieldDataset.uuid] : [];
  } else if (isAsAppliedVectorAnalysis(vamap)) {
    result.asAppliedDatasetUuids = vamap.asAppliedDataset?.uuid ? [vamap.asAppliedDataset.uuid] : [];
  } else if (isTopographyVectorAnalysis(vamap)) {
    result.topographyMapUuids = vamap.topographyMap?.uuid ? [vamap.topographyMap.uuid] : [];
  } else if (isMultiLayerVectorAnalysis(vamap)) {
    vamap.dataLayers?.forEach((layer) => {
      if (layer.satelliteImages) {
        if (!result.satelliteImageUuids) {
          result.satelliteImageUuids = [];
        }

        result.satelliteImageUuids.push(...layer.satelliteImages.map((image) => {
          return image.uuid;
        }));
      } else if (layer.soilDataset) {
        if (!result.soilDatasetUuids) {
          result.soilDatasetUuids = [];
        }

        result.soilDatasetUuids.push(layer.soilDataset.uuid);
      } else if (layer.yieldDataset) {
        if (!result.yieldDatasetUuids) {
          result.yieldDatasetUuids = [];
        }

        result.yieldDatasetUuids.push(layer.yieldDataset.uuid);
      } else if (layer.asAppliedDataset) {
        if (!result.asAppliedDatasetUuids) {
          result.asAppliedDatasetUuids = [];
        }

        result.asAppliedDatasetUuids.push(layer.asAppliedDataset.uuid);
      } else if (layer.topographyMap) {
        if (!result.topographyMapUuids) {
          result.topographyMapUuids = [];
        }

        result.topographyMapUuids.push(layer.topographyMap.uuid);
      }
    });
  } else if (isZonesOperationsIntersectVectorAnalysis(vamap)) {
    result.vectorAnalysisMapUuids = vamap.zonesOperationMaps?.map((map) => {
      return map.map.uuid;
    });
  }

  return result;
};

export const fetchVamapAssets = async ({
  farmUuid,
  fieldUuid,
  vamap,
}: {
  farmUuid: string,
  fieldUuid: string,
  vamap: TransformedVectorAnalysisMap,
}) => {
  const {
    satelliteImageUuids,
    soilDatasetUuids,
    yieldDatasetUuids,
    asAppliedDatasetUuids,
    topographyMapUuids,
    vectorAnalysisMapUuids,
  } = getVamapRequiredDataUuids(vamap);
  const requestedData = {
    farmUuids: [farmUuid],
    fieldUuids: [fieldUuid],
    satelliteImageUuids,
    soilDatasetUuids,
    yieldDatasetUuids,
    asAppliedDatasetUuids,
    topographyMapUuids,
    vectorAnalysisMapUuids,
    hasSatellite: !!satelliteImageUuids,
    hasSoil: !!soilDatasetUuids,
    hasYield: !!yieldDatasetUuids,
    hasAsApplied: !!asAppliedDatasetUuids,
    hasTopography: !!topographyMapUuids,
    hasVectorAnalysis: !!vectorAnalysisMapUuids,
  };

  const response = await API.graphql(graphqlOperation(
    getVamapAssetsQuery,
    requestedData,
  )) as GetVamapAssetsResponse;

  return transformFarms(response.data?.getFarms)[0].fields[0];
};

export const fetchAssetFeatures = async ({
  assetType,
  uuid,
  farmUuid,
  fieldUuid,
  format,
  buffer,
  urlType = 'sourceDataUrl',
}: {
  assetType: AssetType,
  uuid: string,
  farmUuid: string,
  fieldUuid: string,
  format: GeoFormat,
  buffer?: BufferSize,
  urlType?: 'sourceDataUrl' | 'originDataUrl',
}) => {
  const input: {
    farmUuids: string[],
    fieldUuids: string[],
    exportFormat: GeoFormat,
    buffer?: BufferSize,
    hasSatellite: boolean,
    hasSoil: boolean,
    hasYield: boolean,
    hasAsApplied: boolean,
    hasTopography: boolean,
    hasVamap: boolean,
    withSourceUrl: boolean,
    withOriginalUrl: boolean,
    satelliteImageUuids?: string[],
    topographyMapUuids?: string[],
    soilDatasetUuids?: string[],
    yieldDatasetUuids?: string[],
    asAppliedDatasetUuids?: string[],
    vamapUuids?: string[],
  } = {
    farmUuids: [farmUuid],
    fieldUuids: [fieldUuid],
    exportFormat: format,
    buffer,
    hasSatellite: assetType === AssetType.satelliteImage,
    hasSoil: assetType === AssetType.soilDataset,
    hasYield: assetType === AssetType.yieldDataset,
    hasAsApplied: assetType === AssetType.asAppliedDataset,
    hasTopography: assetType === AssetType.topographyMap,
    hasVamap: assetType === AssetType.vectorAnalysisMap,
    withSourceUrl: urlType === 'sourceDataUrl',
    withOriginalUrl: urlType === 'originDataUrl',
  };

  if (input.hasSatellite) {
    input.satelliteImageUuids = [uuid];
  } else if (input.hasTopography) {
    input.topographyMapUuids = [uuid];
  } else if (input.hasSoil) {
    input.soilDatasetUuids = [uuid];
  } else if (input.hasYield) {
    input.yieldDatasetUuids = [uuid];
  } else if (input.hasAsApplied) {
    input.asAppliedDatasetUuids = [uuid];
  } else if (input.hasVamap) {
    input.vamapUuids = [uuid];
  }

  const response = await API.graphql(graphqlOperation(
    getAssetsUrlsQuery,
    input,
  )) as GetAssetsUrlsQueryResponse;
  let resource: {
    sourceDataUrl?: string;
    originDataUrl?: string;
  } | undefined;

  if (assetType === AssetType.satelliteImage && urlType === 'sourceDataUrl') {
    resource = response.data?.getFarms[0].fields[0].satelliteImages?.[0];
  } else if (assetType === AssetType.soilDataset) {
    resource = response.data?.getFarms[0].fields[0].soilDatasets?.[0];
  } else if (assetType === AssetType.yieldDataset) {
    resource = response.data?.getFarms[0].fields[0].yieldDatasets?.[0];
  } else if (assetType === AssetType.asAppliedDataset) {
    resource = response.data?.getFarms[0].fields[0].asAppliedDatasets?.[0];
  } else if (assetType === AssetType.topographyMap && urlType === 'sourceDataUrl') {
    resource = response.data?.getFarms[0].fields[0].topographyMaps?.[0];
  } else if (assetType === AssetType.vectorAnalysisMap && urlType === 'sourceDataUrl') {
    resource = response.data?.getFarms[0].fields[0].vectorAnalysisMaps?.[0];
  } else {
    return Promise.reject(RemoteAssetStatus.invalid);
  }

  if (!resource) {
    return Promise.reject(RemoteAssetStatus.deleted);
  }

  const url = resource[urlType];

  if (!url || !isValidUrl(url)) {
    return Promise.reject(RemoteAssetStatus.invalid);
  }

  return fetch(url, {
    method: 'GET',
  });
};

export const orderPlanetImage = async ({
  fieldUuid,
  satelliteImageUuid,
}: {
  fieldUuid: string,
  satelliteImageUuid: string,
}) => {
  try {
    const response = await API.graphql(graphqlOperation(orderPlanetImageMutation, {
      input: {
        fieldUuid,
        satelliteImageUuid,
      },
    })) as OrderPlanetImageResponse;

    return response.data?.orderPlanetImage;
  } catch (error) {
    return {
      errors: (error as { errors: unknown })?.errors,
    };
  }
};

export const fetchVamapGeojson = async ({
  farmUuid,
  fieldUuid,
  uuid,
  areaUnit,
}: {
  farmUuid: string,
  fieldUuid: string,
  uuid: string,
  areaUnit: AreaUnit,
}) => {
  const response = await API.graphql(graphqlOperation(getVamapsGeojsonQuery, {
    farmUuids: [farmUuid],
    fieldUuids: [fieldUuid],
    vectorAnalysisMapUuids: [uuid],
    areaUnit,
  })) as GetVamapsGeojsonResponse;
  const zonesMapGeojson = response.data?.getFarms[0].fields[0].vectorAnalysisMaps[0].zonesMapGeojson;

  return prepareZonesMapJson(JSON.parse(zonesMapGeojson || ''));
};

export const fetchVamapAttributesJson = async ({
  farmUuid,
  fieldUuid,
  uuid,
  areaUnit,
}: {
  farmUuid: string,
  fieldUuid: string,
  uuid: string,
  areaUnit: AreaUnit,
}) => {
  const response = await API.graphql(graphqlOperation(getVamapsAttributesJsonQuery, {
    farmUuids: [farmUuid],
    fieldUuids: [fieldUuid],
    vectorAnalysisMapUuids: [uuid],
    areaUnit,
  })) as GetVamapsAttributesJsonResponse;
  const attributesJson = response.data?.getFarms[0].fields[0].vectorAnalysisMaps[0].attributesJson;

  return prepareZonesMapJson(JSON.parse(attributesJson || ''));
};

export const fetchReport = async ({
  farmUuid,
  fieldUuid,
  uuid,
}: {
  farmUuid: string,
  fieldUuid: string,
  uuid: string,
}) => {
  const { data } = await API.graphql(graphqlOperation(getReportsQuery, {
    farmUuids: [farmUuid],
    fieldUuids: [fieldUuid],
    reportUuids: [uuid],
  })) as GetReportsResponse;

  return data?.getFarms[0].fields[0].reports[0];
};

export const generateReports = async (reports: {
  farmUuid: string,
  fieldUuid: string,
  format: 'PDF',
  assets: {
    type: TreeNodeEntity,
    uuid: string,
    attributes?: string[],
  }[],
}[]) => {
  const callApi = async () => {
    const { data } = await API.graphql(graphqlOperation(generateReportsMutation, {
      input: {
        reports,
      },
    })) as GenerateReportsResponse;

    return data?.generateReportsAsync;
  };

  try {
    return await callApi();
  } catch (error) {
    if (!isTokenExpiredSoonError(error)) {
      throw error;
    }

    captureException({
      error: new CustomError('[Field] generateReports: Token expires soon', {
        cause: error,
      }),
    });

    await refreshUserSession();

    return callApi();
  }
};
