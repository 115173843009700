import { Currency } from '../../types/api';

export enum AreaUnit {
  hectares = 'HECTARES',
  acres = 'ACRES',
  squareMeters = 'SQUARE_METERS',
}

export enum UserRole {
  User = 'user',
  Advisor = 'advisor',
}

export enum CognitoGroup {
  Origin = 'Origin-group',
  PfeiferLangen = 'PfeiferLangen-group',
  Organization = 'Organization-group',
  Planet = 'Planet-group',
}

export enum Language {
  English = 'en',
  German = 'de',
  French = 'fr',
  Dutch = 'nl',
  Ukrainian = 'uk',
  Russian = 'ru',
  PortugueseBrazil = 'pt',
  Hungarian = 'hu',
  Slovenian = 'sl',
  Bulgarian = 'bg',
  Italian = 'it',
  Spanish = 'es',
}

export const AREA_UNIT_TO_CURRENCY_MAP: Partial<Record<
AreaUnit,
{ sign: string; name: Currency }
>> = {
  [AreaUnit.acres]: {
    sign: '$',
    name: 'USD',
  },
  [AreaUnit.hectares]: {
    sign: '€',
    name: 'EUR',
  },
};
