import React from 'react';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { useAppDispatch, useAppSelector } from '../../../../../app/store/helpers/functions';
import PopupHeader from '../../../../../components/Popups/PopupHeader';
import { selectTab } from '../../cleanCalibrateSelectors';
import { resetUserInput, setTab } from '../../cleanCalibrateSlice';
import InfoPanel from '../../components/InfoPanel';
import ProcessingTypesTabs from '../../components/ProcessingTypesTabs';
import { ProcessingType } from '../../helpers/constants/ui';
import useDidMount from '../../../../../hooks/useDidMount';
import WarningPanel from '../../components/WarningPanel';
import useCalibrateParams from '../../hooks/useCalibrateParams';
import useCleanParams from '../../hooks/useCleanParams';

import './index.scss';

const TAB_TO_INFO_TEXT_PROPS_MAP = {
  [ProcessingType.fast]: {
    textI18nKey:
      'clean-calibrate.yield-popup.tabs.fast.processing-type.description',
  },
  [ProcessingType.clean]: {
    titleI18nKey:
      'clean-calibrate.yield-popup.tabs.clean.processing-type.cleaning-types',
    items: [
      'clean-calibrate.yield-popup.tabs.clean.processing-type.smart',
      'clean-calibrate.yield-popup.tabs.clean.processing-type.usda',
    ],
  },
  [ProcessingType.calibrate]: {
    titleI18nKey:
      'clean-calibrate.yield-popup.tabs.calibrate.processing-type.calibration-types',
    items: [
      'clean-calibrate.yield-popup.tabs.calibrate.processing-type.pathwise',
      'clean-calibrate.yield-popup.tabs.calibrate.processing-type.avg-total',
      'clean-calibrate.yield-popup.tabs.calibrate.processing-type.conditional',
    ],
  },
  [ProcessingType.calibrateClean]: {
    textI18nKey:
      'clean-calibrate.yield-popup.tabs.calibrate-clean.processing-type.description',
  },
};

export default function ProcessingTypesStepContent({
  onCancel,
}: {
  onCancel: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const tab = useAppSelector(selectTab);
  const infoTextProps = TAB_TO_INFO_TEXT_PROPS_MAP[tab];
  const calibrateParams = useCalibrateParams();
  const cleanParams = useCleanParams();
  const runAvailable = cleanParams && calibrateParams;

  const handleTabChange = (v: ProcessingType) => {
    dispatch(setTab(v));
  };

  useDidMount(() => {
    dispatch(resetUserInput());
  });

  return (
    <>
      <PopupHeader
        classes={{
          root: 'processing-types-step-content__header',
        }}
        title={t('clean-calibrate.yield-popup.clean-calibrate-title')}
        onCancel={onCancel}
      />
      <DialogContent className="processing-types-step-content">
        <DialogContentText className="processing-types-step-content__content-description">
          {t('clean-calibrate.yield-popup.select-processing-type')}
        </DialogContentText>
        <ProcessingTypesTabs
          tab={tab}
          onTabChange={handleTabChange}
        />
        <InfoPanel {...infoTextProps} />
        {
          tab === ProcessingType.fast && !runAvailable
            && (
              <WarningPanel
                text={t('clean-calibrate.yield-popup.tabs.fast.adjust-required')}
              />
            )
        }
      </DialogContent>
    </>
  );
}
