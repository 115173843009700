import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import TargetIcon from '@material-ui/icons/FilterCenterFocusSharp';
import D3Icon from '@material-ui/icons/ThreeDRotation';
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';
import { point } from '@turf/helpers';

import Button from '../../../../../components/Button';
import Loading from '../../../../../components/Loading';
import D2Icon from '../../../../../components/Icons/2d';
import BingLogo from '../../../../../components/Icons/bing-logo.svg';
import MapboxLogo from '../../../../../components/Icons/mapbox-logo.svg';
import MapControlsGroup from '../../components/MapControlsGroup';
import {
  removeLayer,
  setMapIs3d,
  setMapProvider,
} from '../../compareLayersSlice';
import { openPopup } from '../../../popups/popupsSlice';
import { selectBoundingBox } from '../../../../field/fieldSelectors';
import {
  selectIs3d,
  selectLayers,
  selectMapProvider,
} from '../../compareLayersSelectors';
import { getPaddingBounds } from '../../../../../helpers/functions/map/mapLayerConfig';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';
import { POPUPS } from '../../../popups/helpers/constants/popups';
import { isBingMapsEnabled } from '../../../../../helpers/functions/utils/appConfig';
import { fetchAllAssets } from '../../../../field/fieldSlice';
import { MapProvider } from '../../../../../helpers/constants/utils/mapProvider';

import './index.scss';

const MapCompareCard = React.lazy(() => import('../Map'));

class CompareLayersPanel extends Component {
  sync = false; // without this maps sync become veeery laggy

  state = {
    maps: [],
    center: null,
    zoom: null,
  };

  handleLoad = (event) => {
    const { target: map } = event;
    const maps = [
      ...this.state.maps,
      map,
    ];
    const {
      center,
      zoom,
    } = this.state;

    this.setState({
      maps,
    }, () => {
      if (!center || !zoom) {
        this.handleTarget();
        this._calculateData();
      }
    });
  };

  handleDragAndZoom = (event) => {
    if (this.sync) {
      return;
    }

    const { target: map } = event;

    this.sync = true;
    this.state.maps.forEach((m) => {
      if (!map.getStyle() || map === m) {
        return;
      }

      m.setCenter(map.getCenter());
      m.setZoom(map.getZoom());
    });
    this.sync = false;
    this._cleanupMaps();
  };

  handleRotate = (event) => {
    if (this.sync) {
      return;
    }

    const { target: map } = event;

    this.sync = true;
    this.state.maps.forEach((m) => {
      if (!map.getStyle() || map === m) {
        return;
      }

      m.setBearing(map.getBearing());
      m.setPitch(map.getPitch());
    });
    this.sync = false;
    this._cleanupMaps();
  };

  handleZoomIn = () => {
    this.state.maps.forEach((map) => {
      if (!map.getStyle()) {
        return;
      }

      map.zoomIn();
    });
    this._cleanupMaps();
  };

  handleZoomOut = () => {
    this.state.maps.forEach((map) => {
      if (!map.getStyle()) {
        return;
      }

      map.zoomOut();
    });
    this._cleanupMaps();
  };

  handleDimensions = () => {
    const {
      is3d,
      onDimensionClick,
    } = this.props;

    this.state.maps.forEach((map) => {
      if (!map.getStyle()) {
        return;
      }

      if (is3d) {
        map.setPitch([0]);
      } else {
        map.setPitch([70]);
      }
    });
    onDimensionClick(is3d);
    this._cleanupMaps();
  };

  handleTarget = () => {
    const {
      fitBounds,
      fitBoundsOptions,
    } = this.props;

    this.state.maps.forEach((map) => {
      if (!map.getStyle()) {
        return;
      }

      map.fitBounds(fitBounds, fitBoundsOptions);
    });
    this._cleanupMaps();
  };

  handleMouseMove = (event) => {
    const {
      lng,
      lat,
    } = event.lngLat;

    this.state.maps.forEach((map) => {
      if (!map.getStyle() || event.target === map) {
        return;
      }

      const source = map.getSource('pointer-source');

      if (source) {
        source.setData(point([lng, lat]));
      }
    });
  };

  handleAddDataLayerClick = () => {
    this._calculateData();
    this.props.onAddLayerClick();
  };

  handleCardRemove = (layerId) => {
    this._calculateData();
    this.props.onRemoveLayerClick(layerId);
  };

  _cleanupMaps = () => {
    const { maps } = this.state;
    const cleanedMaps = this.state.maps.filter((map) => {
      return map.getStyle();
    });

    if (maps.length !== cleanedMaps.length) {
      this.setState({
        maps: cleanedMaps,
      });
    }
  };

  _calculateData = () => {
    const { maps } = this.state;
    const map = maps.find((m) => {
      return !!m.getStyle();
    });
    let center = null;
    let zoom = null;

    if (map) {
      center = map.getCenter();
      zoom = map.getZoom();
    }

    this.setState({
      center,
      zoom,
    });
  };

  componentDidMount() {
    const { length } = this.props.layers;
    this.props.requestAllAssets();

    if (length) {
      AmplitudeAnalytics.trackLayersCompared({
        layers: length,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { length } = this.props.layers;

    if (
      prevProps.layers.length !== length
      && length > 0
    ) {
      AmplitudeAnalytics.trackLayersCompared({
        layersAmount: length,
      });
    }
  }

  render() {
    const {
      t,
      layers,
      is3d,
      mapProvider,
      onMapProviderClick,
    } = this.props;

    return (
      <div className="compare-layers-panel">
        <div className="maps-grid__wrapper">
          { layers.length < 3
            && (
              <div className="add-layer__wrapper">
                <Button
                  startIcon={<AddIcon />}
                  onClick={this.handleAddDataLayerClick}
                >
                  {t('zones-ops.multi-layer.steps.3.add-data-layer')}
                </Button>
              </div>
            )}
          <div className={clsx('maps-grid', {
            'maps-grid_2-cols': layers.length >= 2,
            'maps-grid_4-cells': layers.length > 2,
          })}
          >
            {
              layers.map(({ layerId, uuid }) => {
                return (
                  <Suspense
                    key={layerId}
                    fallback={<Loading />}
                  >
                    <MapCompareCard
                      uuid={uuid}
                      layerId={layerId}
                      mapProvider={mapProvider}
                      center={this.state.center}
                      zoom={this.state.zoom}
                      onLoad={this.handleLoad}
                      onDrag={this.handleDragAndZoom}
                      onDragEnd={this.handleDragAndZoom}
                      onZoom={this.handleDragAndZoom}
                      onZoomEnd={this.handleDragAndZoom}
                      onRotate={this.handleRotate}
                      onRotateEnd={this.handleRotate}
                      onMouseMove={this.handleMouseMove}
                      onRemove={this.handleCardRemove}
                    />
                  </Suspense>
                );
              })
            }
            { layers.length === 3
              && (
                <div className="add-layer__grid-wrapper">
                  <Button
                    startIcon={<AddIcon />}
                    onClick={this.handleAddDataLayerClick}
                  >
                    {t('zones-ops.multi-layer.steps.3.add-data-layer')}
                  </Button>
                </div>
              )}
          </div>
        </div>
        <div className="compare-layers-panel__map-instruments-panel">
          <MapControlsGroup
            buttons={[
              {
                icon: <TargetIcon />,
                onClick: this.handleTarget,
              },
            ]}
          />
          <MapControlsGroup
            buttons={[
              {
                icon: is3d
                  ? <D2Icon className="MuiSvgIcon-root" />
                  : <D3Icon />,
                onClick: this.handleDimensions,
              },
            ]}
          />
          <MapControlsGroup
            buttons={[
              {
                icon: <AddIcon />,
                onClick: this.handleZoomIn,
              },
              {
                icon: <MinusIcon />,
                onClick: this.handleZoomOut,
              },
            ]}
          />
          {
            isBingMapsEnabled()
            && (
              <MapControlsGroup
                buttons={[
                  {
                    icon: mapProvider === MapProvider.bing
                      ? <MapboxLogo className="MuiSvgIcon-root" />
                      : <BingLogo className="MuiSvgIcon-root" />,
                    onClick: () => onMapProviderClick(mapProvider),
                  },
                ]}
              />
            )
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const boundingBox = selectBoundingBox(state);
  const layers = selectLayers(state);
  const is3d = selectIs3d(state);
  const mapProvider = selectMapProvider(state);
  const width = window.innerWidth / (layers.length > 1 ? 2 : 1);

  return {
    t: ownProps.t,
    is3d,
    mapProvider,
    layers,
    fitBounds: [
      [boundingBox[0], boundingBox[1]],
      [boundingBox[2], boundingBox[3]],
    ],
    fitBoundsOptions: {
      padding: getPaddingBounds('field', width),
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  requestAllAssets: () => {
    const {
      farmUuid,
      fieldUuid,
    } = ownProps.match.params;

    dispatch(fetchAllAssets({
      farmUuid,
      fieldUuid,
    }));
  },
  onDimensionClick: (is3d) => {
    dispatch(setMapIs3d(!is3d));
  },
  onMapProviderClick: (mapProvider) => {
    if (mapProvider === MapProvider.mapbox) {
      dispatch(setMapProvider(MapProvider.bing));
    } else if (mapProvider === MapProvider.bing) {
      dispatch(setMapProvider(MapProvider.mapbox));
    }
  },
  onAddLayerClick: () => {
    dispatch(openPopup({
      type: POPUPS.addDataLayer,
    }));
  },
  onRemoveLayerClick: (layerId) => {
    dispatch(removeLayer(layerId));
  },
});

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CompareLayersPanel)));
