import React from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';

import Checkbox from '../../Checkbox';
import Tooltip from '../../Tooltip';

import './index.scss';

const UseInterpolationCheckbox = ({
  checked = false,
  classes,
  onChange = () => {},
}: {
  checked?: boolean,
  classes?: {
    root?: string;
  },
  onChange: (value: boolean) => void,
}) => {
  const { t } = useTranslation();

  return (
    <div className={clsx('use-interpolation-checkbox__root', classes?.root)}>
      <FormControlLabel
        classes={{
          root: 'use-interpolation-checkbox',
          label: 'use-interpolation-checkbox__label',
        }}
        control={(
          <Checkbox
            value={checked ? 2 : 0}
            onChange={(e) => onChange(e.target.checked)}
          />
        )}
        label={t('zones-ops.common.use-interpolated-data.label')}
      />
      <Tooltip
        classes={{
          tooltip: 'use-interpolation-checkbox__tooltip',
          label: 'use-interpolation-checkbox__tooltip-label',
        }}
        tooltip={t('zones-ops.common.use-interpolated-data.tooltip')}
      >
        <InfoIcon fontSize="small"/>
      </Tooltip>
    </div>
  );
};

export default UseInterpolationCheckbox;
