import i18n from 'i18next';

import { AssetType } from '../../constants/entities/asset';
import { AttributeStatistics, TransformedDataset } from '../../types/dataset';
import { OverviewItem } from '../../../components/Map/Controls/MiniLegend/types';
import { isNumber } from '../utils/number';
import { convertNumberToFormattedString } from '../../markup';
import { AreaUnit } from '../../../features/user/helpers/constants/user';
import { convertDateToDisplayFormat } from '../utils/date';
import { getI18nAreaUnit } from '../..';
import { SatelliteImageIndexStatistics } from '../../../features/satelliteImages/types/satelliteImage';

export const getAssetTypeLabel = (type: AssetType): string => {
  if (type === AssetType.satelliteImage) {
    return i18n.t('general.shared.satellite-monitoring');
  }

  if (type === AssetType.yieldDataset) {
    return i18n.t('general.shared.yield-data');
  }

  if (type === AssetType.asAppliedDataset) {
    return i18n.t('general.shared.as-applied-data');
  }

  if (type === AssetType.soilDataset) {
    return i18n.t('general.shared.soil-data');
  }

  if (type === AssetType.topographyMap) {
    return i18n.t('general.shared.topography');
  }

  if (type === AssetType.vectorAnalysisMap) {
    return i18n.t('general.shared.zones-map');
  }

  if (type === AssetType.equationMap) {
    return i18n.t('general.shared.equation-map');
  }

  return 'UNKNOWN TYPE';
};

export const getDatasetStatisticsItems = (
  viewType: string,
  statistics?: AttributeStatistics[] | null,
) => {
  if (!statistics) {
    return [];
  }

  const {
    attribute: attributeValue,
    ...statistic
  } = statistics.find(({ attribute }) => {
    return attribute.toLowerCase() === viewType.toLowerCase();
  }) || {};

  return Object.entries(statistic)
    .reduce<OverviewItem[]>((acc, [key, value]) => {
    if (isNumber(value)) {
      acc.push({
        label: i18n.t(`general.controls.map-legend.statistics.${key}`),
        value: convertNumberToFormattedString(parseFloat(value.toFixed(3)).toString()),
      });
    }

    return acc;
  }, []);
};

export const getDatasetOverviewItems = (dataset: TransformedDataset, areaUnit?: AreaUnit) => {
  const overviewItems = [
    {
      label: i18n.t('general.controls.map-legend.dataset-creation-date'),
      value: dataset.operationFileDate ? convertDateToDisplayFormat(dataset.operationFileDate) : '',
    },
    {
      label: i18n.t('general.controls.map-legend.operation-start-date'),
      value: dataset.operationStartDate ? convertDateToDisplayFormat(dataset.operationStartDate) : '',
    },
    {
      label: i18n.t('general.controls.map-legend.percent-of-field-area'),
      value: isNumber(dataset.fieldCoverage) ? `${(dataset.fieldCoverage * 100).toFixed(2)}%` : '',
    },
    {
      label: i18n.t('general.controls.map-legend.completed-area'),
      value: dataset.fieldCoveredArea && dataset.fieldCoveredArea !== -1
        ? `${convertNumberToFormattedString(dataset.fieldCoveredArea.toFixed(3))} ${getI18nAreaUnit(areaUnit)}`
        : '',
    },
    {
      label: i18n.t('general.controls.map-legend.amount-of-geometries'),
      value: dataset.number ? convertNumberToFormattedString(dataset.number.toString()) : '',
    },
  ];

  return overviewItems.filter((item) => item.value);
};

export const getSatelliteOverviewItems = (
  viewType: string,
  statistics?: SatelliteImageIndexStatistics[],
  compareToStatistics: SatelliteImageIndexStatistics[] = [],
) => {
  if (!statistics) {
    return [];
  }

  const {
    index: indexValue,
    ...statistic
  } = statistics.find(({ index }) => {
    return index?.toLowerCase() === viewType.toLowerCase();
  }) || {};
  const {
    index: indexCompareTo,
    ...compareToStatistic
  } = compareToStatistics.find(({ index }) => {
    return index?.toLowerCase() === viewType.toLowerCase();
  }) || {};

  return Object.entries(statistic)
    .reduce<OverviewItem[]>((acc, [key, value]) => {
    const compareToValue = compareToStatistic[key as keyof Omit<SatelliteImageIndexStatistics, 'index'>];

    return [
      ...acc,
      {
        label: i18n.t(`general.controls.map-legend.statistics.${key}`),
        value: convertNumberToFormattedString(parseFloat(value.toFixed(3)).toString()),
        delta: compareToValue ? (value - compareToValue).toFixed(3) : null,
      },
    ];
  }, []);
};
