import type { RasterSource } from '../types/map';

export const MAP_STYLE = {
  satellite_streets_v10: {
    style: 'mapbox://styles/mapbox/satellite-streets-v10',
    roadLayerId: 'road-link case',
  },
};

export const triggerMapResize = (delay = 0) => {
  setTimeout(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  }, delay);
};

export const getRasterSources = ({
  rasterSources = [],
  hideLayers = false,
  opacity,
}: {
  rasterSources?: string[],
  hideLayers?: boolean,
  opacity?: number,
}) => {
  if (hideLayers) {
    return [];
  }

  return rasterSources.map((url) => {
    return {
      url,
      before: MAP_STYLE.satellite_streets_v10.roadLayerId,
      id: url,
      opacity,
    };
  });
};

export const getBackgroundRasterSources = ({
  rasterSources,
  hideLayers = false,
  opacity,
  beforeId,
}: {
  rasterSources: string[],
  hideLayers?: boolean,
  opacity?: number,
  beforeId?: string | null,
}) => {
  if (hideLayers) {
    return [];
  }

  return rasterSources.map((url) => {
    return {
      url,
      before: beforeId ?? MAP_STYLE.satellite_streets_v10.roadLayerId,
      id: `background-${url}`,
      opacity,
    };
  });
};

export const getRasterSourceBeforeId = (
  topRasterSources: RasterSource[],
  hideTopLayer?: boolean,
) => {
  return topRasterSources[0] && !hideTopLayer ? topRasterSources[0].id : null;
};
