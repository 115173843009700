import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';

import {
  selectEquationMap,
  selectFarmUuid,
  selectFieldUuid,
  selectVectorAnalysisMap,
} from '../../field/fieldSelectors';
import { getAssetName, is3dMap, isVectorAnalysis } from '../../../helpers/functions/entities/assets';
import { fetchAssetFeatures } from '../../field/fieldAPI';
import { downloadAsFile } from '../../../helpers';
import {
  processingNotify,
  closeProcessingNotification,
} from '../../notifications/notificationsSlice';
import { errorNotify } from '../../notifications/helpers/functions/notify';
import { fetchSatellites, fetchVamapAttributesJson } from '../../field/fieldSlice';
import {
  openExportZonesMapPopup,
  openExportEquationMapsPopup,
} from '../../exportData/exportDataSlice';
import { openPopup } from '../popups/popupsSlice';
import { POPUPS } from '../popups/helpers/constants/popups';
import { GeoFormat } from '../../../helpers/constants/api';
import { AssetType } from '../../../helpers/constants/entities/asset';
import { findAttributeToPreselect } from '../../../helpers/functions/entities/attribute';

const initialState = {
  exportAttributesLoading: false,
  legend: {
    uuid: '',
    _chartScale: null,
  },
  selectedItemUuid: null,
  selectedItemGroupType: null,
  pinsInstrument: '',
  mapAttributes: {
    [AssetType.satelliteImage]: {
      viewType: null,
    },
    [AssetType.soilDataset]: {
      viewType: null,
      attribute: null,
    },
    [AssetType.yieldDataset]: {
      viewType: null,
      attribute: null,
    },
    [AssetType.asAppliedDataset]: {
      viewType: null,
      attribute: null,
    },
    [AssetType.topographyMap]: {
      viewType: null,
    },
    [AssetType.equationMap]: {
      geoMap: null,
    },
  },
  dataLayersTree: {
    viewType: 'default', // DataLayersTreeViewType
  },
};

export const exportZonesMap = createAsyncThunk(
  'fieldWorkflow/exportZonesMap',
  async (uuid, { getState, dispatch }) => {
    await dispatch(fetchVamapAttributesJson({ uuid }));

    const state = getState();
    const vamap = selectVectorAnalysisMap(state, uuid);

    dispatch(openExportZonesMapPopup({
      zonesMaps: [{
        ...vamap,
        fieldUuid: selectFieldUuid(state),
      }],
      archiveName: vamap.name,
    }));
  },
);

export const exportEquationMap = createAsyncThunk(
  'fieldWorkflow/exportEquationMap',
  (uuid, { getState, dispatch }) => {
    const state = getState();
    const equationMap = selectEquationMap(state, uuid);
    const fieldUuid = selectFieldUuid(state);

    dispatch(openExportEquationMapsPopup({
      equationMaps: [{
        ...equationMap,
        fieldUuid,
      }],
      archiveName: equationMap.name,
    }));
  },
);

const processGeojsonData = (response, name) => {
  return response.text()
    .then((jsonString) => {
      downloadAsFile(jsonString, `${name}.json`);
    });
};

const processGeotiffData = (response, name) => {
  return response.blob()
    .then((blob) => {
      downloadAsFile(blob, `${name}.tif`);
    });
};

const exportAssetData = ({
  farmUuid,
  fieldUuid,
  format,
  buffer,
  urlType,
  item,
}) => (dispatch) => {
  dispatch(processingNotify({
    message: i18n.t('export.notifications.downloadable-created'),
  }));

  return fetchAssetFeatures({
    farmUuid,
    fieldUuid,
    assetType: item._type,
    uuid: item.uuid,
    format,
    buffer,
    urlType,
  })
    .then((response) => {
      const name = getAssetName(item);

      if (format === GeoFormat.geojson) {
        return processGeojsonData(response, name);
      } if (format === GeoFormat.geotiff) {
        return processGeotiffData(response, name);
      }
    })
    .then(() => {
      dispatch(closeProcessingNotification());
    })
    .catch((error) => {
      dispatch(closeProcessingNotification());
      errorNotify({
        error,
        dispatch,
      });
    });
};

export const exportSourceData = (item) => (dispatch, getState) => {
  const state = getState();

  dispatch(openPopup({
    type: POPUPS.exportSourceData,
    item,
    onConfirm: ({
      type,
      buffer,
    }) => {
      dispatch(exportAssetData({
        farmUuid: selectFarmUuid(state),
        fieldUuid: selectFieldUuid(state),
        format: type,
        buffer,
        urlType: 'sourceDataUrl',
        item,
      }));
    },
  }));
};

export const exportOriginalData = (item) => (dispatch, getState) => {
  const state = getState();
  dispatch(exportAssetData({
    farmUuid: selectFarmUuid(state),
    fieldUuid: selectFieldUuid(state),
    format: GeoFormat.geojson,
    urlType: 'originDataUrl',
    item,
  }));
};

export const openLegend = (item) => (dispatch, getState) => {
  const state = getState();
  const farmUuid = selectFarmUuid(state);
  const fieldUuid = selectFieldUuid(state);

  if (isVectorAnalysis(item)) {
    let requiredSatImages;
    const satImagesLayer = (item.dataLayers || []).find((layer) => {
      return layer.satelliteImages;
    });

    dispatch(fetchVamapAttributesJson({
      uuid: item.uuid,
    }));

    if (item.satelliteImages) {
      requiredSatImages = item.satelliteImages.map(({ uuid }) => {
        return uuid;
      });
    } else if (satImagesLayer) {
      requiredSatImages = satImagesLayer.satelliteImages.map(({ uuid }) => {
        return uuid;
      });
    }

    if (requiredSatImages) {
      dispatch(fetchSatellites({
        farmUuid,
        fieldUuid,
        requiredSatImages,
      }));
    }
  } else if (is3dMap(item)) {
    dispatch(fetchVamapAttributesJson({
      uuid: item.vectorAnalysisMap.uuid,
    }));
  }

  dispatch(fieldWorkflowSlice.actions.setupLegend(item.uuid));
};

export const fieldWorkflowSlice = createSlice({
  name: 'fieldWorkflow',
  initialState,
  reducers: {
    resetLegend(state) {
      state.legend = initialState.legend;
    },
    reset() {
      return initialState;
    },
    selectAsset(state, action) {
      const attribute = findAttributeToPreselect(action.payload.asset);

      state.selectedItemUuid = action.payload.asset?.uuid;
      state.selectedItemGroupType = action.payload.groupType;

      if (attribute && action.payload.asset) {
        state.mapAttributes[action.payload.asset._type].attribute = attribute;
      }
    },
    setSelectedPinsInstrument(state, action) {
      state.pinsInstrument = action.payload;
    },
    setChartScaleLegend(state, action) {
      state.legend._chartScale = action.payload;
    },
    setupLegend(state, action) {
      state.legend.uuid = action.payload;
    },
    setMapSatelliteViewType(state, action) {
      state.mapAttributes[AssetType.satelliteImage].viewType = action.payload;
    },
    setMapSoilAttributes(state, action) {
      state.mapAttributes[AssetType.soilDataset] = {
        attribute: action.payload.attribute || state.mapAttributes[AssetType.soilDataset].attribute,
        viewType: action.payload.viewType || state.mapAttributes[AssetType.soilDataset].viewType,
      };
    },
    setMapYieldAttributes(state, action) {
      state.mapAttributes[AssetType.yieldDataset] = {
        attribute: action.payload.attribute || state.mapAttributes[AssetType.yieldDataset].attribute,
        viewType: action.payload.viewType || state.mapAttributes[AssetType.yieldDataset].viewType,
      };
    },
    setMapAsAppliedAttributes(state, action) {
      state.mapAttributes[AssetType.asAppliedDataset] = {
        attribute: action.payload.attribute || state.mapAttributes[AssetType.asAppliedDataset].attribute,
        viewType: action.payload.viewType || state.mapAttributes[AssetType.asAppliedDataset].viewType,
      };
    },
    setMapTopographyMapViewType(state, action) {
      state.mapAttributes[AssetType.topographyMap].viewType = action.payload;
    },
    setMapEquationMapGeoMap(state, action) {
      state.mapAttributes[AssetType.equationMap].geoMap = action.payload;
    },
    setDataLayersTreeViewType(state, action) {
      state.dataLayersTree.viewType = action.payload.viewType;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(exportZonesMap.pending, (state) => {
        state.exportAttributesLoading = true;
      })
      .addCase(exportZonesMap.fulfilled, (state) => {
        state.exportAttributesLoading = false;
      });
  },
});

export const {
  resetLegend,
  reset,
  selectAsset,
  setSatelliteFilter,
  setVamapFilter,
  setPinsGroupsFilter,
  setSelectedPinsInstrument,
  setChartScaleLegend,
  setMapSatelliteViewType,
  setMapSoilAttributes,
  setMapYieldAttributes,
  setMapAsAppliedAttributes,
  setMapTopographyMapViewType,
  setMapEquationMapGeoMap,
  setDataLayersTreeViewType,
} = fieldWorkflowSlice.actions;

export default fieldWorkflowSlice.reducer;
