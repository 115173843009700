import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { useAppDispatch, useAppSelector } from '../../../../../../app/store/helpers/functions';
import TextField from '../../../../../../components/TextField';
import GridSizeAdjustment from '../../../components/GridSizeAdjustment';
import PreviewTileDatasetsList from '../DatasetsList';
import { updateEquationMapsData } from '../../../createBatchEquationBasedAnalysisSlice';
import {
  selectEquationMapData,
  selectGridSize,
} from '../../../createBatchEquationBasedAnalysisSelectors';

import './index.scss';

export default function PreviewTileSettingsView({
  fieldUuid,
}: {
  fieldUuid: string,
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const equationMapData = useAppSelector((state) => selectEquationMapData(state, fieldUuid));
  const defaultGridSize = useAppSelector(selectGridSize);

  const handleTitleChange = (event: ChangeEvent<{ value: string }>) => {
    dispatch(updateEquationMapsData({
      [fieldUuid]: {
        title: event.target.value,
      },
    }));
  };

  const handleGridXChange = (value: number) => {
    dispatch(updateEquationMapsData({
      [fieldUuid]: {
        gridSize: {
          x: value,
        },
      },
    }));
  };

  const handleGridYChange = (value: number) => {
    dispatch(updateEquationMapsData({
      [fieldUuid]: {
        gridSize: {
          y: value,
        },
      },
    }));
  };

  return (
    <div className="preview-tile-settings-view">
      <Typography className="preview-tile-settings-view__header">
        {t('batch-equation-based.steps.preview.preview-tile.adjust-settings')}
      </Typography>
      <div className="preview-tile-settings-view__settings">
        <TextField
          required
          title={t('batch-equation-based.steps.preview.settings-view.map-title')}
          value={equationMapData.title}
          onChange={handleTitleChange}
        />
        <GridSizeAdjustment
          gridX={equationMapData.gridSize?.x ?? defaultGridSize.x}
          gridY={equationMapData.gridSize?.y ?? defaultGridSize.y}
          onGridXChange={handleGridXChange}
          onGridYChange={handleGridYChange}
        />
        <PreviewTileDatasetsList
          fieldUuid={fieldUuid}
        />
      </div>
    </div>
  );
}
