import { PREDEFINED_PREFIXES, PREDEFINED_VALUES } from '../../../../../helpers/components/autocomplete';
import { Label } from '../../../../field/types/field';
import { AutocompleteItem } from '../../../autocomplete/types/autocomplete';

export const transformToFilterUpdates = (
  autocompleteValue: AutocompleteItem[],
  labelsMap: Record<string, string[]> | null,
) => {
  return autocompleteValue.reduce((acc, { prefix, value }) => {
    if (prefix === PREDEFINED_PREFIXES.search) {
      acc.name = value;
    } else if (value === PREDEFINED_VALUES.allValues) {
      const labelsToAdd = labelsMap?.[prefix]?.map((v) => ({ name: prefix, value: v })) ?? [];
      acc.labels = [...acc.labels, ...labelsToAdd];
    } else {
      acc.labels.push({ name: prefix, value });
    }

    return acc;
  }, { labels: [], name: '' } as { labels: Label[], name: string });
};
