import {
  isEqual,
  isWithinInterval,
} from 'date-fns';

import { isNumber } from '../../../../../helpers/functions/utils/number';
import { prettifyNumber } from '../../../../../helpers/markup';
import { getFieldLink } from '../../../../../helpers/navigation';
import { StripeProductKey } from '../../../../stripePriceDataConfig/helpers/constants/stripeProduct';
import { PricingConfig } from '../../../../stripePriceDataConfig/types/config';
import { PricingPlan } from '../../../../user/helpers/constants/order';
import type {
  Currency,
  Organization,
} from '../../../../user/types/api';
import { UserOrder } from '../../../../user/types/order';
import type { UserAction } from '../../types/api';
import { ACTIVITY_LOG_ACTION_TO_STRIPE_PRODUCT_KEY_MAP } from '../constants/action';
import {
  getOrderEndDate,
  getOrderStartDate,
  isAnnualSubscription,
  isFreeTrialPlan,
  isPayAsYouGoPlan,
} from '../../../../user/helpers/functions/order';

const ACTIONS_WITHOUT_CONSUMED_AREA = ['COMMENT_PHOTO', 'NOTE_PHOTO', 'FIELD_NOTE', 'NOTE_COMMENT'];

const getActionFieldLink = ({ farmUuid, fieldUuid, operation }: UserAction) => {
  if (!farmUuid || !fieldUuid || operation === 'FIELD_DELETION') {
    return null;
  }

  return getFieldLink(farmUuid, fieldUuid);
};

const getActionConsumedArea = ({ operation, area }: UserAction) => {
  if (ACTIONS_WITHOUT_CONSUMED_AREA.includes(operation)) {
    return null;
  }

  return prettifyNumber(area, 2);
};

export const transformAction = (
  actions: UserAction[],
  organizations: Organization[],
  currentUser: {
    uuid: string,
    email: string,
  },
) => {
  return actions.map((action) => {
    const organization = organizations.find(({ uuid }) => uuid === action.organizationUuid);
    let userEmail: string | undefined;

    if (action.userUuid === currentUser.uuid) {
      userEmail = currentUser.email;
    } else {
      userEmail = organization?.users?.find(({ userUuid }) => userUuid === action.userUuid)?.email;
    }

    return {
      ...action,
      organization: organization?.name,
      user: userEmail || '',
      fieldLink: getActionFieldLink(action),
      area: getActionConsumedArea(action),
    };
  });
};

const isActionWithinOrderPeriod = (
  action: UserAction,
  order: UserOrder,
) => {
  const orderStartDate = getOrderStartDate(order);
  const orderEndDate = getOrderEndDate(order);
  const operationDate = new Date(action.operationDate);

  return orderStartDate
    && orderEndDate
    && isWithinInterval(operationDate, {
      start: orderStartDate,
      end: orderEndDate,
    })
    && !isEqual(operationDate, orderEndDate);
};

export const populateActionsEstimatedCosts = ({
  actions,
  pricingConfig,
  currency,
  orders = [],
}: {
  actions: UserAction[];
  pricingConfig?: PricingConfig;
  currency: Currency;
  orders: UserOrder[];
}) => {
  if (!pricingConfig || !currency) {
    return actions;
  }

  const payAsYouGoOrders = orders.filter((order) => {
    return isPayAsYouGoPlan(order)
      && order.status === 'INVOICE_PAID';
  });
  const fixedFeePlans = orders.filter((order) => {
    return (
      order.status === 'INVOICE_PAID'
      && (isAnnualSubscription(order) || isFreeTrialPlan(order))
    );
  });

  return actions.map((action) => {
    const payAsYouGoPlanAction = payAsYouGoOrders.some((order) => {
      return isActionWithinOrderPeriod(action, order);
    });
    const fixedFeePlanAction = fixedFeePlans.some((order) => {
      return isActionWithinOrderPeriod(action, order);
    });

    let costs = null;

    if (payAsYouGoPlanAction) {
      const payAsYouGoPlanConfig = pricingConfig[PricingPlan.MonthlyPayAsYouGo];
      const productKey = ACTIVITY_LOG_ACTION_TO_STRIPE_PRODUCT_KEY_MAP[action.operation];
      const productPriceConfig = productKey
        ? payAsYouGoPlanConfig[productKey].prices[currency]
        : null;
      const price = productPriceConfig?.price ?? 0;
      const unitsAmount = Number(action.unitsAmount) || 0;
      const area = Number(action.area) || 0;

      costs = productKey === StripeProductKey.AdditionalUserSeat
        ? price * unitsAmount
        : price * area;
    } else if (fixedFeePlanAction) {
      costs = 0;
    }

    return {
      ...action,
      costs: isNumber(costs)
        ? prettifyNumber(costs, 2)
        : costs,
    };
  });
};
