import React from 'react';
import { useSelector } from 'react-redux';

import FieldLegend from '../common';
import { selectField } from '../../../../../field/fieldSelectors';
import { selectLegend } from '../../../fieldWorkflowSelectors';
import { getAssetByUuid } from '../../../../../../helpers/functions/entities/lookup';

const MapLegend = () => {
  const field = useSelector(selectField);
  const { uuid } = useSelector(selectLegend);
  const legendItem = getAssetByUuid(uuid, field);

  return (
    <FieldLegend legend={legendItem} />
  );
};

export default MapLegend;
