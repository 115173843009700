import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

import { getSelectedOptionByUuid } from '../../helpers/components/autocomplete';
import {
  getPurposeItems,
  getTypeItems,
} from '../../helpers/analysis';
import Autocomplete from '../Autocomplete';
import ComboBox from '../ComboBox';
import Checkbox from '../Checkbox';
import Select from '../Select';
import Tooltip from '../Tooltip';
import AntSwitch from '../AntSwitch';
import ZonesMapsFilterTooltip from '../Tooltip/Tooltips/ZonesMapsFilterTooltip';
import FarmSelect from '../../features/farms/containers/FarmSelect';
import AmplitudeAnalytics from '../../helpers/classes/amplitudeAnalytics';
import { comparator } from '../../helpers/functions/utils/string';
import useCurrentPage from '../../hooks/useCurrentPage';

import './index.scss';

const ZonesMapsListFilter = ({
  labelsMap = {},
  fields = [],
  farm,
  field,
  type,
  purpose,
  labels,
  withRates,
  withLabelsSwitch,
  labelsExpanded,
  fieldsLoading,
  onFarmChange,
  onFieldChange,
  onFilterUpdate,
  onLabelsFilterGetOptions,
  onLabelsSwitchClick,
}) => {
  const { t } = useTranslation();
  const page = useCurrentPage();
  const fieldsOptions = fields
    .filter(({ farmUuid }) => (farm ? farm.uuid === farmUuid : true))
    .sort((a, b) => comparator(a.name, b.name))
    .map(({ uuid, title }) => ({ uuid, title }));

  const handleFilterChange = (filterType, value) => {
    onFilterUpdate({
      [filterType]: value,
    });

    AmplitudeAnalytics.trackZonesMapsFilterChanged({
      page,
      ...(filterType === 'type' ? { type: value } : {}),
      ...(filterType === 'purpose' ? { purpose: value } : {}),
      ...(filterType === 'withRates' ? { withRates: value } : {}),
      ...(filterType === 'labels' ? { labelsAmount: value.length } : {}),
    });
  };

  const handleFieldChange = (_event, value) => {
    onFieldChange(value);
    AmplitudeAnalytics.trackZonesMapsFilterChanged({
      page,
      fieldName: !!value,
    });
  };

  const handleFarmChange = (value) => {
    onFarmChange(value);
    AmplitudeAnalytics.trackZonesMapsFilterChanged({
      page,
      farmName: !!value,
    });
  };

  return (
    <div className="zones-maps-list-filter">
      <FarmSelect
        selectedFarmUuid={farm?.uuid}
        onFarmChange={handleFarmChange}
        className='zones-maps-list-filter__control'
      />
      <ComboBox
        title={t('general.shared.field')}
        placeholder={t('general.controls.select')}
        classes={{ root: 'zones-maps-list-filter__control' }}
        options={fieldsOptions}
        value={field}
        disableCloseOnSelect={false}
        loading={fieldsLoading}
        getOptionSelected={getSelectedOptionByUuid}
        onChange={handleFieldChange}
      />
      <Select
        id="typeFilter"
        clearable
        classes={{
          root: 'zones-maps-list-filter__control',
        }}
        label={(
          <div className="label-with-tooltip">
            {t('general.controls.zones-map-filters.type-label')}
            <Tooltip tooltip={<ZonesMapsFilterTooltip name={t('general.controls.zones-map-filters.type-label')} />}>
              <HelpOutlineOutlinedIcon
                className="info-icon"
                fontSize="small"
              />
            </Tooltip>
          </div>
        )}
        options={getTypeItems()}
        value={type}
        disabled={!field}
        onChange={(value) => handleFilterChange('type', value)}
      />
      <Select
        disabled={!field}
        id="purposeFilter"
        clearable
        classes={{
          root: 'zones-maps-list-filter__control',
        }}
        label={(
          <div className="label-with-tooltip">
            {t('general.controls.purpose')}
            <Tooltip tooltip={<ZonesMapsFilterTooltip name={t('general.controls.purpose')} />}>
              <HelpOutlineOutlinedIcon
                className="info-icon"
                fontSize="small"
              />
            </Tooltip>
          </div>
        )}
        options={getPurposeItems()}
        value={purpose}
        onChange={(value) => handleFilterChange('purpose', value)}
      />
      <Autocomplete
        limitTags={4}
        disableCustomLabel
        placeholder={t('zones-maps.tools-panel.filters.search-placeholder')}
        placeholderWithValue={t('field-profiler.tools-panel.add-filter')}
        searchLabel={t('field-profiler.tools-panel.search')}
        allValuesLabel={t('field-profiler.tools-panel.all-values')}
        options={labelsMap}
        value={labels}
        classes={{
          root: 'zones-maps-list-filter__control-autocomplete',
        }}
        onChange={(value) => handleFilterChange('labels', value)}
        onGetOptions={onLabelsFilterGetOptions}
      />
      <div className="zones-maps-list-filter__control zones-maps-list-filter__control-checkbox">
        <Checkbox
          disabled={!field}
          id="withRates"
          value={withRates ? 2 : 0}
          onChange={(e) => handleFilterChange('withRates', e.target.checked)}
        />
        <label
          className={clsx('zones-maps-list-filter__control-checkbox-label', {
            'zones-maps-list-filter__control-checkbox-label_disabled': !field,
          })}
          htmlFor="withRates"
        >
          {t('zones-maps.tools-panel.filters.with-rates-label')}
          <Tooltip tooltip={<ZonesMapsFilterTooltip name={t('zones-maps.tools-panel.filters.with-rates-label')} />}>
            <HelpOutlineOutlinedIcon
              className="info-icon"
              fontSize="small"
            />
          </Tooltip>
        </label>
      </div>
      {
        withLabelsSwitch
        && (
          <div className="zones-maps-list-filter__control-switch-wrapper">
            <AntSwitch
              isChecked={labelsExpanded}
              label={t('field-profiler.tools-panel.expand-all-labels')}
              onValueChange={onLabelsSwitchClick}
            />
          </div>
        )
      }
    </div>
  );
};

export default ZonesMapsListFilter;
