export enum StripeProductKey {
  AdditionalUserSeat = 'AdditionalUserSeat',
  BaseMonthlyFee = 'BaseMonthlyFee',
  DatasetImport = 'DatasetImport',
  EquationMapsCreation = 'EquationMapsCreation',
  Export = 'Export',
  FieldRegistration = 'FieldRegistration',
  FieldStorage = 'FieldStorage',
  FullAccessToEssentialFunctionalities = 'FullAccessToEssentialFunctionalities',
  PlanetImagery = 'PlanetImagery',
  YieldCleaningAndCalibration = 'YieldCleaningAndCalibration',
  YieldSyntheticMapCreation = 'YieldSyntheticMapCreation',
  ZonesVraMapsCreation = 'ZonesVRAMapsCreation',
}
