import React from 'react';
import { connect } from 'react-redux';

import { setSelectedVamapUuid } from '../../../cloneZonesMapSlice';
import { selectSelectedVamapUuid } from '../../../cloneZonesMapSelectors';
import { selectVectorAnalysisMaps } from '../../../../../field/fieldSelectors';
import useDataLayersList from '../../../../dataLayersView/hooks/useDataLayersList';

import './index.scss';

const CloneZonesMapPanel = ({
  vectorAnalysisMaps,
  selectedVamapUuid,
  onVamapClick,
}) => {
  const handleAssetItemClick = (item) => {
    onVamapClick(item.uuid);
  };

  const {
    dataLayersListComponent,
  } = useDataLayersList({
    assets: vectorAnalysisMaps,
    selectedUuid: selectedVamapUuid,
    onAssetClick: handleAssetItemClick,
  });

  return (
    <div className="clone-zones-map-panel">
      {dataLayersListComponent}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    vectorAnalysisMaps: selectVectorAnalysisMaps(state),
    selectedVamapUuid: selectSelectedVamapUuid(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onVamapClick: (uuid) => {
    dispatch(setSelectedVamapUuid(uuid));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CloneZonesMapPanel);
