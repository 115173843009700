import { useContext, useMemo, useEffect } from 'react';
import type { ChallengeName } from 'amazon-cognito-identity-js';

import { TokensBrokerContext } from '../containers/TokensBroker';
import { ClientMessageEventPayload, SsoMessageEvent } from '../types/message';
import { readSsoAppUrl } from '../../../../helpers/functions/utils/vendorConfigs/sso';
import { getAwsClientId } from '../../../../helpers/functions/utils/vendorConfigs/aws';
import TokensService from '../classes/TokensService';

export default function useTokensBroker({
  onSignInSuccess,
  onSignInError,
  onSignOutSuccess,
  onSignOutError,
  onTokensReceived,
  onTokensError,
}: {
  onSignInSuccess?: (challenge?: ChallengeName) => void;
  onSignInError?: (error: string) => void;
  onSignOutSuccess?: () => void;
  onSignOutError?: (error: string) => void;
  onTokensReceived?: (tokensExist: boolean) => void;
  onTokensError?: (error: string) => void;
}) {
  const iframeRef = useContext(TokensBrokerContext).ref;
  const ssoOrigin = useMemo(() => readSsoAppUrl(), []);
  const tokensService = useMemo(() => new TokensService(), []);

  useEffect(() => {
    const handleMessage = (event: SsoMessageEvent) => {
      if (event.origin !== ssoOrigin) {
        return;
      }

      switch (event.data.type) {
        case 'SIGN_IN_SUCCESS': {
          onSignInSuccess?.(event.data.challenge);
          break;
        }
        case 'SIGN_IN_ERROR': {
          onSignInError?.(event.data.error);
          break;
        }
        case 'TOKENS': {
          const tokensExist = Object.keys(event.data.payload.tokens).length > 0;
          tokensService.storeTokens(event.data.payload.tokens);
          onTokensReceived?.(tokensExist);
          break;
        }
        case 'TOKENS_ERROR': {
          const clientId = getAwsClientId();
          tokensService.clearUserTokens(clientId);
          onTokensError?.(event.data.error);
          break;
        }
        case 'SIGN_OUT_SUCCESS': {
          onSignOutSuccess?.();
          break;
        }
        case 'SIGN_OUT_ERROR': {
          const clientId = getAwsClientId();
          tokensService.clearUserTokens(clientId);
          onSignOutError?.(event.data.error);
          break;
        }
        default: {
          break;
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [
    ssoOrigin,
    tokensService,
    onSignInSuccess,
    onSignInError,
    onSignOutSuccess,
    onSignOutError,
    onTokensReceived,
    onTokensError,
  ]);

  const sendMessage = (message: ClientMessageEventPayload) => {
    if (!iframeRef.current) {
      return;
    }

    iframeRef.current.contentWindow?.postMessage(message, ssoOrigin);
  };

  const sendSignInMessage = (email: string, password: string) => {
    sendMessage({ type: 'SIGN_IN', payload: { email, password } });
  };

  const sendGetTokensMessage = () => {
    sendMessage({ type: 'GET_TOKENS' });
  };

  const sendSignOutMessage = () => {
    sendMessage({ type: 'SIGN_OUT' });
  };

  return {
    sendSignInMessage,
    sendSignOutMessage,
    sendGetTokensMessage,
  };
}
