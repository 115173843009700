import React, { useMemo } from 'react';
import clsx from 'clsx';

import ResetFiltersButton from '../ResetFiltersButton';
import { AssetGroupType } from '../../../../../helpers/constants/entities/asset';
import { VectorAnalysisFilters } from '../../types/analysis';
import { SatelliteFilters } from '../../types/satelliteImage';
import { PinsGroupsFilters } from '../../types/pinsGroups';
import {
  AssetGroupFilterKey,
  DefaultAssetGroupFilters,
} from '../../types/assetGroup';
import FilterIcon from '../FilterIcon';
import Label from '../../../../../components/Label';
import { generateFiltersSummary } from '../../helpers/functions/filtersSummary';

import './index.scss';

type FiltersValue =
  SatelliteFilters |
  VectorAnalysisFilters |
  PinsGroupsFilters |
  DefaultAssetGroupFilters;

const SelectedFilters = ({
  type,
  filtersValue,
  classes,
  onFilterClear = () => {},
  onFiltersClear = () => {},
}: {
  type: AssetGroupType;
  filtersValue: FiltersValue,
  classes?: {
    root?: string;
  };
  onFilterClear?: (key: AssetGroupFilterKey) => void;
  onFiltersClear?: () => void;
}) => {
  const filterSummary = useMemo(() => {
    return generateFiltersSummary({ [type]: filtersValue });
  }, [filtersValue, type]);
  const isFilterSet = useMemo(() => filterSummary.some((filter) => filter.value), [filterSummary]);

  if (!isFilterSet) {
    return <div className='selected-filters__empty'></div>;
  }

  return (
    <div className={clsx('selected-filters', classes?.root)}>
      <div className='selected-filters__label'>
        <FilterIcon className='selected-filters__icon' />
      </div>
      {filterSummary.map(({ label, value, key }) => {
        const title = `${label}: ${value}`;
        return (
          <Label
            classes={{
              label: 'selected-filters__item',
            }}
            key={title}
            text={title}
            onDelete={() => onFilterClear(key)}
          />
        );
      })}
      <ResetFiltersButton classes={{ root: 'selected-filters__reset-button' }} onClick={onFiltersClear}/>
    </div>
  );
};

export default SelectedFilters;
