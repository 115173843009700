import getPlatformContextQuery from './graphql/queries/getPlatformContext.gql';
import getFieldsQuery from './graphql/queries/getFields.gql';
import type {
  Field,
  GetFieldsResponse,
  GetPlatformContextArg,
  GetPlatformContextResponse,
  GetPlatformContextResult,
  PlatformContextEvent,
} from './types/api';
import {
  DEFAULT_STATUSES,
  DEFAULT_SUB_FIELD_TYPES,
} from './helpers/constants/contextEventFilter';
import { extractFields, getUniqueFieldUuids } from '../farms/helpers/functions/farms';
import { errorNotify } from '../notifications/helpers/functions/notify';
import {
  batchOperations,
  splitIntoChunks,
} from '../../helpers/functions/utils/batchOperations';
import { BATCH_SIZE } from '../../helpers/constants/utils/batchOperations';
import { emptyAPI } from '../emptyApi/emptyAPI';
import { CustomError } from '../../helpers/functions/utils/errorHandling';
import { selectIsLoaded as selectIsUserDataLoaded } from '../user/userSelectors';
import { RootState } from '../../app/store/helpers/types';

// BE limitation. See https://trello.com/c/j1WO1KN6
const FIELDS_CHUNK_SIZE = 100;

export const asyncOperationsAPI = emptyAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getPlatformContext: builder.query<GetPlatformContextResult, GetPlatformContextArg>({
      queryFn: async (
        {
          filter,
          options,
        },
        {
          getState,
          dispatch,
        },
        _extraOptions,
        baseQuery,
      ) => {
        const getPlatformContextResult = await baseQuery({
          document: getPlatformContextQuery,
          variables: {
            input: {
              statuses: DEFAULT_STATUSES,
              subFieldTypes: DEFAULT_SUB_FIELD_TYPES,
              ...filter,
            },
          },
        });
        let result: {
          data?: {
            fields: Field[];
            events: PlatformContextEvent[];
          };
          error?: any;
        };

        if (getPlatformContextResult.error) {
          errorNotify({
            error: new CustomError('[Async Operations] Unable to fetch events.', {
              cause: getPlatformContextResult.error,
            }),
            dispatch,
          });

          result = {
            error: getPlatformContextResult.error,
          };
        } else {
          const { getPlatformContext } = getPlatformContextResult.data as GetPlatformContextResponse;

          if (options?.skipFields) {
            result = {
              data: {
                fields: [],
                events: getPlatformContext.events,
              },
            };
          } else {
            const fieldUuids = getUniqueFieldUuids(getPlatformContext.events);
            let fields: Field[] = [];

            if (fieldUuids.length !== 0) {
              try {
                const fieldUuidsChunks = splitIntoChunks(fieldUuids, FIELDS_CHUNK_SIZE);
                const fieldsChunksResult = await batchOperations(async (fieldUuidsChunk) => {
                  const getFieldsChunkResult = await baseQuery({
                    document: getFieldsQuery,
                    variables: {
                      fieldUuids: fieldUuidsChunk,
                    },
                  });

                  if (getFieldsChunkResult.error) {
                    throw new CustomError('[Async Operations] Unable to fetch fields.', {
                      cause: getFieldsChunkResult.error,
                    });
                  }

                  const { getFarms } = getFieldsChunkResult.data as GetFieldsResponse;

                  return getFarms;
                }, fieldUuidsChunks, BATCH_SIZE);

                fields = extractFields<Field>(fieldsChunksResult.flat());

                result = {
                  data: {
                    fields,
                    events: getPlatformContext.events,
                  },
                };
              } catch (error) {
                const state = getState() as RootState;
                const userDataLoaded = selectIsUserDataLoaded(state);

                if (userDataLoaded) {
                  errorNotify({
                    error,
                    dispatch,
                  });
                }

                result = {
                  error: (error as Error).cause,
                };
              }
            } else {
              result = {
                data: {
                  fields: [],
                  events: getPlatformContext.events,
                },
              };
            }
          }
        }

        return result;
      },
    }),
  }),
});

export const { useGetPlatformContextQuery } = asyncOperationsAPI;
