import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Auth } from '@aws-amplify/auth';

import NewPasswordForm from '../../../../components/NewPasswordForm';
import useDidMount from '../../../../../../../hooks/useDidMount';
import { errorNotify } from '../../../../../../notifications/helpers/functions/notify';
import { CustomError } from '../../../../../../../helpers/functions/utils/errorHandling';
import useAuthenticate from '../../../../hooks/useAuthenticate';
import { getDefaultRoute, getRedirectedFrom } from '../../../../helpers/functions/navigation';

const NewPasswordPanel = ({
  onLoading,
}: {
  onLoading: (loading: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { signOut } = useAuthenticate();

  useDidMount(() => {
    signOut();
  });

  const onChangePasswordClick = async (password: string) => {
    onLoading(true);

    const user = await Auth.currentAuthenticatedUser();

    Auth.completeNewPassword(user, password)
      .then(() => {
        const redirectedFrom = getRedirectedFrom(location.search);
        const url = redirectedFrom || getDefaultRoute();

        onLoading(false);
        history.replace(url);
      })
      .catch((e) => {
        onLoading(false);
        errorNotify({
          error: new CustomError('[UI Authentication] Unable to set new password.', {
            cause: e,
          }),
          message: e.message,
          dispatch,
        });
      });
  };

  return (
    <NewPasswordForm
      onChangePasswordClick={onChangePasswordClick}
    />
  );
};

export default NewPasswordPanel;
