import React, {
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import StarIcon from '@material-ui/icons/Star';
import {
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { TransformedSatelliteImage } from '../../../../satelliteImages/types/satelliteImage';
import Button from '../../../../../components/Button';
import ButtonMenu from '../../../../../components/ButtonMenu';

import './index.scss';

type Mode = 'all' | 'last6yrs' | 'last10yrs';

const getDiffInYears = (date: string): number => {
  return (new Date()).getFullYear() - (new Date(date)).getFullYear();
};

const getRecommendedUuids = (images: TransformedSatelliteImage[], mode: Mode): string[] => {
  return images.filter((image) => {
    const yearsDiff = getDiffInYears(image.satelliteImage.acquisitionDate || '');

    return mode === 'all'
        || (mode === 'last10yrs' && yearsDiff <= 10)
        || (mode === 'last6yrs' && yearsDiff <= 6);
  })
    .map((image) => image.uuid);
};

export default function RecommendedSatelliteImagesPicker({
  dropdownView = false,
  allImages = [],
  filteredImages = [],
  onSelect,
}: {
  dropdownView?: boolean;
  allImages?: TransformedSatelliteImage[];
  filteredImages?: TransformedSatelliteImage[];
  onSelect: (uuids: string[]) => void;
}) {
  const { t } = useTranslation();

  const isEmpty = useMemo(() => {
    return allImages.every((image) => !image.recomendedForMutliYearAnalytics);
  }, [allImages]);
  const recommendedImages = useMemo(() => {
    return filteredImages.filter((image) => image.recomendedForMutliYearAnalytics);
  }, [filteredImages]);
  const oldestRecommendedImage = recommendedImages[recommendedImages.length - 1];

  const disabled = isEmpty || !oldestRecommendedImage;

  const handleRecommendedImagesSelect = (mode: Mode) => {
    onSelect(getRecommendedUuids(recommendedImages, mode));
  };

  const noImagesMessage = useMemo(() => {
    if (isEmpty) {
      return t('general.controls.satellite-filters.no-recommended-for-field');
    }

    if (!oldestRecommendedImage) {
      return t('general.controls.satellite-filters.no-recommended-for-filter');
    }

    return '';
  }, [isEmpty, oldestRecommendedImage, t]);

  const yearsDiff = getDiffInYears(oldestRecommendedImage?.satelliteImage?.acquisitionDate || '');
  const modes: { label: string; id: Mode }[] = [
    {
      label: t('general.controls.satellite-filters.for-all-time'),
      id: 'all',
    },
  ];

  if (yearsDiff >= 6) {
    modes.push({
      label: t('general.controls.satellite-filters.last-6-years'),
      id: 'last6yrs',
    });
  }

  if (yearsDiff >= 10) {
    modes.push({
      label: t('general.controls.satellite-filters.last-10-years'),
      id: 'last10yrs',
    });
  }

  return (
    <div
      className={clsx('recommended-satellite-images-picker', {
        'recommended-satellite-images-picker_dropdown': dropdownView,
      })}
      title={noImagesMessage}
    >
      {dropdownView && (
        <>
          <ButtonMenu
            size="small"
            classes={{
              button: {
                root: 'recommended-satellite-images-picker__button',
                startIcon: 'recommended-satellite-images-picker__start-icon',
                endIcon: 'recommended-satellite-images-picker__end-icon',
              },
            }}
            items={modes}
            disabled={disabled}
            startIcon={<StarIcon fontSize="small" />}
            onItemClick={(item) => handleRecommendedImagesSelect(item.id)}
          >
            {t('general.controls.satellite-filters.recommended')}
          </ButtonMenu>
        </>
      )}
      {!dropdownView && !disabled && (
        <>
          <StarIcon color="primary" fontSize="small" />
          <Typography>
            {t('general.controls.satellite-filters.recommended')}:
          </Typography>
          <div className='recommended-satellite-images-picker__list'>
            {modes.map((mode) => {
              return (
                <Button
                  key={mode.id}
                  className="recommended-satellite-images-picker__list-item"
                  onClick={() => handleRecommendedImagesSelect(mode.id)}
                >
                  {mode.label}
                </Button>
              );
            })}
          </div>
        </>
      )}
      {!dropdownView && disabled && (
        <Typography
          className='recommended-satellite-images-picker__empty-message'
        >
          {noImagesMessage}
        </Typography>
      )}
    </div>
  );
}
