import { SessionStorage } from '../../../../helpers/classes/cognitoStorage/sessionStorage';

export default class TokensService {
  private readonly storage: SessionStorage;

  private readonly itemPrefix = 'CognitoIdentityServiceProvider';

  constructor() {
    this.storage = SessionStorage.getInstance();
  }

  private getUserId(clientId: string): string | null {
    return this.storage.getItem(`${this.itemPrefix}.${clientId}.LastAuthUser`);
  }

  storeTokens(tokens: Record<string, string>): void {
    Object.entries(tokens).forEach(([key, value]) => {
      this.storage.setItem(key, value);
    });
  }

  clearUserTokens(clientId: string): void {
    const userId = this.getUserId(clientId);

    if (!userId) {
      return;
    }

    this.storage.removeItem(`${this.itemPrefix}.${clientId}.${userId}.idToken`);
    this.storage.removeItem(`${this.itemPrefix}.${clientId}.${userId}.accessToken`);
    this.storage.removeItem(`${this.itemPrefix}.${clientId}.${userId}.refreshToken`);
    this.storage.removeItem(`${this.itemPrefix}.${clientId}.${userId}.clockDrift`);
    this.storage.removeItem(`${this.itemPrefix}.${clientId}.LastAuthUser`);
    this.storage.removeItem(`${this.itemPrefix}.${clientId}.${userId}.userData`);
  }
}
