import React from 'react';

import { DataLayersListNode } from '../../../../types/dataLayersList';
import SimpleItem from '../../../ListView/SimpleItem';
import { TransformedDataset } from '../../../../../../../helpers/types/dataset';
import DatasetItem from '../../../Items/DatasetItem';

export default function DatasetNode({
  listNode,
  dataset,
  selectedItemUuid,
  disabled,
  onClick,
}: {
  listNode: DataLayersListNode;
  dataset: TransformedDataset;
  selectedItemUuid: string;
  disabled?: boolean;
  onClick: () => void;
}) {
  const isItemSelected = listNode.uuid === selectedItemUuid;

  return (
    <SimpleItem
      disabled={disabled}
      isSelected={isItemSelected}
      onClick={onClick}
    >
      <DatasetItem dataset={dataset} />
    </SimpleItem>
  );
}
