import React, {
  ChangeEvent,
  Fragment,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react';
import { nanoid } from '@reduxjs/toolkit';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  AutocompleteProps,
  AutocompleteGetTagProps,
} from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormHelperTextProps as FormHelperText, Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Label from '../Label';
import Button from '../Button';
import Tooltip from '../Tooltip';

import './index.scss';

export interface Option<T = string> {
  title: string,
  value: T,
}

interface Action {
  icon?: ReactNode,
  title?: string,
  isButton?: boolean,
}

export interface ComboBoxProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
> {
  disabled?: boolean,
  loading?: boolean,
  classes?: {
    root?: string,
    autocomplete?: string,
    input?: string,
    inputRoot?: string,
    listbox?: string,
    noOptions?: string,
    option?: string,
    outline?: string,
    label?: string,
    tooltip?: string,
    tooltipLabel?: string,
    endAdornment?: string,
  },
  title?: string,
  placeholder?: string,
  multiple?: Multiple,
  limitTags?: number,
  noOptionsText?: string,
  // TODO: replace Option type with type from Autocomplete component
  options: AutocompleteProps<Option<T>, Multiple, DisableClearable, false>['options'],
  value?: AutocompleteProps<Option<T>, Multiple, DisableClearable, false>['value'],
  disableClearable?: DisableClearable,
  disableCloseOnSelect?: boolean,
  required?: boolean,
  infoTooltip?: string,
  error?: boolean;
  helperText?: ReactNode;
  startAdornment?: ReactNode;
  FormHelperTextProps?: Partial<FormHelperText>;
  actions?: Action[],
  renderAction?: (action: Action) => ReactElement,
  renderOption?: (o: Option<T>) => ReactElement,
  filterOptions?: AutocompleteProps<Option<T>, Multiple, DisableClearable, false>['filterOptions'],
  'data-test'?: string,
  onActionClick?:() => void,
  // TODO: remove e from onChange signature
  onChange: AutocompleteProps<Option<T>, Multiple, DisableClearable, false>['onChange'],
  getOptionSelected: (o: Option<T>, v: Option<T>) => boolean,
  getOptionDisabled?: (o: Option<T>) => boolean,
  onSearchValueChange?: (e: ChangeEvent<HTMLInputElement>) => void,
}

export default function ComboBox<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
>({
  disabled = false,
  loading = false,
  classes = {},
  title,
  placeholder,
  multiple,
  limitTags = -1,
  options = [],
  value,
  disableClearable,
  disableCloseOnSelect = true,
  required,
  infoTooltip,
  actions = [],
  noOptionsText,
  error,
  helperText,
  startAdornment,
  FormHelperTextProps,
  'data-test': dataTest,
  renderAction,
  renderOption,
  filterOptions,
  onActionClick,
  onChange,
  getOptionSelected,
  getOptionDisabled,
  onSearchValueChange = () => {},
}: ComboBoxProps<T, Multiple, DisableClearable>) {
  const id: string = useMemo(() => nanoid().toString(), []);
  const isValuePresent = Array.isArray(value) && value.length === 0 ? false : !!value;
  let otherProps = {};

  if (multiple) {
    otherProps = {
      limitTags,
      renderTags: (tags: Option<T>[], getCustomizedTagProps: AutocompleteGetTagProps) => {
        return tags.map((tag: Option<T>, index: number) => {
          const props = getCustomizedTagProps({ index });

          return (
            <Label
              classes={{
                label: 'autocomplete__label',
              }}
              key={index}
              text={tag.title}
              {...props}
            />
          );
        });
      },
    };
  }

  return (
    <div
      data-test={dataTest}
      className={`combobox ${classes.root || ''}`}
    >
      {
        title
          && (
            <label htmlFor={`combobox_${id}`} className={clsx('combobox__label', classes.label)}>
              {title}
              {required && <span className="required-asterisk">*</span>}
              {
                infoTooltip
                  && (
                    <Tooltip
                      classes={{
                        tooltip: classes?.tooltip,
                        label: clsx('combobox__tooltip-label', classes?.tooltipLabel),
                      }}
                      tooltip={infoTooltip}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                  )
              }
            </label>
          )
      }
      <Autocomplete
        {...otherProps}
        id={`combobox_${id}`}
        multiple={multiple}
        disabled={disabled}
        disableCloseOnSelect={disableCloseOnSelect}
        disableClearable={disableClearable}
        popupIcon={<ExpandMoreIcon classes={{ root: 'expand-icon' }} />}
        options={options}
        value={value}
        onChange={onChange}
        classes={{
          root: clsx(classes.autocomplete, {
            'autocomplete-clearable': !disableClearable,
          }),
          listbox: classes.listbox,
          noOptions: classes.noOptions,
          option: clsx('combobox__option', classes.option),
          endAdornment: classes?.endAdornment,
        }}
        noOptionsText={noOptionsText}
        getOptionSelected={getOptionSelected}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={(option) => option.title}
        renderOption={renderOption}
        filterOptions={filterOptions}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                'data-test': `${dataTest}-input`,
                ...(
                  loading
                    ? {
                      endAdornment: (
                        <>
                          <CircularProgress className="combobox__loader" size={15}/>
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }
                    : {}
                ),
                classes: {
                  root: clsx('combobox__input-root', classes?.inputRoot, {
                    'combobox__with-clear-icon-and-loading': !disableClearable && loading && value,
                  }),
                  input: clsx('combobox__input-input', classes?.input),
                  focused: 'combobox_focused',
                  notchedOutline: clsx('combobox__outline', classes?.outline),
                  marginDense: 'combobox__input-root_margin-dense',
                },
                startAdornment: (
                  <>
                    {startAdornment}
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
              error={error}
              helperText={helperText}
              variant="outlined"
              size="small"
              placeholder={isValuePresent ? '' : placeholder}
              onChange={onSearchValueChange}
              FormHelperTextProps={FormHelperTextProps}
            />
          );
        }}
        PaperComponent={({ children }) => {
          return (
            <Paper
              onMouseDown={(event) => event.preventDefault()}
            >
              {children}
              {!!actions?.length && (
                <div className="combobox__listbox-actions">
                  {actions.map((action, index) => {
                    return (
                      <Fragment key={index}>
                        {(action.isButton ?? true)
                          ? (
                            <Button
                              key={index}
                              onClick={onActionClick}
                              className="combobox__listbox-actions-item"
                            >
                              {renderAction?.(action)}
                            </Button>
                          )
                          : renderAction?.(action)}
                      </Fragment>
                    );
                  })}
                </div>
              )}
            </Paper>
          );
        }}
      />
    </div>
  );
}
