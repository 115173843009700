import { formatAcquisitionDate } from '../../../../../helpers/satellite';
import { TransformedSatelliteImage } from '../../../../satelliteImages/types/satelliteImage';
import {
  AsAppliedDatasetVariable,
  EquationMapVariable,
  InputDataVariable,
  SatelliteImagesVariable,
  SoilDatasetVariable,
  TopographyMapVariable,
  VectorAnalysisMapVariable,
  YieldDatasetVariable,
} from '../../types/inputDataVariable';

export const isSatelliteImagesVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is SatelliteImagesVariable => {
  return 'satelliteImageUuids' in dataVariable;
};

export const isSoilDatasetVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is SoilDatasetVariable => {
  return 'soilDatasetUuid' in dataVariable;
};

export const isYieldDatasetVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is YieldDatasetVariable => {
  return 'yieldDatasetUuid' in dataVariable;
};

export const isAsAppliedDatasetVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is AsAppliedDatasetVariable => {
  return 'asAppliedDatasetUuid' in dataVariable;
};

export const isTopographyMapVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is TopographyMapVariable => {
  return 'topographyMapUuid' in dataVariable;
};

export const isVectorAnalysisMapVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is VectorAnalysisMapVariable => {
  return 'vectorAnalysisMapUuid' in dataVariable;
};

export const isEquationMapVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is EquationMapVariable => {
  return 'equationMapUuid' in dataVariable;
};

export const getSatelliteImagesVariableName = (
  satelliteImages?: TransformedSatelliteImage[],
  selectedSatelliteImageUuids?: string[] | null,
) => {
  const images = (satelliteImages ?? []).filter((image) => {
    return selectedSatelliteImageUuids?.includes(image.uuid);
  }, []) ?? [];

  return images
    .map((image) => {
      return formatAcquisitionDate(image.satelliteImage.acquisitionDate);
    })
    .join(', ');
};
