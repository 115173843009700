import { AssetType } from '../../../../../helpers/constants/entities/asset';
import {
  areAsAppliedDatasets,
  areSatelliteImages,
  areSoilDatasets,
  areTopographyMaps,
  areVectorAnalysisMaps,
  areYieldDatasets,
} from '../../../../../helpers/functions/entities/assets';
import { filterVamaps } from '../../../filters/helpers/functions/analysis';
import { filterAssetGroupItems } from '../../../filters/helpers/functions/assetGroup';
import { filterDatasets } from '../../../filters/helpers/functions/dataset';
import { filterSatelliteImages } from '../../../filters/helpers/functions/satelliteImage';
import { AssignableAsset, DataLayersListFilters } from '../../types/dataLayersList';

export const filterDataLayersList = (
  assets: AssignableAsset[],
  filters: DataLayersListFilters,
): AssignableAsset[] => {
  if (areSatelliteImages(assets)) {
    return filterSatelliteImages(filters[AssetType.satelliteImage], assets);
  }

  if (areSoilDatasets(assets)) {
    return filterDatasets(filters[AssetType.soilDataset], assets);
  }

  if (areYieldDatasets(assets)) {
    return filterDatasets(filters[AssetType.yieldDataset], assets);
  }

  if (areAsAppliedDatasets(assets)) {
    return filterDatasets(filters[AssetType.asAppliedDataset], assets);
  }

  if (areTopographyMaps(assets)) {
    return filterAssetGroupItems(filters[AssetType.topographyMap], assets);
  }

  if (areVectorAnalysisMaps(assets)) {
    return filterVamaps(filters[AssetType.vectorAnalysisMap], assets);
  }

  return [];
};
