import { API, graphqlOperation } from '@aws-amplify/api';

import getCombinedUserQuery from './graphql/queries/getCombinedUser.gql';
import getOrdersAndAdditionalUserDataQuery from './graphql/queries/getOrdersAndAdditionalUserData.gql';
import getUserTotalAreaQuery from './graphql/queries/getUserTotalArea.gql';
import saveUserDataMutation from './graphql/mutations/saveUserData.gql';
import saveOrganizationMutation from './graphql/mutations/saveOrganization.gql';
import saveStripeCustomerMutation from './graphql/mutations/saveStripeCustomer.gql';

export const getCombinedUser = async () => {
  const { data } = await API.graphql(graphqlOperation(getCombinedUserQuery));

  return data;
};

export const getOrdersAndAdditionalUserData = async ({
  stripeCustomerId,
}) => {
  const { data } = await API.graphql(graphqlOperation(getOrdersAndAdditionalUserDataQuery, {
    getStripeCustomerInput: { customerId: stripeCustomerId },
  }));

  return data;
};

export const getUserTotalArea = async () => {
  const { data } = await API.graphql(graphqlOperation(getUserTotalAreaQuery));

  return data.getUserData;
};

export const saveUserData = async ({
  identity,
  language,
  areaUnit,
  country,
  companyType,
  phoneNumber,
  email,
  acceptedTermsAndConditions,
}) => {
  const { data } = await API.graphql(graphqlOperation(saveUserDataMutation, {
    input: {
      ...(identity ? { identity } : null),
      ...(language ? { language } : null),
      ...(areaUnit ? { areaUnit } : null),
      ...(country ? { country } : null),
      ...(companyType ? { companyType } : null),
      ...(phoneNumber ? { phoneNumber } : null),
      ...(email ? { email } : null),
      ...(acceptedTermsAndConditions ? { acceptedTermsAndConditions } : null),
    },
  }));

  return data.saveUserData;
};

export const saveStripeCustomer = async ({ phoneNumber }) => {
  const { data } = await API.graphql(graphqlOperation(saveStripeCustomerMutation, {
    input: { phoneNumber },
  }));

  return data.saveStripeCustomer;
};

export const saveOrganization = async (anOrganization) => {
  const { data } = await API.graphql(graphqlOperation(saveOrganizationMutation, {
    input: anOrganization,
  }));

  return data.saveOrganization;
};
