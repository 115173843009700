import { Fragment } from 'react';

import { ProcessingType } from '../../helpers/constants/ui';
import CalibrateTabContent from './Tabs/Calibrate/Content';
import CalibrateTabRightActions from './Tabs/Calibrate/RightActions';
import CleanTabContent from './Tabs/Clean/Content';
import CleanTabRightActions from './Tabs/Clean/RightActions';
import CalibrateCleanTabContent from './Tabs/CalibrateClean/Content';
import CalibrateCleanTabRightActions from './Tabs/CalibrateClean/RightActions';
import FastTabContent from './Tabs/Fast/Content';
import FastTabRightActions from './Tabs/Fast/RightActions';

export const CONFIG = {
  [ProcessingType.fast]: {
    content: FastTabContent,
    leftActions: Fragment,
    rightActions: FastTabRightActions,
  },
  [ProcessingType.clean]: {
    content: CleanTabContent,
    leftActions: Fragment,
    rightActions: CleanTabRightActions,
  },
  [ProcessingType.calibrateClean]: {
    content: CalibrateCleanTabContent,
    leftActions: Fragment,
    rightActions: CalibrateCleanTabRightActions,
  },
  [ProcessingType.calibrate]: {
    content: CalibrateTabContent,
    leftActions: Fragment,
    rightActions: CalibrateTabRightActions,
  },
} as const;
