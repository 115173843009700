import React from 'react';
import {
  Trans,
  useTranslation,
} from 'react-i18next';
import Link from '@material-ui/core/Link';

import useReportIssuePopup from '../../../../../hooks/useReportIssuePopup';
import { useAppSelector } from '../../../../../app/store/helpers/functions';
import {
  selectFieldUuid,
  selectName,
} from '../../../../field/fieldSelectors';

import './index.scss';

export default function NoSatelliteMonitoringMessage() {
  const { t } = useTranslation();
  const { openReportIssuePopup } = useReportIssuePopup();

  const fieldName = useAppSelector(selectName);
  const fieldUuid = useAppSelector(selectFieldUuid);

  const handleSubmitRequestButtonClick = () => {
    openReportIssuePopup({
      commentTemplate: t('field.activate-satellite-monitoring', {
        fieldName,
        fieldUuid,
      }),
      successMessage: t('general.notifications.submit-request-success'),
    });
  };

  return (
    <Trans i18nKey="field.notifications.no-satellite-monitoring">
      No monitoring
      <Link
        className='link no-satellite-monitoring-message__link'
        component="button"
        onClick={handleSubmitRequestButtonClick}
      >
        submit a request
      </Link>
    </Trans>
  );
}
