import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { isSatelliteImage } from '../../../../../../../helpers/functions/entities/assets';
import VariableNameDataLayersTreePanel from '../../../../components/VariableNameDataLayersTreePanel';
import SelectAttributePanel from '../../../../../dataLayersView/components/DataLayersTree/Panels/SelectAttributePanel';
import {
  selectAsAppliedDatasets,
  selectSoilDatasets,
  selectTopographyMaps,
  selectYieldDatasets,
  selectSatelliteLoading,
  selectSatelliteImages,
  selectVectorAnalysisMaps,
  selectEquationMaps,
} from '../../../../../../field/fieldSelectors';
import useDataLayersTree from '../../../../../dataLayersView/hooks/useDataLayersTree';
import { DataLayersTreeNode } from '../../../../../dataLayersView/types/dataLayersTree';
import { getDataVariableCheckedLayers } from '../../../../helpers/functions/variables';
import {
  ASSET_TYPE_TO_ASSET_GROUP,
  AssetGroupType,
} from '../../../../../../../helpers/constants/entities/asset';
import { AssignableAsset } from '../../../../types/variable';
import type { TransformedAsset } from '../../../../../../../helpers/types';
import { InputDataVariable } from '../../../../../dataVariable/types/inputDataVariable';

import './index.scss';

export default function SelectDataVariablePanel({
  dataVariable,
  variableError,
  selectedDataset,
  attributes,
  selectedAttribute,
  onAssetItemClick = () => {},
  onAssetItemCheck = () => {},
  onAttributeChange = () => {},
  onVariableChange = () => {},
}: {
  dataVariable: InputDataVariable,
  variableError: string,
  selectedDataset?: AssignableAsset,
  attributes: { title: string, value: string }[],
  selectedAttribute: string,
  onAssetItemClick: (item: AssignableAsset, type: string) => void,
  onAssetItemCheck: (item: string[], itemId?: string) => void,
  onAttributeChange: (value: string) => void,
  onVariableChange: (value: string) => void,
}) {
  const soilDatasets = useSelector(selectSoilDatasets);
  const yieldDatasets = useSelector(selectYieldDatasets);
  const asAppliedDatasets = useSelector(selectAsAppliedDatasets);
  const topographyMaps = useSelector(selectTopographyMaps);
  const satelliteImages = useSelector(selectSatelliteImages);
  const satellitesLoading = useSelector(selectSatelliteLoading);
  const vectorAnalysisMaps = useSelector(selectVectorAnalysisMaps);
  const equationMaps = useSelector(selectEquationMaps);

  const [checkedLayers, setCheckedLayers] = useState(getDataVariableCheckedLayers(dataVariable));

  const selectedAssetGroupType = selectedDataset
    ? ASSET_TYPE_TO_ASSET_GROUP[selectedDataset._type]
    : undefined;

  const handleAssetItemClick = (type: string, item: TransformedAsset) => {
    if (item.uuid === selectedDataset?.uuid) {
      return;
    }

    if (!isSatelliteImage(item)) {
      setCheckedLayers({});
    }

    onAssetItemClick(item as AssignableAsset, type);
  };

  const handleCheckedLayersChange = (checked: Record<string, number>, item?: DataLayersTreeNode) => {
    const satelliteImagesGroupNode = dataLayersTree.find(({ id }) => id === AssetGroupType.satelliteImages);
    const satelliteImagesNodes = satelliteImagesGroupNode?.children ?? [];
    const checkedLayersUuids = satelliteImagesNodes.reduce<string[]>((acc, node) => {
      return node.uuid && checked[node.id] === 2 ? [...acc, node.uuid] : acc;
    }, []);

    setCheckedLayers(checked);
    onAssetItemCheck(checkedLayersUuids, item?.uuid);
  };

  const dataLayersTreeChildren = [
    <VariableNameDataLayersTreePanel
      key="top-panel"
      variable={dataVariable.variable}
      error={variableError}
      onVariableChange={onVariableChange}
    />,
  ];

  if (selectedAssetGroupType !== AssetGroupType.equationMaps) {
    dataLayersTreeChildren.push(
      <SelectAttributePanel
        key="bottom-panel"
        attributes={attributes}
        selectedAttribute={selectedAttribute}
        onAttributeChange={onAttributeChange}
      />,
    );
  }

  const { dataLayersTreeComponent, dataLayersTree } = useDataLayersTree({
    assets: {
      satelliteImages,
      soilDatasets,
      yieldDatasets,
      asAppliedDatasets,
      topographyMaps,
      vectorAnalysisMaps,
      equationMaps,
    },
    satelliteImagesLoading: satellitesLoading,
    mode: {
      [AssetGroupType.satelliteImages]: 'multiSelect',
    },
    collapsible: true,
    checked: checkedLayers,
    selectedAssetUuid: selectedDataset?.uuid,
    selectedAssetGroupType,
    hasGroupPlaceholderAction: false,
    showSatelliteRecommendations: true,
    onAssetNodeClick: handleAssetItemClick,
    onCheckedChange: handleCheckedLayersChange,
    children: dataLayersTreeChildren,
  });

  return (
    <div className="equation-variable-dataset-panel">
      {dataLayersTreeComponent}
    </div>
  );
}
