import { TransformedField } from '../../../features/field/types/field';
import { TransformedAsset } from '../../types';

export const getAssetByUuid = (
  uuid: string | undefined,
  field: TransformedField | null | undefined,
): TransformedAsset | null => {
  if (!uuid || !field) {
    return null;
  }

  if (field.uuid === uuid) {
    return field;
  }

  const assetArrays = [
    field.satelliteImages,
    field.vectorAnalysisMaps,
    field.soilDatasets,
    field.yieldDatasets,
    field.asAppliedDatasets,
    field.topographyMaps,
    field.threeDimensionalMaps,
    field.equationMaps,
  ];

  for (const found of assetArrays) {
    if (found) {
      const asset = found.find((a) => a.uuid === uuid);

      if (asset) {
        return asset;
      }
    }
  }

  return null;
};
