import React, {
  Fragment,
  MouseEvent,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react';
import clsx from 'clsx';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import type {
  ButtonClassKey,
  StyledComponentProps,
  IconButtonClassKey,
} from '@material-ui/core';

import Button from '../Button';
import type {
  ButtonMenuButton,
  ButtonMenuDivider,
  ButtonMenuItem,
  ButtonMenuSubheader,
} from './types';

import './index.scss';

const isButtonMenuButton = (item: ButtonMenuItem): item is ButtonMenuButton => {
  return item.type === 'button' || !item.type;
};

const isButtonMenuDivider = (item: ButtonMenuItem): item is ButtonMenuDivider => {
  return item.type === 'divider';
};

const isButtonMenuSubheader = (item: ButtonMenuItem): item is ButtonMenuSubheader => {
  return item.type === 'subheader';
};

export default function ButtonMenu<T extends ButtonMenuItem>({
  children,
  items = [],
  isIconButton = false,
  size = 'medium',
  edge = false,
  startIcon,
  classes,
  horizontalAnchor = 'right',
  disabled,
  onItemClick = () => {},
  onClick = () => {},
  ...buttonProps
}: {
  children?: ReactNode,
  items: T[],
  isIconButton?: boolean,
  size?: 'medium' | 'small',
  edge?: false | 'start' | 'end',
  startIcon?: ReactNode,
  classes?: {
    iconButton?: StyledComponentProps<IconButtonClassKey>['classes'],
    button?: StyledComponentProps<ButtonClassKey>['classes'],
    menu?: string,
    buttonMenuItem?: {
      buttonLabel?: string,
      buttonIcon?: string,
    },
  },
  horizontalAnchor?: 'right' | 'center' | 'left',
  disabled?: boolean,
  onItemClick: (item: T, e: MouseEvent) => void,
  onClick?: (e: MouseEvent) => void,
}) {
  const [anchor, setAnchor] = React.useState<Element | null>(null);
  const open = !!anchor;

  const handleItemClick = (event: MouseEvent, item: T) => {
    setAnchor(null);
    onItemClick(item, event);
  };

  const handleButtonClick = (event: MouseEvent) => {
    setAnchor(event.currentTarget);
    onClick(event);
  };

  const listHasIcons = useMemo(() => {
    return items.some((item) => isButtonMenuButton(item) && item.IconComponent);
  }, [items]);

  return (
    <Fragment>
      {
        isIconButton
          ? (
            <IconButton
              edge={edge}
              size={size}
              classes={classes?.iconButton}
              onClick={handleButtonClick}
              {...buttonProps}
            >
              {children || <MoreHorizIcon />}
            </IconButton>
          )
          : (
            <Button
              startIcon={startIcon}
              endIcon={open ? <ArrowUpIcon /> : <ArrowDownIcon />}
              onClick={handleButtonClick}
              classes={classes?.button}
              disabled={disabled}
              {...buttonProps}
            >
              {children}
            </Button>
          )
      }
      {
        open
          && (
            <Menu
              open
              anchorEl={anchor}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: horizontalAnchor,
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: horizontalAnchor,
              }}
              className={clsx('button-menu__menu', classes?.menu)}
              onClose={() => setAnchor(null)}
            >
              {
              items.map((item, index) => {
                let result: ReactElement;

                if (isButtonMenuDivider(item)) {
                  result = (
                    <Divider key={`${item.id}_${index}`} />
                  );
                } else if (isButtonMenuSubheader(item)) {
                  result = (
                    <Typography
                      key={`${item.id}_${index}`}
                      className="button-menu__subheader"
                    >
                      {item.label}
                    </Typography>
                  );
                } else {
                  const Icon = item.IconComponent;
                  const { tooltipRenderer } = item;

                  result = (
                    <MenuItem
                      {...item.props}
                      key={item.id}
                      className={clsx('button-menu__button', {
                        'button-menu__button_disabled': item.disabled,
                      })}
                      disabled={item.disabled}
                      onClick={(e) => handleItemClick(e, item)}
                    >
                      <div className={clsx('button-menu__button-content', {
                        'button-menu__button-content_disabled': item.disabled,
                      })}
                      >
                        {
                          Icon
                            && (
                              <ListItemIcon
                                classes={{
                                  root: clsx('button-menu__button-icon', classes?.buttonMenuItem?.buttonIcon),
                                }}
                              >
                                <Icon fontSize="small"/>
                              </ListItemIcon>
                            )
                        }
                        <div className="button-menu__button-content-text">
                          <Typography
                            className={clsx(
                              'button-menu__button-label',
                              { 'button-menu__button-label_icon-placeholder': listHasIcons && !Icon },
                              classes?.buttonMenuItem?.buttonLabel,
                            )}
                          >
                            {item.label}
                          </Typography>
                          <Typography className="button-menu__button-label_secondary-text">
                            {item.description}
                          </Typography>
                        </div>
                      </div>
                      {
                        tooltipRenderer
                          && (
                            <span
                              role="button"
                              tabIndex={0}
                              onClick={(e) => e.stopPropagation()}
                              onKeyDown={(e) => e.stopPropagation()}
                            >
                              {tooltipRenderer()}
                            </span>
                          )
                      }
                    </MenuItem>
                  );
                }

                return result;
              })
            }
            </Menu>
          )
      }
    </Fragment>
  );
}
