import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import Typography from '@material-ui/core/Typography';

import Button from '../../../../../../../components/Button';
import { getAuthLink } from '../../../../../../../helpers/navigation';
import { getCode, getEmail } from '../../../../helpers/functions/navigation';
import { View } from '../../../../helpers/constants/authentication';
import useDidMount from '../../../../../../../hooks/useDidMount';
import useAuthenticate from '../../../../hooks/useAuthenticate';
import { getBrandName } from '../../../../../../../helpers/functions/utils/appConfig';

import '../../../../components/common/index.scss';

const SignUpConfirmPanel = () => {
  const location = useLocation();
  const { signOut, authenticationCompleted } = useAuthenticate();

  useDidMount(() => {
    const code = getCode(location.search);
    const email = getEmail(location.search);

    signOut();
    Auth.confirmSignUp(email, code);
  });

  return (
    <div className="authentication-panel">
      <Typography
        classes={{
          root: 'authentication-panel__header',
        }}
        variant="h1"
      >
        Welcome to {getBrandName()}
      </Typography>
      <Typography
        classes={{
          root: 'authentication-panel__tip',
        }}
      >
        Your email is confirmed. Your registration is completed
        successfully. Click the button below to proceed.
      </Typography>
      <Button
        className="authentication-panel__button"
        variant="contained"
        color="primary"
        component={Link}
        disabled={!authenticationCompleted}
        to={getAuthLink(View.logIn)}
      >
        Log in
      </Button>
    </div>
  );
};

export default SignUpConfirmPanel;
