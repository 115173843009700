import React from 'react';
import i18n from 'i18next';
import OrderPlanetIcon from '@material-ui/icons/AddShoppingCart';
import SaveAltSharpIcon from '@material-ui/icons/SaveAltSharp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';

import AssignRatesIcon from '../../components/Icons/assignRates.svg';
import ExportIcon from '../../../../../components/Icons/export';
import ExportFileIcon from '../../../../../components/Icons/exportFile';
import ExportMapLayerIcon from '../../../../../components/Icons/exportMapLayer';
import ExportWorkPlanIcon from '../../../../../components/Icons/exportWorkPlan';
import ExportAdmFileIcon from '../../../../../components/Icons/exportAdmFile';
import RenameIcon from '../../../../../components/Icons/rename';
import CloneIcon from '../../../../../components/Icons/clone';
import type { ButtonMenuItem } from '../../../../../components/ButtonMenu/types';
import {
  isPlanetImageAcquired,
  isPlanetImageAvailable,
  isPlanetImageOrdered,
  isValid as isValidSatelliteImage,
} from '../../../../../helpers/functions/entities/satelliteImage';
import {
  MenuAction,
  DATASET_ASSET_TYPE_TO_DELETE_MENU_ACTION,
  DATASET_ASSET_TYPE_TO_RENAME_MENU_ACTION,
} from '../constants/dataLayersTree';
import { isInteractableStatus } from '../../../../../helpers/functions/entities/dataset';
import {
  isDataset,
  isDrawnManuallyVectorAnalysis,
  isSoilDataset,
  isTopographyMap,
  isYieldDataset,
  isZonesOperationsIntersectVectorAnalysis,
} from '../../../../../helpers/functions/entities/assets';
import { isJohnDeereExportWorkflowEnabled } from '../../../../../helpers/functions/utils/appConfig';
import { isInvalid as isEquationMapInvalid } from '../../../../../helpers/functions/entities/equationMap';
import type { TransformedSatelliteImage } from '../../../../satelliteImages/types/satelliteImage';
import type { TransformedVectorAnalysisMap } from '../../../../../helpers/types/vectorAnalysisMap';
import type {
  TransformedAsAppliedDataset,
  TransformedSoilDataset,
  TransformedTopographyMap,
  TransformedYieldDataset,
} from '../../../../../helpers/types/dataset';
import type { TransformedEquationMap } from '../../../../../helpers/types/equationMap';
import ExportToJohnDeereTooltip from '../../components/DataLayersTree/ExportToJohnDeereTooltip';
import { isAssetJDExportDisabled } from '../../../jdExport/helpers/functions/ui';
import { hasRates } from '../../../../../helpers/functions/entities/vectorAnalysisMap';
import {
  isJDExportAsWorkPlanDisabled,
  isVamapJDExportAsWorkPlanDisabled,
} from '../../../jdWorkPlan/helpers/functions/ui';

export const getSatelliteImageActions = (
  image: TransformedSatelliteImage,
  extra: {
    jdProfileIsHealth: boolean;
    jdProfileIsAuthorized: boolean;
    isSynchronizedJohnDeereField: boolean;
  },
): ButtonMenuItem[] => {
  let result: ButtonMenuItem[] = [];

  if (isPlanetImageAvailable(image)) {
    result = [
      {
        id: MenuAction.orderPlanetImage,
        label: i18n.t('field.assets.planet-image-items.order-image'),
        IconComponent: OrderPlanetIcon,
      },
    ];
  } else if (isPlanetImageOrdered(image)) {
    result = [
      {
        id: MenuAction.orderProcessingPlanetImage,
        label: i18n.t('field.assets.planet-image-items.order-processing'),
        disabled: true,
      },
    ];
  } else if (isPlanetImageAcquired(image) || isValidSatelliteImage(image)) {
    result = [
      {
        id: MenuAction.downloadSourceData,
        label: i18n.t('field.assets.zones-map-ops-items.download-source-data'),
        IconComponent: SaveAltSharpIcon,
      },
      ...(
        isJohnDeereExportWorkflowEnabled()
          ? [
            {
              id: MenuAction.exportToJDAsMapsLayers,
              label: i18n.t('field.assets.zones-map-ops-items.export-to-jd-as-maps-layers'),
              disabled: isAssetJDExportDisabled(extra),
              tooltipRenderer: () => <ExportToJohnDeereTooltip {...extra}/>,
              IconComponent: ExportMapLayerIcon,
            },
          ]
          : []
      ),
    ];
  }

  return result;
};

export const getVectorAnalysisMapActions = (
  vamap: TransformedVectorAnalysisMap,
  extra: {
    jdProfileIsHealth: boolean;
    jdProfileIsAuthorized: boolean;
    isSynchronizedJohnDeereField: boolean;
    jdWorkPlanExportAvailable: boolean;
  },
): ButtonMenuItem[] => {
  const { jdWorkPlanExportAvailable, ...restJDProfileSettings } = extra;
  const assetHasNoRatesAssigned = !hasRates(vamap);

  return [
    {
      id: MenuAction.goToMap,
      label: i18n.t('field.assets.zones-map-ops-items.go-to-map'),
      IconComponent: OpenInNewIcon,
    },
    {
      id: MenuAction.assignRates,
      label: i18n.t('field.assets.zones-map-ops-items.assign-rates'),
      IconComponent: AssignRatesIcon,
    },
    {
      id: MenuAction.exportMap,
      label: i18n.t('field.assets.zones-map-ops-items.export-map'),
      IconComponent: ExportIcon,
    },
    {
      id: MenuAction.downloadSourceData,
      label: i18n.t('field.assets.zones-map-ops-items.download-source-data'),
      IconComponent: SaveAltSharpIcon,
      disabled: isZonesOperationsIntersectVectorAnalysis(vamap)
        || isDrawnManuallyVectorAnalysis(vamap),
    },
    ...(
      isJohnDeereExportWorkflowEnabled()
        ? [
          {
            id: MenuAction.exportToJDAsFiles,
            label: i18n.t('field.assets.zones-map-ops-items.export-to-jd-as-files'),
            disabled: isAssetJDExportDisabled(restJDProfileSettings),
            tooltipRenderer: () => <ExportToJohnDeereTooltip {...restJDProfileSettings}/>,
            IconComponent: ExportFileIcon,
          },
          {
            id: MenuAction.exportToJDAsMapsLayers,
            label: i18n.t('field.assets.zones-map-ops-items.export-to-jd-as-maps-layers'),
            disabled: isAssetJDExportDisabled(restJDProfileSettings),
            tooltipRenderer: () => <ExportToJohnDeereTooltip {...restJDProfileSettings}/>,
            IconComponent: ExportMapLayerIcon,
          },
          {
            id: MenuAction.exportToJDAsWorkPlan,
            label: i18n.t('field.assets.zones-map-ops-items.export-to-jd-as-work-plan'),
            disabled: isVamapJDExportAsWorkPlanDisabled({ assetHasNoRatesAssigned, ...extra }),
            tooltipRenderer: () => (
              <ExportToJohnDeereTooltip
                {...extra}
                assetHasNoRatesAssigned={assetHasNoRatesAssigned}
              />
            ),
            IconComponent: ExportWorkPlanIcon,
          },
        ]
        : []
    ),
    {
      id: '_divider',
      type: 'divider',
    },
    {
      id: MenuAction.cloneZonesMap,
      label: i18n.t('general.controls.clone'),
      IconComponent: CloneIcon,
    },
    {
      id: MenuAction.renameZonesMap,
      label: i18n.t('general.controls.rename'),
      IconComponent: RenameIcon,
    },
    {
      id: MenuAction.deleteZonesMap,
      label: i18n.t('general.controls.delete'),
      IconComponent: DeleteIcon,
    },
  ];
};

export const getDatasetActions = (
  dataset: TransformedSoilDataset |
  TransformedYieldDataset |
  TransformedAsAppliedDataset |
  TransformedTopographyMap,
  extra: {
    jdProfileIsHealth: boolean;
    jdProfileIsAuthorized: boolean;
    isSynchronizedJohnDeereField: boolean;
  },
): ButtonMenuItem[] => {
  let result: ButtonMenuItem[] = [];

  if ((isDataset(dataset) && isInteractableStatus(dataset.status)) || isTopographyMap(dataset)) {
    result = [
      {
        id: MenuAction.downloadSourceData,
        label: isTopographyMap(dataset)
          ? i18n.t('field.assets.zones-map-ops-items.download-source-data')
          : i18n.t('field.assets.dataset-items.download-gridded-data'),
        IconComponent: SaveAltSharpIcon,
      },
      ...(isDataset(dataset)
        ? [
          {
            id: MenuAction.downloadOriginalData,
            label: i18n.t('field.assets.dataset-items.download-original-data'),
            IconComponent: SaveAltSharpIcon,
          },
        ]
        : []
      ),
      ...(
        isJohnDeereExportWorkflowEnabled()
          && (isSoilDataset(dataset) || isYieldDataset(dataset) || isTopographyMap(dataset))
          ? [
            {
              id: MenuAction.exportToJDAsMapsLayers,
              label: i18n.t('field.assets.zones-map-ops-items.export-to-jd-as-maps-layers'),
              disabled: isAssetJDExportDisabled(extra),
              tooltipRenderer: () => <ExportToJohnDeereTooltip {...extra} />,
              IconComponent: ExportMapLayerIcon,
            },
          ]
          : []
      ),
      ...(
        isJohnDeereExportWorkflowEnabled() && isYieldDataset(dataset)
          ? [
            {
              id: MenuAction.exportToJDAsYieldOperation,
              label: i18n.t('field.assets.dataset-items.export-to-jd-as-yield-operation'),
              disabled: isAssetJDExportDisabled(extra),
              tooltipRenderer: () => (
                <ExportToJohnDeereTooltip
                  defaultMessage={i18n.t('general.popups.export-to-john-deere-as-adm-file.yield-operation.tooltip')}
                  {...extra}
                />
              ),
              IconComponent: ExportAdmFileIcon,
            },
          ]
          : []
      ),
      {
        id: '_divider',
        type: 'divider',
      },
      {
        id: DATASET_ASSET_TYPE_TO_RENAME_MENU_ACTION[dataset._type],
        label: i18n.t('general.controls.rename'),
        IconComponent: RenameIcon,
      },
    ];
  }

  return [
    ...result,
    {
      id: DATASET_ASSET_TYPE_TO_DELETE_MENU_ACTION[dataset._type],
      label: i18n.t('general.controls.delete'),
      IconComponent: DeleteIcon,
    },
  ];
};

export const getThreeDimensionalMapActions = () => {
  return [
    {
      id: MenuAction.rename3DMap,
      label: i18n.t('general.controls.rename'),
      IconComponent: RenameIcon,
    },
    {
      id: MenuAction.delete3DMap,
      label: i18n.t('general.controls.delete'),
      IconComponent: DeleteIcon,
    },
  ];
};

export const getEquationMapActions = (
  equationMap: TransformedEquationMap,
  extra: {
    jdProfileIsHealth: boolean;
    jdProfileIsAuthorized: boolean;
    isSynchronizedJohnDeereField: boolean;
    jdWorkPlanExportAvailable: boolean;
  },
): ButtonMenuItem[] => {
  const { jdWorkPlanExportAvailable, ...restJDProfileSettings } = extra;

  return [
    ...(
      !isEquationMapInvalid(equationMap)
        ? [
          {
            id: MenuAction.downloadEquationMap,
            label: i18n.t('field.assets.equation-map-ops-items.export-map'),
            IconComponent: ExportIcon,
          },
          ...(
            isJohnDeereExportWorkflowEnabled()
              ? [
                {
                  id: MenuAction.exportToJDAsFiles,
                  label: i18n.t('field.assets.equation-map-ops-items.export-to-jd-as-files'),
                  disabled: isAssetJDExportDisabled(restJDProfileSettings),
                  tooltipRenderer: () => <ExportToJohnDeereTooltip {...restJDProfileSettings}/>,
                  IconComponent: ExportFileIcon,
                },
                {
                  id: MenuAction.exportToJDAsMapsLayers,
                  label: i18n.t('field.assets.equation-map-ops-items.export-to-jd-as-maps-layers'),
                  disabled: isAssetJDExportDisabled(restJDProfileSettings),
                  tooltipRenderer: () => <ExportToJohnDeereTooltip {...restJDProfileSettings}/>,
                  IconComponent: ExportMapLayerIcon,
                },
                {
                  id: MenuAction.exportToJDAsWorkPlan,
                  label: i18n.t('field.assets.zones-map-ops-items.export-to-jd-as-work-plan'),
                  disabled: isJDExportAsWorkPlanDisabled(extra),
                  tooltipRenderer: () => <ExportToJohnDeereTooltip {...extra}/>,
                  IconComponent: ExportWorkPlanIcon,
                },
              ]
              : []
          ),
          {
            id: '_divider',
            type: 'divider',
          },
          {
            id: MenuAction.renameEquationMap,
            label: i18n.t('general.controls.rename'),
            IconComponent: RenameIcon,
          },
        ] as ButtonMenuItem[]
        : []
    ),
    {
      id: MenuAction.deleteEquationMap,
      label: i18n.t('general.controls.delete'),
      IconComponent: DeleteIcon,
    },
  ];
};
