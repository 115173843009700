import { ProductUnit } from '../../../../../helpers/constants/units/productUnit';
import { JDProductType } from '../../../../jdProducts/helpers/constants/product';
import { AreaUnit } from '../../../../user/helpers/constants/user';
import { ApplicationProductType } from '../../types/workPlan';

enum WorkPlanUnit {
  'galAc' = 'gal1ac-1',
  'qtAc' = 'qt1ac-1',
  'ptAc' = 'pt1ac-1',
  'flozAc' = 'floz1ac-1',
  'lHa' = 'l1ha-1',
  'mlHa' = 'ml1ha-1',
  'klHa' = 'kl1ha-1',
  'ulHa' = 'ul1ha-1',
  'dlHa' = 'dl1ha-1',
  'm3Ha' = '[m3]1ha-1',
  'klAc' = 'kl1ac-1',
  'ulAc' = 'ul1ac-1',
  'mlAc' = 'ml1ac-1',
  'dlAc' = 'dl1ac-1',
  'lbAc' = 'lb1ac-1',
  'tonAc' = 'ton1ac-1',
  'ozmAc' = 'ozm1ac-1',
  'ozmFt2' = 'ozm1[ft2]-1',
  'kgHa' = 'kg1ha-1',
  'tHa' = 't1ha-1',
  'gHa' = 'g1ha-1',
  'mgHa' = 'mg1ha-1',
  'dgHa' = 'dg1ha-1',
  'dgMm2' = 'dg1[mm2]-1',
  'mgM2' = 'mg1[m2]-1',
  'gM2' = 'g1[m2]-1',
  'kgM2' = 'kg1[m2]-1',
  'kgAc' = 'kg1ac-1',
  'tAc' = 't1ac-1',
  'gAc' = 'g1ac-1',
  'mgAc' = 'mg1ac-1',
  'dgAc' = 'dg1ac-1',
  'seedsAc' = 'seeds1ac-1',
  'thndSeedsAc' = 'thndSeeds1ac-1',
  'seedsFt2' = 'seeds1[ft2]-1',
  'seedsHa' = 'seeds1ha-1',
  'thndSeedsHa' = 'thndSeeds1ha-1',
  'seedsM2' = 'seeds1[m2]-1',
  'lbFt2' = 'lb1[ft2]-1',
  'kgFt2' = 'kg1[ft2]-1',
}

enum WorkPlanUnitMeasure {
  English = 'English (Imperial)',
  Metric = 'Metric',
  Mixed = 'Mixed',
}

// VR stands for "value rate" from JD docs
type WorkPlanVrDomain = Record<WorkPlanUnitMeasure, WorkPlanUnit[]>;

const VR_APP_RATE_MASS_DOMAIN: WorkPlanVrDomain = {
  [WorkPlanUnitMeasure.English]: [
    WorkPlanUnit.lbAc,
    WorkPlanUnit.tonAc,
    WorkPlanUnit.ozmAc,
    WorkPlanUnit.ozmFt2,
  ],
  [WorkPlanUnitMeasure.Metric]: [
    WorkPlanUnit.kgHa,
    WorkPlanUnit.tHa,
    WorkPlanUnit.gHa,
    WorkPlanUnit.mgHa,
    WorkPlanUnit.dgHa,
    WorkPlanUnit.dgMm2,
    WorkPlanUnit.mgM2,
    WorkPlanUnit.gM2,
    WorkPlanUnit.kgM2,
  ],
  [WorkPlanUnitMeasure.Mixed]: [
    WorkPlanUnit.lbAc,
    WorkPlanUnit.kgAc,
    WorkPlanUnit.tonAc,
    WorkPlanUnit.ozmAc,
    WorkPlanUnit.tAc,
    WorkPlanUnit.gAc,
    WorkPlanUnit.mgAc,
    WorkPlanUnit.dgAc,
  ],
};

const VR_APP_RATE_VOLUME_DOMAIN: WorkPlanVrDomain = {
  [WorkPlanUnitMeasure.English]: [
    WorkPlanUnit.galAc,
    WorkPlanUnit.qtAc,
    WorkPlanUnit.ptAc,
    WorkPlanUnit.flozAc,
  ],
  [WorkPlanUnitMeasure.Metric]: [
    WorkPlanUnit.lHa,
    WorkPlanUnit.mlHa,
    WorkPlanUnit.klHa,
    WorkPlanUnit.ulHa,
    WorkPlanUnit.dlHa,
    WorkPlanUnit.m3Ha,
  ],
  [WorkPlanUnitMeasure.Mixed]: [
    WorkPlanUnit.galAc,
    WorkPlanUnit.klAc,
    WorkPlanUnit.qtAc,
    WorkPlanUnit.ptAc,
    WorkPlanUnit.flozAc,
    WorkPlanUnit.ulAc,
    WorkPlanUnit.mlAc,
    WorkPlanUnit.lbAc,
    WorkPlanUnit.dlAc,
  ],
};

const VR_SOLUTION_RATE_LIQUID_DOMAIN: WorkPlanVrDomain = {
  [WorkPlanUnitMeasure.English]: [
    WorkPlanUnit.galAc,
    WorkPlanUnit.qtAc,
    WorkPlanUnit.ptAc,
    WorkPlanUnit.flozAc,
  ],
  [WorkPlanUnitMeasure.Metric]: [
    WorkPlanUnit.lHa,
    WorkPlanUnit.mlHa,
    WorkPlanUnit.klHa,
    WorkPlanUnit.ulHa,
    WorkPlanUnit.dlHa,
    WorkPlanUnit.m3Ha,
  ],
  [WorkPlanUnitMeasure.Mixed]: [
    WorkPlanUnit.galAc,
    WorkPlanUnit.klAc,
    WorkPlanUnit.qtAc,
    WorkPlanUnit.ptAc,
    WorkPlanUnit.flozAc,
    WorkPlanUnit.ulAc,
    WorkPlanUnit.mlAc,
    WorkPlanUnit.lbAc,
    WorkPlanUnit.dlAc,
  ],
};

const VR_SEED_RATE_MASS_DOMAIN: WorkPlanVrDomain = {
  [WorkPlanUnitMeasure.English]: [
    WorkPlanUnit.lbAc,
    WorkPlanUnit.tonAc,
    WorkPlanUnit.lbFt2,
  ],
  [WorkPlanUnitMeasure.Metric]: [
    WorkPlanUnit.kgHa,
    WorkPlanUnit.tHa,
    WorkPlanUnit.kgM2,
  ],
  [WorkPlanUnitMeasure.Mixed]: [
    WorkPlanUnit.lbAc,
    WorkPlanUnit.kgFt2,
    WorkPlanUnit.kgAc,
  ],
};

const VR_SEED_RATE_SEEDS_DOMAIN: WorkPlanVrDomain = {
  [WorkPlanUnitMeasure.English]: [
    WorkPlanUnit.seedsAc,
    WorkPlanUnit.thndSeedsAc,
    WorkPlanUnit.seedsFt2,
  ],
  [WorkPlanUnitMeasure.Metric]: [
    WorkPlanUnit.seedsHa,
    WorkPlanUnit.thndSeedsHa,
    WorkPlanUnit.seedsM2,
  ],
  [WorkPlanUnitMeasure.Mixed]: [
    WorkPlanUnit.seedsAc,
    WorkPlanUnit.seedsFt2,
  ],
};

const getAllDomainsUnits = (domains: WorkPlanVrDomain[]): WorkPlanUnit[] => {
  const allUnits = domains.flatMap((domain) => {
    return Object.values(domain).flat();
  });

  return [...new Set(allUnits)].sort();
};

const APPLICATION_OPERATION_TYPE_UNITS = getAllDomainsUnits([
  VR_APP_RATE_MASS_DOMAIN,
  VR_APP_RATE_VOLUME_DOMAIN,
  VR_SOLUTION_RATE_LIQUID_DOMAIN,
]);

const SEEDING_OPERATION_TYPE_UNITS = getAllDomainsUnits([
  VR_SEED_RATE_MASS_DOMAIN,
  VR_SEED_RATE_SEEDS_DOMAIN,
]);

// Update CHEMICAL_UNITS, FERTILIZER_UNITS, TANK_MIX_UNITS, DRY_BLEND_UNITS, VARIETY_UNITS when JD provide API solution
const CHEMICAL_UNITS = {
  [AreaUnit.acres]: APPLICATION_OPERATION_TYPE_UNITS,
  [AreaUnit.hectares]: APPLICATION_OPERATION_TYPE_UNITS,
};

const FERTILIZER_UNITS = {
  [AreaUnit.acres]: APPLICATION_OPERATION_TYPE_UNITS,
  [AreaUnit.hectares]: APPLICATION_OPERATION_TYPE_UNITS,
};

const TANK_MIX_UNITS = {
  [AreaUnit.acres]: APPLICATION_OPERATION_TYPE_UNITS,
  [AreaUnit.hectares]: APPLICATION_OPERATION_TYPE_UNITS,
};

const DRY_BLEND_UNITS = {
  [AreaUnit.acres]: APPLICATION_OPERATION_TYPE_UNITS,
  [AreaUnit.hectares]: APPLICATION_OPERATION_TYPE_UNITS,
};

export const APPLICATION_PRODUCT_TYPE_UNITS_MAPPER: Record<
ApplicationProductType,
Partial<Record<AreaUnit, WorkPlanUnit[]>>
> = {
  [JDProductType.chemical]: CHEMICAL_UNITS,
  [JDProductType.fertilizer]: FERTILIZER_UNITS,
  [JDProductType.tankMix]: TANK_MIX_UNITS,
  [JDProductType.dryBlend]: DRY_BLEND_UNITS,
};

export const VARIETY_UNITS: Partial<Record<AreaUnit, WorkPlanUnit[]>> = {
  [AreaUnit.acres]: SEEDING_OPERATION_TYPE_UNITS,
  [AreaUnit.hectares]: SEEDING_OPERATION_TYPE_UNITS,
};

export const PRODUCT_UNIT_TO_WORK_PLAN_UNIT: Partial<Record<ProductUnit, WorkPlanUnit>> = {
  [ProductUnit.kgHa]: WorkPlanUnit.kgHa,
  [ProductUnit.kgAc]: WorkPlanUnit.kgAc,
  [ProductUnit.kgM2]: WorkPlanUnit.kgM2,
  [ProductUnit.lbAc]: WorkPlanUnit.lbAc,
  [ProductUnit.lHa]: WorkPlanUnit.lHa,
  [ProductUnit.galAc]: WorkPlanUnit.galAc,
  [ProductUnit.seedsHa]: WorkPlanUnit.seedsHa,
  [ProductUnit.seedsAc]: WorkPlanUnit.seedsAc,
  [ProductUnit.seedsM2]: WorkPlanUnit.seedsM2,
  [ProductUnit.tHa]: WorkPlanUnit.tHa,
};
