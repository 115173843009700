import React from 'react';
import { useTranslation } from 'react-i18next';

import Select from '../../../../../../../components/Select';
import { AssetType } from '../../../../../../../helpers/constants/entities/asset';

import './index.scss';

export default function CreateAnalysisDataLayerTypeSelector({
  selectedType,
  dataLayersOptions,
  onDataLayerTypeChange,
}: {
  selectedType: AssetType,
  dataLayersOptions: { value: AssetType; title: string }[],
  onDataLayerTypeChange: (value: AssetType | null) => void,
}) {
  const { t } = useTranslation();

  return (
    <Select
      id="create-analysis-data-layer-type-selector"
      classes={{
        root: 'create-analysis-data-layer-type-selector',
        selectInput: 'create-analysis-data-layer-type-selector__input',
      }}
      labelPosition="left"
      label={`${t('zones-ops.multi-layer.steps.3.data-layer-type')}:`}
      value={selectedType}
      options={dataLayersOptions}
      onChange={onDataLayerTypeChange}
    />
  );
}
