import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  getPlanSelectAppUrl,
} from '../../../../../helpers/functions/utils/vendorConfigs/pricing';

import './index.scss';

export default function PlanSelectIframe() {
  const { t } = useTranslation();
  const location = useLocation();
  const planSelectAppUrl = `${getPlanSelectAppUrl()}${location.search}`;

  return (
    <div className="plan-select-iframe-container">
      <iframe
        className="plan-select-iframe"
        title={t('general.navigation.pricing')}
        src={planSelectAppUrl}
      />
    </div>
  );
}
