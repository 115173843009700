import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AuthenticationRedirector from '../router/redirectors/Authentication';
import UserDataRedirector from '../router/redirectors/UserData';
import PricingRedirector from '../router/redirectors/Pricing';
import Panel from '../features/ui/userOrganizations/containers/Panel';
import { getDocumentTitle } from '../helpers';
import useDidMount from '../hooks/useDidMount';
import NoAccessPanel from '../features/ui/applicationShell/containers/NoAccessPanel';
import { selectIsUserOrganizationsAccessible } from '../features/user/userSelectors';
import BaseTemplate from './BaseTemplate.jsx';

const UserOrganization = () => {
  const { t } = useTranslation();
  const isPageAvailable = useSelector(selectIsUserOrganizationsAccessible);

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.organizations'));
  });

  let content = [<Panel key="panel" />];

  if (!isPageAvailable) {
    content = [
      <NoAccessPanel
        key="panel"
        description="restricted-access.no-access.page"
      />,
    ];
  }

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <PricingRedirector>
          <BaseTemplate
            headerProps={{
              text: t('user-organizations.title'),
            }}
          >
            {content}
          </BaseTemplate>
        </PricingRedirector>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
};

export default UserOrganization;
