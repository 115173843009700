import { AssetType } from '../../../../../helpers/constants/entities/asset';
import { FieldDatasetEntity } from '../../../../assets/types/state';
import { getDataLayerUseInterpolatedDataValue } from '../../../../createAnalysis/helpers/functions/settings';
import {
  AssetVariableData,
  AssignedVariables,
} from '../../types/variables';

const populateVariableWithDatasetGeometryType = ({
  variable,
  datasets,
}: {
  variable: AssetVariableData,
  datasets?: FieldDatasetEntity[],
}) => {
  const { type } = variable;

  if (type === AssetType.yieldDataset || type === AssetType.asAppliedDataset) {
    const variableDataset = datasets?.find((dataset) => dataset.uuid === variable.uuid);

    return {
      ...variable,
      geometryType: variableDataset && 'geometryType' in variableDataset
        ? variableDataset.geometryType
        : null,
    };
  }

  return variable;
};

export const getVariableUseInterpolatedDataValue = ({
  variable,
  datasets,
}: {
  variable: AssetVariableData,
  datasets?: FieldDatasetEntity[],
}) => {
  const variableWithGeometryType = populateVariableWithDatasetGeometryType({
    variable,
    datasets,
  });

  return getDataLayerUseInterpolatedDataValue(variableWithGeometryType);
};

export const populateFieldsVariablesUseInterpolatedDataValue = ({
  fieldsAssignedVariables,
  fieldsDatasets,
}: {
  fieldsAssignedVariables: Record<string, AssignedVariables>;
  fieldsDatasets: Record<string, FieldDatasetEntity[]>;
}) => {
  return Object.entries(fieldsAssignedVariables)
    .reduce<Record<string, AssignedVariables>>((acc, [fieldUuid, fieldAssignedVariables]) => {
    const assignedVariables = Object.entries(fieldAssignedVariables)
      .reduce<AssignedVariables>((fieldVariables, [variableName, variableData]) => {
      if (!variableData) {
        return fieldVariables;
      }

      return {
        ...fieldVariables,
        [variableName]: {
          ...variableData,
          useInterpolatedData: getVariableUseInterpolatedDataValue({
            variable: variableData,
            datasets: fieldsDatasets[fieldUuid],
          }),
        },
      };
    }, {});

    return {
      ...acc,
      [fieldUuid]: assignedVariables,
    };
  }, {});
};
