import React, {
  Suspense,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';

import Loading from '../../../../../../../components/Loading';
import Stepper from '../../../../../../../components/Stepper';
import Panel from '../../../Panel/DataLayers';
import DataLayersPreviews from '../../../DataLayersPreviews';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import { goToPrevStep } from '../../../../../zonesOps/zonesOpsSlice';
import { navigateToSettings } from '../../../../createMultiLayerAnalysisSlice';
import Message from '../../../../../../../components/Messages/Message';
import {
  selectCreateAnalysisAssets,
  selectIsAllAssetsLoaded,
} from '../../../../../../field/fieldSelectors';
import {
  selectDataLayers,
  selectIsValidSetup,
} from '../../../../createMultiLayerAnalysisSelectors';
import { fetchAllAssets } from '../../../../../../field/fieldSlice';
import { selectSelectedField } from '../../../../../zonesOps/zonesOpsSelectors';
import useDidMount from '../../../../../../../hooks/useDidMount';
import { AssetType } from '../../../../../../../helpers/constants/entities/asset';
import DataLayersTabs from '../../../DataLayersTabs';

import './index.scss';

const Map = React.lazy(() => import('../../../Map'));

const StepperDataLayers = ({
  steps,
  stepIndex,
  step,
}) => {
  const dispatch = useDispatch();

  const {
    farmUuid,
    fieldUuid,
  } = useSelector(selectSelectedField);
  const dataLayers = useSelector(selectDataLayers);
  const isValidSetup = useSelector(selectIsValidSetup);
  const isFieldLoaded = useSelector(selectIsAllAssetsLoaded);
  const assets = useSelector(selectCreateAnalysisAssets);
  const dataLayersAvailable = Object.values(assets).some((asset) => asset.length);

  const requestDataLayers = () => {
    let requiredSatImages;
    const satelliteImagesLayer = dataLayers.find((layer) => {
      return layer.type === AssetType.satelliteImage;
    });

    if (satelliteImagesLayer) {
      requiredSatImages = satelliteImagesLayer.satelliteImageUuids;
    }

    dispatch(fetchAllAssets({
      farmUuid,
      fieldUuid,
      requiredSatImages,
    }));
  };

  const onClickNext = () => {
    dispatch(navigateToSettings());
  };

  const onClickBack = () => {
    dispatch(goToPrevStep());
  };

  useDidMount(requestDataLayers);

  let content;

  if (isFieldLoaded) {
    if (dataLayersAvailable) {
      content = [
        <DataLayersTabs key="tabs"/>,
        <Panel key="panel" step={step} />,
        <Suspense key="map" fallback={<Loading />}>
          <Map step={step} />
        </Suspense>,
        <DataLayersPreviews key="previews" />,
      ];
    } else {
      content = (
        <Message key="panel" messageTextLocation="zones-ops.common.no-assets-message" />
      );
    }
  } else {
    content = (
      <BackdropLoading key="loader" />
    );
  }

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      classes={{
        map: 'data-layers__map',
      }}
      nextDisabled={!isValidSetup}
      onClickBack={onClickBack}
      onClickNext={onClickNext}
    >
      { content }
    </Stepper>
  );
};

export default StepperDataLayers;
