import React from 'react';

import { DataLayersListNode } from '../../../../types/dataLayersList';
import SimpleItem from '../../../ListView/SimpleItem';
import { TransformedVectorAnalysisMap } from '../../../../../../../helpers/types/vectorAnalysisMap';
import ZonesMapItem from '../../../Items/ZonesMapItem';

export default function ZonesMapNode({
  listNode,
  zonesMap,
  selectedItemUuid,
  onClick,
}: {
  listNode: DataLayersListNode;
  zonesMap: TransformedVectorAnalysisMap;
  selectedItemUuid: string;
  onClick: () => void;
}) {
  const isItemSelected = listNode.uuid === selectedItemUuid;

  return (
    <SimpleItem isSelected={isItemSelected} onClick={onClick}>
      <ZonesMapItem zonesMap={zonesMap} />
    </SimpleItem>
  );
}
