export const YIELD_PATTERNS = [
  'yield',
  'yld',
  'DRYMATTER',
  'Mass_Yield',
  'Dry_Yield',
  'Wet_Yield',
  'WetMass',
];

export const MACHINE_ID_PATTERNS = [
  'MachineId',
  'Machine',
  'Pass_Num',
  'Region',
];

export const DELAY_PATTERNS = [
  'Duration',
  'Crop_Flw_M',
  'Duration_s',
  'Distance_f',
];

export const VELOCITY_PATTERNS = [
  'Speed',
  'VEHICLSPEE',
  'Speed_mph_',
];

export const SWATH_WIDTH_PATTERNS = [
  'Width',
  'SWATHWIDTH',
  'Swth_Wdth_',
];
