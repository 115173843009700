import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AuthenticationRedirector from '../router/redirectors/Authentication/index';
import UserDataRedirector from '../router/redirectors/UserData';
import BackdropLoading from '../components/Loading/BackdropLoading/index';
import Panel from '../features/ui/userActivityLog/containers/Panel/index';
import NoAccessPanel from '../features/ui/applicationShell/containers/NoAccessPanel/index';
import { getDocumentTitle } from '../helpers/index';
import useDidMount from '../hooks/useDidMount';
import { selectIsLoaded } from '../features/user/userSelectors';
import BaseTemplate from './BaseTemplate.jsx';
import { reset } from '../features/ui/userActivityLog/userActivityLogSlice';
import useSubscriptionAvailable from '../hooks/useSubscriptionAvailable';

export default function ActivityLog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isUserDataLoaded = useSelector(selectIsLoaded);
  const isPageAvailable = useSubscriptionAvailable();

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.activity-log'));

    return () => {
      dispatch(reset());
    };
  });

  let content = [
    <BackdropLoading key="loading" />,
  ];

  if (!isPageAvailable) {
    content = [
      <NoAccessPanel
        key="panel"
        description="restricted-access.no-access.page"
      />,
    ];
  } else if (isUserDataLoaded) {
    content = [
      <Panel key="panel" />,
    ];
  }

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <BaseTemplate
          headerProps={{
            text: t('general.navigation.activity-log'),
          }}
        >
          {content}
        </BaseTemplate>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
}
