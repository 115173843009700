import React from 'react';
import { useTranslation } from 'react-i18next';

import BaseTemplate from './BaseTemplate.jsx';
import PlanSelectIframe from '../features/ui/pricing/components/PlanSelectIframe';
import AuthenticationRedirector from '../router/redirectors/Authentication';
import UserDataRedirector from '../router/redirectors/UserData';
import useDidMount from '../hooks/useDidMount';
import { getDocumentTitle } from '../helpers';

export default function Pricing() {
  const { t } = useTranslation();

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.pricing'));
  });

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <BaseTemplate headerProps={{ text: t('general.navigation.pricing') }}>
          <PlanSelectIframe key="panel" />
        </BaseTemplate>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
}
