import React from 'react';
import {
  endOfDay,
  isAfter,
  isBefore,
  startOfDay,
} from 'date-fns';

import { AssetGroupType } from '../../../../../helpers/constants/entities/asset';
import { isDateValid } from '../../../../../helpers/functions/utils/date';
import {
  DEFAULT_PINS_GROUPS_FILTER,
  PinsGroupsFilterKey,
} from '../constants/pinsGroups';
import {
  DEFAULT_SAT_IMAGES_FILTER,
  SatelliteFilterKey,
} from '../constants/satelliteImage';
import {
  DEFAULT_VAMAPS_FILTER,
  VectorAnalysisFilterKey,
} from '../constants/analysis';
import { SatelliteFilters } from '../../types/satelliteImage';
import DefaultAssetGroupFilter from '../../components/DefaultAssetGroupFilter';
import ZonesMapFilter from '../../components/ZonesMapFilter';
import SatelliteFilter from '../../components/SatelliteFilter';
import { getVamapTypeFilterOptions } from './analysis';
import type { PinsGroupsFilters } from '../../types/pinsGroups';
import type { VectorAnalysisFilters } from '../../types/analysis';
import type {
  AssetGroupsFilters,
  DefaultAssetGroupFilters,
} from '../../types/assetGroup';
import type { TransformedSatelliteImage } from '../../../../satelliteImages/types/satelliteImage';
import type { PinsGroup } from '../../../../pins/types';
import type { TransformedVectorAnalysisMap } from '../../../../../helpers/types/vectorAnalysisMap';
import { getPinsGroupsTypeFilterOptions } from './pinsGroups';
import {
  DEFAULT_ASSET_GROUP_FILTER,
  DefaultAssetGroupFilterKey,
} from '../constants/assetGroup';

/**
 * groups consequent items for filter display and transforms to string
 *
 * @param {Object[]} items
 * @param {string} items[].title - name of the item, for result string construction
 * @param {string} items[].value - value of the item, for comparison
 */

export const getDefaultAssetGroupFilterValue = (type: AssetGroupType) => {
  switch (type) {
    case AssetGroupType.satelliteImages:
      return DEFAULT_SAT_IMAGES_FILTER;
    case AssetGroupType.vectorAnalysisMaps:
      return DEFAULT_VAMAPS_FILTER;
    case AssetGroupType.pinsGroups:
      return DEFAULT_PINS_GROUPS_FILTER;
    default:
      return DEFAULT_ASSET_GROUP_FILTER;
  }
};

export const getDefaultFilterValue = ({
  [AssetGroupType.satelliteImages]: satelliteFilterKey,
  [AssetGroupType.vectorAnalysisMaps]: vectorAnalysisFilterKey,
  [AssetGroupType.pinsGroups]: pinsGroupsFilterKey,
  [AssetGroupType.equationMaps]: equationMapsFilterKey,
  [AssetGroupType.soilDatasets]: soilDatasetsFilterKey,
  [AssetGroupType.yieldDatasets]: yieldDatasetsFilterKey,
  [AssetGroupType.asAppliedDatasets]: asAppliedDatasetsFilterKey,
  [AssetGroupType.topographyMaps]: topographyMapsFilterKey,
  [AssetGroupType.threeDimensionalMaps]: threeDimensionalMapsFilterKey,
}: {
  [AssetGroupType.satelliteImages]?: SatelliteFilterKey,
  [AssetGroupType.vectorAnalysisMaps]?: VectorAnalysisFilterKey,
  [AssetGroupType.pinsGroups]?: PinsGroupsFilterKey,
  [AssetGroupType.equationMaps]?: DefaultAssetGroupFilterKey,
  [AssetGroupType.soilDatasets]?: DefaultAssetGroupFilterKey,
  [AssetGroupType.yieldDatasets]?: DefaultAssetGroupFilterKey,
  [AssetGroupType.asAppliedDatasets]?: DefaultAssetGroupFilterKey,
  [AssetGroupType.topographyMaps]?: DefaultAssetGroupFilterKey,
  [AssetGroupType.threeDimensionalMaps]?: DefaultAssetGroupFilterKey,
}) => {
  let defaultFilterKey = null;

  if (equationMapsFilterKey) {
    defaultFilterKey = equationMapsFilterKey;
  } else if (soilDatasetsFilterKey) {
    defaultFilterKey = soilDatasetsFilterKey;
  } else if (yieldDatasetsFilterKey) {
    defaultFilterKey = yieldDatasetsFilterKey;
  } else if (asAppliedDatasetsFilterKey) {
    defaultFilterKey = asAppliedDatasetsFilterKey;
  } else if (topographyMapsFilterKey) {
    defaultFilterKey = topographyMapsFilterKey;
  } else if (threeDimensionalMapsFilterKey) {
    defaultFilterKey = threeDimensionalMapsFilterKey;
  }

  if (satelliteFilterKey) {
    return DEFAULT_SAT_IMAGES_FILTER[satelliteFilterKey];
  } if (vectorAnalysisFilterKey) {
    return DEFAULT_VAMAPS_FILTER[vectorAnalysisFilterKey];
  } if (pinsGroupsFilterKey) {
    return DEFAULT_PINS_GROUPS_FILTER[pinsGroupsFilterKey];
  } if (defaultFilterKey) {
    return DEFAULT_ASSET_GROUP_FILTER[defaultFilterKey];
  }

  return null;
};

export const assetGroupFilterRenderer = ({
  type,
  filtersValue,
  assets,
  classes = {},
  onFiltersUpdate,
  onFiltersClear,
}: {
  type: AssetGroupType,
  filtersValue: AssetGroupsFilters;
  assets?: {
    satelliteImages?: TransformedSatelliteImage[],
    vectorAnalysisMaps?: TransformedVectorAnalysisMap[],
    pinsGroups?: PinsGroup[],
  };
  classes?: Partial<Record<AssetGroupType, Record<string, string>>>,
  onFiltersUpdate: (value: Partial<AssetGroupsFilters>) => void;
  onFiltersClear?: () => void;
}) => {
  let result = null;

  if (type === AssetGroupType.satelliteImages) {
    const satelliteFilter = filtersValue[type];

    result = (
      <SatelliteFilter
        classes={classes.satelliteImages}
        value={satelliteFilter}
        images={assets?.satelliteImages}
        onChange={(v: SatelliteFilters) => {
          onFiltersUpdate({ [type]: v });
        }}
        onFiltersClear={onFiltersClear}
      />
    );
  } else if (type === AssetGroupType.vectorAnalysisMaps) {
    const vectorAnalysisMapsFilter = filtersValue[type];
    const options = getVamapTypeFilterOptions(
      assets?.vectorAnalysisMaps,
      DEFAULT_VAMAPS_FILTER.typeFilterValue,
    );

    result = (
      <ZonesMapFilter
        classes={classes.vectorAnalysisMaps}
        value={vectorAnalysisMapsFilter}
        typeOptions={options}
        onChange={(v: VectorAnalysisFilters) => {
          onFiltersUpdate({ [type]: v });
        }}
        onFiltersClear={onFiltersClear}
      />
    );
  } else if (type === AssetGroupType.pinsGroups) {
    const pinsGroupsFilter = filtersValue[type];
    const options = getPinsGroupsTypeFilterOptions(
      assets?.pinsGroups,
      DEFAULT_PINS_GROUPS_FILTER.typeFilterValue,
    );

    result = (
      <ZonesMapFilter
        classes={classes.pinsGroups}
        value={pinsGroupsFilter}
        typeOptions={options}
        onChange={(v: PinsGroupsFilters) => {
          onFiltersUpdate({ [type]: v });
        }}
        onFiltersClear={onFiltersClear}
      />
    );
  } else {
    const filter = filtersValue[type];

    result = (
      <DefaultAssetGroupFilter
        classes={classes.equationMaps}
        value={filter}
        onChange={(v: DefaultAssetGroupFilters) => {
          onFiltersUpdate({ [type]: v });
        }}
        onFiltersClear={onFiltersClear}
      />
    );
  }

  return result;
};

export const isDateMatch = (dateString: string, dateFrom: Date | null, dateTo: Date | null): boolean => {
  const date = new Date(dateString);

  const dateFromMatched = !isDateValid(dateFrom) || isAfter(date, startOfDay(dateFrom));
  const dateToMatched = !isDateValid(dateTo) || isBefore(date, endOfDay(dateTo));

  return dateFromMatched && dateToMatched;
};
