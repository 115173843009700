import { getPatternsMatch } from '../utils/fuzzySearch';
import { FullAttribute } from '../../types/dataset';
import { TransformedAsset } from '../../types';
import { isYieldDataset } from './assets';

const YIELD_ATTRIBUTES_PATTERNS = [
  // 1 priority
  'WetMass',
  'Yld_Mass_D',
  'Dry_Yield',
  // 2 priority
  'VRYIELDVOL',
  'Yld_Mass_W',
  'Wet_Yield',
  // 3 priority
  'DRYMATTER',
  'Yld_Vol_Dr',
  'Mass_Yield',
  // 4 priority
  'Yld_Vol_We',
];

export const findYieldAttribute = (fullAttributes: FullAttribute[]): FullAttribute | undefined => {
  const results = getPatternsMatch(
    fullAttributes,
    YIELD_ATTRIBUTES_PATTERNS,
    {
      keys: ['fullName', 'transliteratedName'],
    },
  );

  return [...results][0]?.item;
};

/**
 * Determines the attribute to be preselected on the view based on the asset type.
 * For yield datasets, attempts to find a yield-specific attribute.
 * For other asset types with attributes, defaults to the first available attribute.
 *
 * @returns The transliterated name of the attribute, or an empty string
 */
export const findAttributeToPreselect = (asset: TransformedAsset | null): string => {
  let attribute;

  if (isYieldDataset(asset)) {
    const fullAttributes = asset?.fullAttributes || [];
    attribute = findYieldAttribute(fullAttributes)?.transliteratedName ?? '';
  } else {
    const attributes = asset && 'fullAttributes' in asset ? asset.fullAttributes : [];
    attribute = attributes?.[0]?.transliteratedName ?? '';
  }

  return attribute;
};
