import { AssetType } from '../../../../helpers/constants/entities/asset';
import { GeometryType } from '../../../../helpers/constants/entities/dataset';

export const getDataLayerUseInterpolatedDataValue = ({
  type,
  geometryType,
}: {
  type?: AssetType | null;
  geometryType?: GeometryType | null;
}) => {
  if (type === AssetType.satelliteImage || type === AssetType.soilDataset) {
    return true;
  }

  const isYieldOrAsAppliedDataset = type === AssetType.yieldDataset || type === AssetType.asAppliedDataset;

  if (
    isYieldOrAsAppliedDataset
    && (geometryType === GeometryType.Points
      || geometryType === GeometryType.MultiPoints)
  ) {
    return true;
  }

  return false;
};

export const getVamapUseInterpolatedDataValue = (
  dataLayers: {
    type?: AssetType | null;
    geometryType?: GeometryType | null;
  }[] = [],
) => {
  return dataLayers.some((dataLayer) => getDataLayerUseInterpolatedDataValue(dataLayer));
};
