import i18n from 'i18next';

import { AssetGroupFilterKey, DefaultAssetGroupFilters } from '../../types/assetGroup';
import { SatelliteFilters } from '../../types/satelliteImage';
import { DEFAULT_ASSET_GROUP_FILTER_KEYS_I18N_MAP, DefaultAssetGroupFilterKey } from '../constants/assetGroup';
import { getCloudFilterOptions } from './satelliteImage';
import { comparator } from '../../../../../helpers/functions/utils/string';
import {
  ALL_IMAGES_CLOUD_FILTER_VALUE,
  ALL_IMAGES_NDVI_FILTER_VALUE,
  SATELLITE_FILTER_KEYS_I18N_MAP,
  SatelliteFilterKey,
} from '../constants/satelliteImage';
import {
  DEFAULT_VAMAPS_FILTER,
  VAMAP_FILTER_KEYS_I18N_MAP,
  VectorAnalysisFilterKey,
} from '../constants/analysis';
import { TYPE_I18N_KEY_LABEL_MAP } from '../../../../../helpers/analysis';
import { VectorAnalysisFilters } from '../../types/analysis';
import { AnalysisMapType } from '../../../../../helpers/constants/entities/vectorAnalysisMap';
import {
  DEFAULT_PINS_GROUPS_FILTER,
  PINS_GROUPS_FILTER_KEYS_I18N_MAP,
  PinsGroupsFilterKey,
} from '../constants/pinsGroups';
import type { PinsGroupsFilters } from '../../types/pinsGroups';
import {
  ASSET_TYPE_TO_I18N_KEY,
  AssetGroupType,
  AssetType,
} from '../../../../../helpers/constants/entities/asset';
import { formatDate } from '../../../../../helpers/functions/utils/date';

type FilterSummary = {
  label: string;
  value: string;
  key: AssetGroupFilterKey;
};

const groupItems = (items: { title: string; value: number }[]) => {
  const sortedItems = [...(items || [])].sort((aItem, bItem) => comparator(String(aItem.value), String(bItem.value)));
  const groups = sortedItems.reduce((acc, curr) => {
    const lastGroup = acc[acc.length - 1];

    if (!lastGroup) {
      acc.push([curr]);
    } else if (lastGroup.length === 2) {
      const lastItem = lastGroup[1];

      if (lastItem.value + 1 === curr.value) {
        lastGroup[1] = curr;
      } else {
        acc.push([curr]);
      }
    } else {
      const item = lastGroup[0];

      if (item.value + 1 === curr.value) {
        lastGroup.push(curr);
      } else {
        acc.push([curr]);
      }
    }

    return acc;
  }, [] as { title: string; value: number }[][]);

  return groups.map((element) => {
    if (element.length === 2) {
      return `${element[0].title}-${element[1].title}`;
    }

    return element[0].title;
  });
};

const generateSatelliteFiltersSummary = (satelliteFilter: SatelliteFilters) => {
  return Object.values(SatelliteFilterKey)
    .map((key) => {
      switch (key) {
        case SatelliteFilterKey.cloudFilterValue: {
          const options = getCloudFilterOptions();
          const selectedOption = options.find((option) => option.value === satelliteFilter[key]);

          if (!selectedOption || selectedOption.value === ALL_IMAGES_CLOUD_FILTER_VALUE) {
            return null;
          }

          return {
            label: i18n.t(SATELLITE_FILTER_KEYS_I18N_MAP[SatelliteFilterKey.cloudFilterValue]),
            value: selectedOption.title,
            key,
          };
        }
        case SatelliteFilterKey.monthFilterValue:
        case SatelliteFilterKey.yearFilterValue: {
          const value = satelliteFilter[key];

          if (!value?.length) {
            return null;
          }

          return {
            label: i18n.t(SATELLITE_FILTER_KEYS_I18N_MAP[key]),
            value: groupItems(value).join(', '),
            key,
          };
        }
        case SatelliteFilterKey.providerFilterValue: {
          const value = satelliteFilter[key];

          if (!value?.length) {
            return null;
          }

          return {
            label: i18n.t(SATELLITE_FILTER_KEYS_I18N_MAP[key]),
            value: value.map(({ title }) => title).join(', '),
            key,
          };
        }
        case SatelliteFilterKey.ndviFilterValue: {
          const value = satelliteFilter[key];
          const [min, max] = ALL_IMAGES_NDVI_FILTER_VALUE;

          if (!value || (value[0] === min && value[1] === max)) {
            return null;
          }

          return {
            label: i18n.t(SATELLITE_FILTER_KEYS_I18N_MAP[key]),
            value: i18n.t('general.controls.satellite-filters.from-to', {
              from: value[0],
              to: value[1],
            }),
            key,
          };
        }
        default:
          return null;
      }
    });
};

const generateVectorAnalysisFiltersSummary = (vectorAnalysisMapsFilter: VectorAnalysisFilters) => {
  return Object.values(VectorAnalysisFilterKey)
    .map((key) => {
      switch (key) {
        case VectorAnalysisFilterKey.typeFilterValue: {
          const value = vectorAnalysisMapsFilter[key] as AnalysisMapType;

          if (value === DEFAULT_VAMAPS_FILTER[key]) {
            return null;
          }

          return {
            label: i18n.t(VAMAP_FILTER_KEYS_I18N_MAP[key]),
            value: i18n.t(TYPE_I18N_KEY_LABEL_MAP[value]),
            key,
          };
        }
        case VectorAnalysisFilterKey.nameFilterValue: {
          const name = vectorAnalysisMapsFilter[key];

          if (!name) {
            return null;
          }

          return {
            label: i18n.t(VAMAP_FILTER_KEYS_I18N_MAP[key]),
            value: name,
            key,
          };
        }
        default:
          return null;
      }
    });
};

const generatePinsGroupsFiltersSummary = (pinsGroupsFilter: PinsGroupsFilters) => {
  return Object.values(PinsGroupsFilterKey)
    .map((key) => {
      switch (key) {
        case PinsGroupsFilterKey.typeFilterValue: {
          const value = pinsGroupsFilter[key] as AssetType;

          if (value === DEFAULT_PINS_GROUPS_FILTER[key]) {
            return null;
          }

          return {
            label: i18n.t(PINS_GROUPS_FILTER_KEYS_I18N_MAP[key]),
            value: i18n.t(ASSET_TYPE_TO_I18N_KEY[value]),
            key,
          };
        }
        case PinsGroupsFilterKey.nameFilterValue: {
          const name = pinsGroupsFilter[key];

          if (!name) {
            return null;
          }

          return {
            label: i18n.t(PINS_GROUPS_FILTER_KEYS_I18N_MAP[key]),
            value: name,
            key,
          };
        }
        default:
          return null;
      }
    });
};

const generateDefaultAssetGroupFiltersSummary = (defaultFilter: DefaultAssetGroupFilters) => {
  return Object.values(DefaultAssetGroupFilterKey)
    .map((key) => {
      switch (key) {
        case DefaultAssetGroupFilterKey.nameFilterValue: {
          const name = defaultFilter[key];

          if (!name) {
            return null;
          }

          return {
            label: i18n.t(DEFAULT_ASSET_GROUP_FILTER_KEYS_I18N_MAP[key]),
            value: name,
            key,
          };
        }
        case DefaultAssetGroupFilterKey.createdFrom:
        case DefaultAssetGroupFilterKey.createdTo: {
          const date = defaultFilter[key];

          if (!date) {
            return null;
          }

          return {
            label: i18n.t(DEFAULT_ASSET_GROUP_FILTER_KEYS_I18N_MAP[key]),
            value: formatDate(date),
            key,
          };
        }
        default:
          return null;
      }
    });
};

export const generateFiltersSummary = ({
  [AssetGroupType.satelliteImages]: satelliteFilter,
  [AssetGroupType.vectorAnalysisMaps]: vectorAnalysisMapsFilter,
  [AssetGroupType.pinsGroups]: pinsGroupsFilter,
  [AssetGroupType.equationMaps]: equationMapsFilter,
  [AssetGroupType.soilDatasets]: soilDatasetsFilter,
  [AssetGroupType.yieldDatasets]: yieldDatasetsFilter,
  [AssetGroupType.asAppliedDatasets]: asAppliedDatasetsFilter,
  [AssetGroupType.topographyMaps]: topographyMapsFilter,
  [AssetGroupType.threeDimensionalMaps]: threeDimensionalMapsFilter,
}: {
  [AssetGroupType.satelliteImages]?: SatelliteFilters,
  [AssetGroupType.vectorAnalysisMaps]?: VectorAnalysisFilters,
  [AssetGroupType.pinsGroups]?: PinsGroupsFilters,
  [AssetGroupType.equationMaps]?: DefaultAssetGroupFilters,
  [AssetGroupType.soilDatasets]?: DefaultAssetGroupFilters,
  [AssetGroupType.yieldDatasets]?: DefaultAssetGroupFilters,
  [AssetGroupType.asAppliedDatasets]?: DefaultAssetGroupFilters,
  [AssetGroupType.topographyMaps]?: DefaultAssetGroupFilters,
  [AssetGroupType.threeDimensionalMaps]?: DefaultAssetGroupFilters,
}): FilterSummary[] => {
  let resultFilters: (FilterSummary | null)[] = [];

  if (satelliteFilter) {
    resultFilters = generateSatelliteFiltersSummary(satelliteFilter);
  } else if (vectorAnalysisMapsFilter) {
    resultFilters = generateVectorAnalysisFiltersSummary(vectorAnalysisMapsFilter);
  } else if (pinsGroupsFilter) {
    resultFilters = generatePinsGroupsFiltersSummary(pinsGroupsFilter);
  } else {
    const defaultFilter = equationMapsFilter
        || soilDatasetsFilter
        || yieldDatasetsFilter
        || asAppliedDatasetsFilter
        || topographyMapsFilter
        || threeDimensionalMapsFilter;

    if (defaultFilter) {
      resultFilters = generateDefaultAssetGroupFiltersSummary(defaultFilter);
    }
  }

  return resultFilters.filter((v) => !!v) as FilterSummary[];
};
