import React, {
  useMemo,
  useCallback,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

import {
  selectAreaUnit,
  selectLastOrder,
  selectMaxArea,
  selectTotalArea,
} from '../../../../user/userSelectors';
import {
  getOrderDaysLeft,
  isFreeTrialPlan,
  isSoftLimitPlan,
  getOrderEndDate,
} from '../../../../user/helpers/functions/order';
import { convertNumberToFormattedString } from '../../../../../helpers/markup';
import { getI18nAreaAmount } from '../../../../user/helpers/functions/user';
import Button from '../../../../../components/Button';
import { getPricingPageLink } from '../../../../../helpers/navigation';
import { convertDateToDisplayFormat } from '../../../../../helpers/functions/utils/date';
import { useAppSelector } from '../../../../../app/store/helpers/functions';
import useReportIssuePopup from '../../../../../hooks/useReportIssuePopup';

import './index.scss';

export default function PricingPlanStatus() {
  const { t } = useTranslation();
  const areaUnit = useAppSelector(selectAreaUnit);
  const maxArea = useAppSelector(selectMaxArea);
  const totalArea = useAppSelector(selectTotalArea);
  const lastOrder = useAppSelector(selectLastOrder);
  const lastOrderEndDate = getOrderEndDate(lastOrder);
  const { openReportIssuePopup } = useReportIssuePopup();

  const handleSubmitRequestButtonClick = useCallback(() => {
    openReportIssuePopup({
      successMessage: t('general.notifications.submit-request-success'),
    });
  }, [t, openReportIssuePopup]);

  const dateComponent = useMemo(() => {
    let i18nKey;
    let values: Record<string, string>;

    if (isFreeTrialPlan(lastOrder)) {
      i18nKey = 'pricing.days-left';
      values = {
        days: getOrderDaysLeft(lastOrderEndDate).toString(),
      };
    } else {
      i18nKey = 'pricing.next-payment';
      values = {
        date: convertDateToDisplayFormat(lastOrderEndDate),
      };
    }

    return (
      <Trans
        i18nKey={i18nKey}
        components={{
          1: <span className="pricing-plan-status__text pricing-plan-status__text_highlighted" />,
        }}
        values={values}
      />
    );
  }, [lastOrder, lastOrderEndDate]);

  const changeLimitButton = useMemo(() => {
    let result;

    if (!isSoftLimitPlan(lastOrder)) {
      result = (
        <Button
          classes={{
            root: 'pricing-plan-status__upgrade-link',
            startIcon: 'pricing-plan-status__upgrade-link-icon',
          }}
          startIcon={<TrendingUpIcon/>}
          to={getPricingPageLink()}
          component={Link}
        >
          {t('pricing.upgrade-plan')}
        </Button>
      );
    } else {
      result = (
        <Button
          classes={{
            root: 'pricing-plan-status__upgrade-link',
            startIcon: 'pricing-plan-status__upgrade-link-icon',
          }}
          startIcon={<TrendingUpIcon/>}
          onClick={handleSubmitRequestButtonClick}
        >
          {t('pricing.increase-limit')}
        </Button>
      );
    }

    return result;
  }, [t, lastOrder, handleSubmitRequestButtonClick]);

  return (
    <div className="pricing-plan-status">
      <div className="pricing-plan-status__item">
        {dateComponent}
      </div>
      <div className="pricing-plan-status__item">
        <Trans
          i18nKey={'pricing.used-area'}
          values={{
            areaUnit: getI18nAreaAmount(areaUnit),
            maxArea: convertNumberToFormattedString(maxArea.toFixed(2)),
            totalArea: convertNumberToFormattedString(totalArea.toFixed(2)),
          }}
        >
          Text
          <span className="pricing-plan-status__text pricing-plan-status__text_highlighted">
            totalArea
          </span>
          <span className="pricing-plan-status__text">
            maxArea
          </span>
        </Trans>
      </div>
      {changeLimitButton}
    </div>
  );
}
