import React, {
  ReactNode,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';

import {
  AssignableAsset,
  DataLayerAssetType,
  DataLayersListFilters,
  Mode,
} from '../../../types/dataLayersList';
import Checkbox from '../../../../../../components/Checkbox';
import {
  areAsAppliedDatasets,
  areSatelliteImages,
  areSoilDatasets,
  areTopographyMaps,
  areVectorAnalysisMaps,
  areYieldDatasets,
} from '../../../../../../helpers/functions/entities/assets';
import {
  ASSET_TYPE_TO_ASSET_GROUP,
  AssetType,
} from '../../../../../../helpers/constants/entities/asset';
import FiltersPopover from '../../../../filters/components/FiltersPopover';
import SatelliteFilter from '../../../../filters/components/SatelliteFilter';
import DefaultAssetGroupFilter from '../../../../filters/components/DefaultAssetGroupFilter';
import { AssetGroupFilterKey } from '../../../../filters/types/assetGroup';
import SelectedFilters from '../../../../filters/components/SelectedFilters';
import { getDefaultAssetGroupFilterValue, getDefaultFilterValue } from '../../../../filters/helpers/functions';
import ZonesMapFilter from '../../../../filters/components/ZonesMapFilter';
import { DEFAULT_VAMAPS_FILTER } from '../../../../filters/helpers/constants/analysis';
import { getVamapTypeFilterOptions } from '../../../../filters/helpers/functions/analysis';

import './index.scss';

interface BaseProps {
  mode: Mode;
  assets: AssignableAsset[];
  filteredAssets: AssignableAsset[];
  filters: DataLayersListFilters;
  onFiltersChange: (filters: Partial<DataLayersListFilters>) => void;
}

interface DefaultHeaderProps extends BaseProps {
  mode: 'default';
}

interface MultiSelectHeaderProps extends BaseProps {
  mode: 'multiSelect';
  checkedUuids: string[];
  recommendedImagesPicker?: ReactNode;
  onSelectAllClick: (checked: boolean, uuids: string[]) => void;
}

export default function ListHeader(props: DefaultHeaderProps | MultiSelectHeaderProps) {
  const { t } = useTranslation();

  const {
    mode,
    assets,
    filteredAssets,
    filters,
    onFiltersChange,
  } = props;
  const multiSelectMode = mode === 'multiSelect';

  let title;
  let assetType: DataLayerAssetType | null = null;
  let filtersValue;
  let assetGroupFilter;
  let result;

  const handleCheckboxClick = () => {
    if (multiSelectMode) {
      const uuids = filteredAssets.map(({ uuid }) => uuid);
      props.onSelectAllClick(!isChecked, uuids);
    }
  };

  const isChecked = useMemo(() => {
    if (!multiSelectMode || props.checkedUuids.length === 0) {
      return 0;
    }

    const checkedUuidsSet = new Set(props.checkedUuids);
    const hasUnchecked = props.filteredAssets.some(({ uuid }) => !checkedUuidsSet.has(uuid));

    return hasUnchecked ? 1 : 2;
  }, [props, multiSelectMode]);

  const handleAllFiltersClear = (type: DataLayerAssetType | null) => {
    if (!type) {
      return;
    }

    const defaultFilter = getDefaultAssetGroupFilterValue(ASSET_TYPE_TO_ASSET_GROUP[type]);
    onFiltersChange({ [type]: defaultFilter });
  };

  const handleFilterClear = (key: AssetGroupFilterKey) => {
    if (!assetType) {
      return;
    }

    const assetTypeFilter = filters[assetType];

    onFiltersChange({
      [assetType]: {
        ...assetTypeFilter,
        [key]: getDefaultFilterValue({
          [ASSET_TYPE_TO_ASSET_GROUP[assetType]]: key,
        }),
      },
    });
  };

  if (areSatelliteImages(assets)) {
    title = t('general.shared.satellite-monitoring');
    assetType = AssetType.satelliteImage;
    filtersValue = filters[assetType];
    assetGroupFilter = (
      <SatelliteFilter
        images={assets}
        value={filtersValue}
        onChange={(f) => onFiltersChange({ [AssetType.satelliteImage]: f })}
      />
    );
  } else if (areSoilDatasets(assets)) {
    title = t('general.shared.soil-data');
    assetType = AssetType.soilDataset;
    filtersValue = filters[assetType];
    assetGroupFilter = (
      <DefaultAssetGroupFilter
        value={filtersValue}
        onChange={(f) => onFiltersChange({ [AssetType.soilDataset]: f })}
      />
    );
  } else if (areYieldDatasets(assets)) {
    title = t('general.shared.yield-data');
    assetType = AssetType.yieldDataset;
    filtersValue = filters[assetType];
    assetGroupFilter = (
      <DefaultAssetGroupFilter
        value={filtersValue}
        onChange={(f) => onFiltersChange({ [AssetType.yieldDataset]: f })}
      />
    );
  } else if (areAsAppliedDatasets(assets)) {
    title = t('general.shared.as-applied-data');
    assetType = AssetType.asAppliedDataset;
    filtersValue = filters[assetType];
    assetGroupFilter = (
      <DefaultAssetGroupFilter
        value={filtersValue}
        onChange={(f) => onFiltersChange({ [AssetType.asAppliedDataset]: f })}
      />
    );
  } else if (areTopographyMaps(assets)) {
    title = t('general.shared.topography');
    assetType = AssetType.topographyMap;
    filtersValue = filters[assetType];
    assetGroupFilter = (
      <DefaultAssetGroupFilter
        value={filtersValue}
        onChange={(f) => onFiltersChange({ [AssetType.topographyMap]: f })}
      />
    );
  } else if (areVectorAnalysisMaps(assets)) {
    const options = getVamapTypeFilterOptions(
      assets,
      DEFAULT_VAMAPS_FILTER.typeFilterValue,
    );
    title = t('general.shared.zones-map');
    assetType = AssetType.vectorAnalysisMap;
    filtersValue = filters[assetType];
    assetGroupFilter = (
      <ZonesMapFilter
        value={filtersValue}
        typeOptions={options}
        onChange={(f) => onFiltersChange({ [AssetType.vectorAnalysisMap]: f })}
      />
    );
  }

  if (assets.length === 0) {
    result = null;
  } else {
    result = (
      <div className="data-layers-list-header">
        <div className={clsx('data-layers-list-header__content', {
          'data-layers-list-header__content_default': mode === 'default',
        })}
        >
          <div className="data-layers-list-header__content-item">
            {mode === 'multiSelect' && <Checkbox value={isChecked} onClick={handleCheckboxClick}/>}
            <Typography className="data-layers-list-header__title">{title}</Typography>
          </div>
          <div className="data-layers-list-header__content-item">
            {assetType === AssetType.satelliteImage && 'recommendedImagesPicker' in props && props.recommendedImagesPicker}
            <FiltersPopover
              onFiltersClear={() => handleAllFiltersClear(assetType)}
            >
              {assetGroupFilter}
            </FiltersPopover>
          </div>
        </div>
        {assetType && filtersValue && (
          <SelectedFilters
            type={ASSET_TYPE_TO_ASSET_GROUP[assetType]}
            filtersValue={filtersValue}
            classes={{
              root: 'data-layers-list-header__selected-filters',
            }}
            onFilterClear={handleFilterClear}
            onFiltersClear={() => handleAllFiltersClear(assetType)}
          />
        )}
      </div>
    );
  }

  return result;
}
