import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  selectDatasetUuid,
  selectStep,
} from '../../../../../cleanCalibrateSelectors';
import Button from '../../../../../../../../components/Button';
import { selectFieldUuid, selectYieldDataset } from '../../../../../../../field/fieldSelectors';
import { useCleanCalibrateYieldDatasetMutation } from '../../../../../cleanCalibrateAPI';
import { useAppDispatch, useAppSelector } from '../../../../../../../../app/store/helpers/functions';
import { setStep } from '../../../../../cleanCalibrateSlice';
import useCalibrateParams from '../../../../../hooks/useCalibrateParams';
import useCleanParams from '../../../../../hooks/useCleanParams';

export default function FastTabRightActions({
  onCancel,
}: {
  onCancel: () => void,
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [cleanCalibrateYieldDataset] = useCleanCalibrateYieldDatasetMutation();
  const step = useAppSelector(selectStep);
  const fieldUuid = useAppSelector(selectFieldUuid);
  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) => selectYieldDataset(state, datasetUuid));
  const calibrateParams = useCalibrateParams();
  const cleanParams = useCleanParams();
  const runAvailable = cleanParams && calibrateParams;

  const handleConfirm = async () => {
    if (!calibrateParams || !cleanParams || !fieldUuid || !dataset?.uuid) {
      return;
    }

    await cleanCalibrateYieldDataset({
      fieldUuid,
      yieldDatasetUuid: dataset.uuid,
      actions: {
        actions: ['CLEAN', 'CALIBRATE'],
        calibrateAction: calibrateParams,
        cleanAction: cleanParams,
      },
    });

    onCancel();
  };

  const handleBack = () => {
    dispatch(setStep(step - 1));
  };

  const handleAdjustConfig = () => {
    dispatch(setStep(step + 1));
  };

  return (
    <>
      {
        step === 0
          ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={onCancel}
            >
              {t('general.controls.cancel')}
            </Button>
          )
          : (
            <Button
              variant="outlined"
              color="primary"
              disableRipple
              onClick={handleBack}
            >
              {t('general.stepper.back')}
            </Button>
          )
      }
      {
        step === 0
          && (
            <Button
              variant={runAvailable ? 'outlined' : 'contained'}
              color="primary"
              onClick={handleAdjustConfig}
            >
              {
                runAvailable
                  ? t('general.controls.adjust')
                  : t('general.controls.proceed')
              }
            </Button>
          )
      }
      {
        (runAvailable || step !== 0)
          && (
            <Button
              variant="contained"
              color="primary"
              disabled={!cleanParams || !calibrateParams}
              onClick={handleConfirm}
            >
              {t('general.controls.run')}
            </Button>
          )
      }
    </>
  );
}
