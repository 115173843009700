import React, { forwardRef, ReactNode, Ref } from 'react';
import type { ExtendButtonBase } from '@material-ui/core';
import Button, {
  ButtonProps,
  ButtonTypeMap,
} from '@material-ui/core/Button';
import clsx from 'clsx';

import './index.scss';

const EnhancedButton = (
  props: ButtonProps,
  ref: Ref<HTMLButtonElement>,
) => {
  const {
    pressed,
    children,
    classes,
    ...otherProps
  } = props as {
    pressed: boolean,
    children?: ReactNode,
    classes: {
      root: string,
      contained: string,
      containedPrimary: string,
      containedSecondary: string,
      startIcon: string,
      endIcon: string,
      label: string,
    },
  };

  return (
    <Button
      ref={ref}
      classes={{
        root: clsx('button', classes && classes.root, {
          button_pressed: pressed,
        }),
        contained: clsx('button_contained', classes && classes.contained),
        containedPrimary: clsx('button_contained-primary', classes && classes.containedPrimary),
        containedSecondary: clsx('button_contained-secondary', classes && classes.containedSecondary),
        startIcon: clsx('button__start-icon', classes && classes.startIcon),
        label: clsx('button__label', classes && classes.label),
        endIcon: classes?.endIcon,
      }}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export default forwardRef(EnhancedButton) as ExtendButtonBase<ButtonTypeMap>;
