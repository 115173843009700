import { DatasetViewType } from '../../constants/entities/dataset';
import { GeoMapTypeOption } from '../../../features/satelliteImages/helpers/constants/geoMapType';
import {
  EquationMapMapConfig,
  SatelliteImageMapConfig,
  SatelliteImageMapParams,
  VectorAnalysisMapConfig,
} from '../../types/map';
import { TransformedSatelliteImage } from '../../../features/satelliteImages/types/satelliteImage';
import { TransformedVectorAnalysisMap } from '../../types/vectorAnalysisMap';
import { TransformedEquationMap } from '../../types/equationMap';
import { AreaUnit } from '../../../features/user/helpers/constants/user';
import { TransformedAsset } from '../../types';
import {
  getEquationMapLayerConfig,
  getSatelliteMapLayerConfig,
  getZonesMapLayerConfig,
  getMapLayerProps,
} from './mapLayerConfig';
import {
  getGeoMapsControlConfig,
  getGeoMapTypeControlConfig,
  getIndexesControlConfig,
  getLegendControlConfig,
  getMiniLegendControlConfig,
  getOverlayLayersControlsConfig,
  getSatelliteImageMiniLegendControlConfig,
  getScoutingAndSamplingControlConfig,
  getViewControlConfig,
  getShowHideControlConfig,
} from './mapControlConfig';

export const getSatelliteImageMapConfig = (
  image?: TransformedSatelliteImage | null,
  {
    geoMapType,
    isRawType,
    viewType,
    satelliteImages,
    overlayLayersControlEnabled,
  }: SatelliteImageMapParams & {
    overlayLayersControlEnabled?: boolean;
  } = {},
): SatelliteImageMapConfig => {
  return {
    ...getSatelliteMapLayerConfig(image, {
      geoMapType,
      isRawType,
      viewType,
    }),
    indexesControl: getIndexesControlConfig(image, {
      satelliteGeoMapType: geoMapType,
      isRawSatelliteType: isRawType,
      satelliteViewType: viewType,
    }),
    showHideControl: getShowHideControlConfig(image),
    miniLegendControl: getSatelliteImageMiniLegendControlConfig(image, {
      geoMapType,
      isRawType,
      viewType,
      satelliteImages,
    }),
    geoMapTypeControl: getGeoMapTypeControlConfig(image, {
      satelliteGeoMapType: geoMapType,
      isRawSatelliteType: isRawType,
    }),
    overlayLayersControls: getOverlayLayersControlsConfig(overlayLayersControlEnabled),
  };
};

export const getZonesMapMapConfig = (
  zonesMap?: TransformedVectorAnalysisMap | null,
  {
    overlayLayersControlEnabled = false,
    showHideControlEnabled = true,
  }: {
    overlayLayersControlEnabled?: boolean;
    showHideControlEnabled?: boolean;
  } = {},
): VectorAnalysisMapConfig => {
  return {
    ...getZonesMapLayerConfig(zonesMap),
    showHideControl: getShowHideControlConfig(zonesMap, showHideControlEnabled),
    legendControl: getLegendControlConfig(zonesMap),
    scoutingAndSamplingControl: getScoutingAndSamplingControlConfig(zonesMap),
    overlayLayersControls: getOverlayLayersControlsConfig(overlayLayersControlEnabled),
  };
};

export const getEquationMapMapConfig = (
  equationMap: TransformedEquationMap | null,
  {
    activeGeoMap,
    overlayLayersControlEnabled = false,
    showHideControlEnabled = true,
  }: {
    activeGeoMap?: string;
    overlayLayersControlEnabled?: boolean;
    showHideControlEnabled?: boolean;
  } = {},
): EquationMapMapConfig => {
  return {
    ...getEquationMapLayerConfig(equationMap, { activeGeoMap }),
    showHideControl: getShowHideControlConfig(
      equationMap,
      showHideControlEnabled,
    ),
    overlayLayersControls: getOverlayLayersControlsConfig(overlayLayersControlEnabled),
    legendControl: getLegendControlConfig(equationMap),
    geoMapsControl: getGeoMapsControlConfig(equationMap, {
      equationMapGeoMap: activeGeoMap,
    }),
  };
};

export const prepareToDisplaySelectedItem = (
  item: TransformedAsset | null | undefined,
  {
    soilViewType = null,
    soilAttribute,
    yieldViewType = null,
    yieldAttribute,
    asAppliedViewType = null,
    asAppliedAttribute,
    satelliteViewType,
    satelliteGeoMapType,
    isRawSatelliteType,
    satelliteImages,
    equationMapGeoMap,
    topographyMapViewType,
    topographyViewTypeControlEnabled = false,
    showHideControlEnabled,
    overlayLayersControlEnabled,
    scoutingAndSamplingEnabled = true,
    datasetAttributesEnabled = true,
    isCreationView = false,
    areaUnit,
  }: {
    soilViewType?: DatasetViewType | null,
    soilAttribute?: string,
    yieldViewType?: DatasetViewType | null,
    yieldAttribute?: string,
    asAppliedViewType?: DatasetViewType | null,
    asAppliedAttribute?: string,
    satelliteViewType?: string;
    satelliteGeoMapType?: GeoMapTypeOption;
    isRawSatelliteType?: boolean;
    satelliteImages?: TransformedSatelliteImage[],
    equationMapGeoMap?: string,
    topographyMapViewType?: string,
    topographyViewTypeControlEnabled?: boolean;
    showHideControlEnabled?: boolean,
    overlayLayersControlEnabled?: boolean,
    scoutingAndSamplingEnabled?: boolean,
    datasetAttributesEnabled?: boolean,
    isCreationView?: boolean,
    areaUnit?: AreaUnit,
  },
) => {
  return {
    ...getMapLayerProps(item, {
      soilAttribute,
      yieldAttribute,
      asAppliedAttribute,
      soilViewType,
      yieldViewType,
      asAppliedViewType,
      satelliteGeoMapType,
      isRawSatelliteType,
      satelliteViewType,
      equationMapGeoMap,
      topographyMapViewType,
      isCreationView,
    }),
    showHideControl: getShowHideControlConfig(item, showHideControlEnabled),
    overlayLayersControls: getOverlayLayersControlsConfig(overlayLayersControlEnabled),
    legendControl: getLegendControlConfig(item),
    indexesControl: getIndexesControlConfig(item, {
      satelliteGeoMapType,
      isRawSatelliteType,
      satelliteViewType,
      topographyMapViewType,
      topographyViewTypeControlEnabled,
      isCreationView,
    }),
    miniLegendControl: getMiniLegendControlConfig(item, {
      soilAttribute,
      yieldAttribute,
      asAppliedAttribute,
      soilViewType,
      yieldViewType,
      asAppliedViewType,
      satelliteGeoMapType,
      isRawSatelliteType,
      satelliteImages,
      satelliteViewType,
      topographyMapViewType,
      areaUnit,
      isCreationView,
    }),
    datasetViewControl: getViewControlConfig(item, {
      soilAttribute,
      yieldAttribute,
      asAppliedAttribute,
      soilViewType,
      yieldViewType,
      asAppliedViewType,
      datasetAttributesEnabled,
    }),
    geoMapsControl: getGeoMapsControlConfig(item, {
      equationMapGeoMap,
    }),
    geoMapTypeControl: getGeoMapTypeControlConfig(item, {
      satelliteGeoMapType,
      isRawSatelliteType,
    }),
    scoutingAndSamplingControl: getScoutingAndSamplingControlConfig(
      item,
      scoutingAndSamplingEnabled,
    ),
  };
};
