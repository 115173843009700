import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Link from '@material-ui/core/Link';

import useReportIssuePopup from '../../../../../hooks/useReportIssuePopup';

import './index.scss';

export default function NoAccessPanel({
  description,
}: {
  description?: string;
}) {
  const { t } = useTranslation();
  const { openReportIssuePopup } = useReportIssuePopup();

  const DEFAULT_DESCRIPTION = 'restricted-access.no-access.app';

  const handleSubmitRequestButtonClick = () => {
    openReportIssuePopup({
      successMessage: t('general.notifications.submit-request-success'),
    });
  };

  return (
    <Paper
      square
      classes={{
        root: 'no-access-card',
      }}
    >
      <div className="no-access-card__container">
        <Typography
          variant="h1"
          classes={{
            root: 'no-access-card__title',
          }}
        >
          {t(description || DEFAULT_DESCRIPTION)}
        </Typography>
        <div className="no-access-card__info">
          <Typography
            classes={{
              root: 'no-access-card__text',
            }}
          >
            <Trans i18nKey="restricted-access.no-access.description">
              Text
              <Link
                className="no-access-card__link link"
                component="button"
                onClick={handleSubmitRequestButtonClick}
              >
                contact us
              </Link> text
            </Trans>
          </Typography>
        </div>
      </div>
      <InfoIcon className="no-access-card__icon"/>
    </Paper>
  );
}
