import React, {
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';

import { ASSET_TYPE_TO_I18N_KEY, AssetType } from '../../../../../helpers/constants/entities/asset';
import DataLayersPreviewItem from '../DataLayersPreviewItem';
import { AssetsLayer } from '../../types/previews';
import { hasNotEmptyLayer } from '../../helpers/functions/previews';
import ToggleButton from '../../../../../components/Map/Controls/common/ToggleButton';

import './index.scss';

const getRefKey = (layerIndex: number, uuid?: string) => `${layerIndex}_${uuid}`;

export default function DataLayersPreviews({
  apiKey,
  expanded,
  layers,
  selectedUuid,
  selectedLayerIndex = 0,
  onItemRemove,
  onItemSelect,
  onExpandedChange,
}: {
  apiKey: string,
  expanded: boolean | null,
  layers: AssetsLayer[],
  selectedUuid: string,
  selectedLayerIndex?: number,
  onItemRemove: (uuid: string, layer: number) => void,
  onItemSelect: (uuid: string, layer: number) => void,
  onExpandedChange: (v: boolean) => void,
}) {
  const { t } = useTranslation();

  const itemRefs = useRef(new Map());

  useEffect(() => {
    const selectedRef = itemRefs.current.get(getRefKey(selectedLayerIndex, selectedUuid));

    if (selectedRef) {
      selectedRef.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  }, [selectedUuid, selectedLayerIndex]);

  const handleItemSelect = (uuid: string, layerIndex: number) => {
    onItemSelect(uuid, layerIndex);
  };

  const handleItemRemove = (uuid: string, layerIndex: number) => {
    onItemRemove(uuid, layerIndex);
  };

  const getLayerItems = (layer: AssetsLayer, layerIndex: number) => {
    let result;

    if (layer.assetType === AssetType.satelliteImage) {
      result = layer.assets.map((asset) => {
        const refKey = getRefKey(layerIndex, asset.uuid);
        return (
          <DataLayersPreviewItem
            key={refKey}
            ref={(el) => itemRefs.current.set(refKey, el)}
            apiKey={apiKey}
            assetType={layer.assetType}
            asset={asset}
            index={layer.index}
            selected={selectedUuid === asset.uuid && selectedLayerIndex === layerIndex}
            onItemSelect={(uuid) => handleItemSelect(uuid, layerIndex)}
            onItemRemove={(uuid) => handleItemRemove(uuid, layerIndex)}
          />
        );
      });
    } else if (
      (layer.assetType === AssetType.soilDataset
        || layer.assetType === AssetType.yieldDataset
        || layer.assetType === AssetType.asAppliedDataset)
      && layer.asset
      && layer.attribute
    ) {
      const refKey = getRefKey(layerIndex, layer.asset?.uuid);
      result = (
        <DataLayersPreviewItem
          key={refKey}
          ref={(el) => itemRefs.current.set(refKey, el)}
          apiKey={apiKey}
          assetType={layer.assetType}
          asset={layer.asset}
          attribute={layer.attribute}
          selected={selectedUuid === layer.asset?.uuid && selectedLayerIndex === layerIndex}
          onItemSelect={(uuid) => handleItemSelect(uuid, layerIndex)}
          onItemRemove={(uuid) => handleItemRemove(uuid, layerIndex)}
        />
      );
    } else if (layer.assetType === AssetType.topographyMap && layer.asset && layer.attribute) {
      const refKey = getRefKey(layerIndex, layer.asset?.uuid);
      result = (
        <DataLayersPreviewItem
          key={refKey}
          ref={(el) => itemRefs.current.set(refKey, el)}
          apiKey={apiKey}
          assetType={layer.assetType}
          asset={layer.asset}
          attribute={layer.attribute}
          selected={selectedUuid === layer.asset?.uuid && selectedLayerIndex === layerIndex}
          onItemSelect={(uuid) => handleItemSelect(uuid, layerIndex)}
          onItemRemove={(uuid) => handleItemRemove(uuid, layerIndex)}
        />
      );
    }

    return result;
  };

  let result;
  const layersTitles: ReactNode[] = [];

  if (hasNotEmptyLayer(layers)) {
    result = (
      <>
        {layers.map((layer, layerIndex) => {
          const attribute = layer.assetType === AssetType.satelliteImage
            ? layer.index
            : layer.attribute;

          const assetTitle = (
            <Typography className="data-layers-previews__layer-title">
              {t(ASSET_TYPE_TO_I18N_KEY[layer.assetType])}
              {layer.assetType === AssetType.satelliteImage && (
                <span className="data-layers-previews__layer-title_highlighted">
                  {` (${layer.assets.length})`}
                </span>
              )}
              <span className="data-layers-previews__layer-title-attribute">
                {attribute && <span>{` | ${attribute}`}</span>}
              </span>
            </Typography>
          );

          layersTitles.push(assetTitle);

          return (
            <div key={layerIndex} className="data-layers-previews__layer">
              {assetTitle}
              <div className="data-layers-previews__layer-items">
                {getLayerItems(layer, layerIndex)}
              </div>
            </div>
          );
        })}
      </>
    );
  }

  return (
    <div className={clsx('data-layers-previews', {
      'data-layers-previews_expanded': expanded,
    })}
    >
      {hasNotEmptyLayer(layers) && (
        <>
          {expanded
            ? (
              <div className="data-layers-previews__content data-layers-previews__content_expanded">
                {result}
              </div>
            )
            : (
              <div className="data-layers-previews__content data-layers-previews__content_collapsed">
                {layersTitles}
              </div>
            )}
        </>
      )}
      {!hasNotEmptyLayer(layers) && (
        <div className={'data-layers-previews__empty'}>
          <Typography className="data-layers-previews__empty-title">
            {t('data-layers-previews.empty')}
          </Typography>
        </div>
      )}
      {expanded !== null && (
        <ToggleButton
          value={expanded}
          classes={{
            root: 'data-layers-previews__toggle-button',
            icon: 'data-layers-previews__toggle-button-icon',
          }}
          compact
          onState={{
            value: true,
            title: t('data-layers-previews.show-layers-preview'),
            name: t('data-layers-previews.show-layers-preview'),
            icon: VisibilityIcon,
          }}
          offState={{
            value: false,
            title: t('data-layers-previews.hide-layers-preview'),
            name: t('data-layers-previews.hide-layers-preview'),
            icon: VisibilityOffIcon,
          }}
          onChange={(v) => onExpandedChange?.(v)}
        />
      )}
    </div>
  );
}
