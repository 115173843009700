import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Overview from '../../../../../../components/Legend/Controls/Overview';
import ZonesMapTable from '../../../../../../components/Views/common/ZonesMapTable';
import { getHexColorCodes } from '../../../../../../helpers/analysis';
import { STORE_NAMES, THREE_D_MAP_STORE_KEY } from '../../../../../../helpers/constants/keyValueBrowserStore';
import useKeyValueBrowserStore from '../../../../../../hooks/useKeyValueBrowserStore';
import { fetchVamapAttributesJson } from '../../../../../field/fieldSlice';
import {
  selectAssetType,
  selectShape,
  selectZonesMapUuid,
} from '../../../createThreeDMapSelectors';
import { selectField, selectVectorAnalysisMap } from '../../../../../field/fieldSelectors';
import { selectAreaUnit } from '../../../../../user/userSelectors';
import { getAssetByUuid } from '../../../../../../helpers/functions/entities/lookup';
import { extractMinMax } from '../../../../../../helpers/functions/entities/3dMap';
import { ASSET_TYPE_TO_I18N_KEY } from '../../../../../../helpers/constants/entities/asset';
import { isNumber } from '../../../../../../helpers/functions/utils/number';

import './index.scss';

const PreviewPanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const areaUnit = useSelector(selectAreaUnit);
  const zonesMapUuid = useSelector(selectZonesMapUuid);
  const assetType = useSelector(selectAssetType);
  const {
    attribute,
    uuid,
    pointsStored,
  } = useSelector(selectShape);
  const field = useSelector(selectField);
  const {
    name: datasetName,
  } = getAssetByUuid(uuid, field);
  const points = useKeyValueBrowserStore(STORE_NAMES.create3d, THREE_D_MAP_STORE_KEY, pointsStored);
  const {
    geoMaps,
    attributes,
    name: zoneMapName,
  } = useSelector((state) => selectVectorAnalysisMap(state, zonesMapUuid)) || {};
  const colors = getHexColorCodes(geoMaps);
  const { min, max } = extractMinMax(points, attribute);

  useEffect(() => {
    const requestVamapAttributes = (anUuid) => {
      dispatch(fetchVamapAttributesJson({ uuid: anUuid }));
    };

    requestVamapAttributes(zonesMapUuid);
  }, [zonesMapUuid, dispatch]);

  return (
    <div className="create-3d-preview-panel">
      <ZonesMapTable
        areaUnit={areaUnit}
        attributes={attributes}
        colors={colors}
      />
      {
        (min != null && max != null)
        && (
          <Overview
            header={t('create-3d.viewer-3d.shape-parameters')}
            items={[
              {
                title: t('zones-ops.multi-layer.steps.4.results-table.min'),
                value: isNumber(min) ? parseFloat(min.toFixed(3)) : min,
              },
              {
                title: t('zones-ops.multi-layer.steps.4.results-table.max'),
                value: isNumber(max) ? parseFloat(max.toFixed(3)) : max,
              },
            ]}
          />
        )
      }
      <Overview
        header={t('general.controls.map.legend')}
        items={[
          {
            title: t('general.controls.map-legend.dataset-type'),
            value: t(ASSET_TYPE_TO_I18N_KEY[assetType]),
          },
          {
            title: t('general.controls.map-legend.dataset-name'),
            value: datasetName,
          },
          {
            title: t('general.controls.map-legend.dataset-attribute'),
            value: attribute,
          },
          {
            title: t('general.controls.map-legend.zones'),
            value: zoneMapName,
          },
        ]}
      />
    </div>
  );
};

export default PreviewPanel;
