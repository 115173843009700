import {
  StripeProductPricingConfig,
  PricingConfig,
  PlanConfig,
  Prices,
} from '../../types/config';

export default function normalizePricingData(
  products: StripeProductPricingConfig[],
): PricingConfig {
  const pricingConfig: PricingConfig = {} as PricingConfig;

  products.forEach((product) => {
    const productKey = product?.productKey;
    const prices = product?.prices;

    if (!prices || !productKey) {
      return;
    }

    prices.forEach((price) => {
      const plan = price?.plan;
      const currency = price?.currency;

      if (!plan || !currency) {
        return;
      }

      if (!pricingConfig[plan]) {
        pricingConfig[plan] = {} as PlanConfig;
      }

      const planConfig = pricingConfig[plan];

      if (!planConfig[productKey]) {
        planConfig[productKey] = {
          prices: {} as Prices,
        };
      }

      const productConfig = planConfig[productKey];

      productConfig.prices[currency] = price;
    });
  });

  return pricingConfig;
}
