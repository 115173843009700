import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../../app/store/helpers/functions';
import { selectCreateAnalysisSatelliteImages } from '../../../../../field/fieldSelectors';
import CreateAnalysisSatelliteImagesPanel from '../../../../dataLayersView/components/DataLayersList/Panels/CreateAnalysisSatelliteImagesPanel';
import useDataLayersList from '../../../../dataLayersView/hooks/useDataLayersList';
import {
  selectActiveUuid,
  selectIndex,
  selectSelectedUuids,
} from '../../../createFieldPotentialZonesSelectors';
import {
  activateImage,
  setIndex,
  selectImages,
  unselectImages,
  setImagesSelection,
} from '../../../createFieldPotentialZonesSlice';
import {
  GFPI_INDEX,
  SATELLITE_INDEXES,
} from '../../../../../../helpers/satellite';
import { AssignableAsset } from '../../../../dataLayersView/types/dataLayersList';
import CreateAnalysisRecommendedImagesPicker from '../../../../dataLayersView/containers/DataLayersList/CreateAnalysisRecommendedImagesPicker';

export default function DataLayersPanel() {
  const dispatch = useAppDispatch();

  const satelliteImages = useAppSelector(selectCreateAnalysisSatelliteImages);
  const selectedSatelliteImageUuid = useAppSelector(selectActiveUuid);
  const checkedSatelliteImagesUuids = useAppSelector(selectSelectedUuids);
  const selectedIndex = useAppSelector(selectIndex);
  const availableIndexes = [...SATELLITE_INDEXES, GFPI_INDEX];

  const handleImageActivate = (asset: AssignableAsset) => {
    dispatch(activateImage(asset.uuid));
  };

  const handleImageSelectionToggle = (value: boolean, uuid: string) => {
    if (value) {
      dispatch(selectImages([uuid]));
    } else {
      dispatch(unselectImages([uuid]));
    }
  };

  const handleAllSelectionToggle = (value: boolean, uuids: string[]) => {
    if (value) {
      dispatch(selectImages(uuids));
    } else {
      dispatch(unselectImages(uuids));
    }
  };

  const handleRecommendedSelect = (uuids: string[]) => {
    dispatch(setImagesSelection(uuids));
  };

  const handleIndexChange = (index: string | null) => {
    if (!index) {
      return;
    }

    dispatch(setIndex(index));
  };

  const panels = [
    <CreateAnalysisSatelliteImagesPanel
      key="top-panel"
      selectedIndex={selectedIndex}
      indexes={availableIndexes}
      onIndexChange={handleIndexChange}
    />,
  ];

  const recommendedImagesPicker = (
    <CreateAnalysisRecommendedImagesPicker
      onRecommendedSelect={handleRecommendedSelect}
    />
  );

  const {
    dataLayersListComponent,
  } = useDataLayersList({
    collapsible: true,
    mode: 'multiSelect',
    assets: satelliteImages,
    selectedUuid: selectedSatelliteImageUuid,
    checkedUuids: checkedSatelliteImagesUuids,
    panels,
    recommendedImagesPicker,
    onAssetClick: handleImageActivate,
    onAssetCheckboxClick: handleImageSelectionToggle,
    onAllCheckboxClick: handleAllSelectionToggle,
  });

  return dataLayersListComponent;
}
