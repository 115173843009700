import React, { ReactNode } from 'react';

import { getSiteKey } from '../../../../../helpers/functions/utils/vendorConfigs/cloudflare';
import useDidMount from '../../../../../hooks/useDidMount';

import './index.scss';

declare global {
  interface Window {
    onTurnstileLoad: () => void;
    turnstile: {
      render: (
        containerId: string,
        options: { sitekey: string; callback: (token: string) => void },
      ) => void;
    };
  }
}

const CONTAINER_ID = 'turnstile-widget-container';

export default function TurnstileWidget({
  errorMessage,
  onVerificationSuccess,
}: {
  errorMessage?: ReactNode,
  onVerificationSuccess: (token: string) => void;
}) {
  useDidMount(() => {
    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit&onload=onTurnstileLoad';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  });

  window.onTurnstileLoad = () => {
    window.turnstile.render(`#${CONTAINER_ID}`, {
      sitekey: getSiteKey(),
      callback: (token: string) => {
        onVerificationSuccess(token);
      },
    });
  };

  return (
    <div className="turnstile-widget">
      <div id={CONTAINER_ID} data-size="flexible"></div>
      <div>{errorMessage}</div>
    </div>
  );
}
