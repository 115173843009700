import { ReactNode } from 'react';

import { DataLayersListNode, AssignableAsset } from '../../types/dataLayersList';
import { TransformedSatelliteImage } from '../../../../satelliteImages/types/satelliteImage';
import {
  TransformedAsAppliedDataset,
  TransformedSoilDataset,
  TransformedTopographyMap,
  TransformedYieldDataset,
} from '../../../../../helpers/types/dataset';
import {
  areAsAppliedDatasets,
  areSatelliteImages,
  areSoilDatasets,
  areTopographyMaps,
  areVectorAnalysisMaps,
  areYieldDatasets,
} from '../../../../../helpers/functions/entities/assets';
import { TransformedVectorAnalysisMap } from '../../../../../helpers/types/vectorAnalysisMap';
import {
  datasetsComparator,
  nameComparator,
} from './comparator';

interface DataLayers {
  assets: AssignableAsset[];
  selectedUuid: string;
  satelliteImageNodeRenderer: (n: DataLayersListNode, d: TransformedSatelliteImage) => ReactNode;
  soilDatasetNodeRenderer: (n: DataLayersListNode, d: TransformedSoilDataset) => ReactNode;
  yieldDatasetNodeRenderer: (n: DataLayersListNode, d: TransformedYieldDataset) => ReactNode;
  asAppliedDatasetNodeRenderer: (n: DataLayersListNode, d: TransformedAsAppliedDataset) => ReactNode;
  topographyMapNodeRenderer: (n: DataLayersListNode, d: TransformedTopographyMap) => ReactNode;
  vectorAnalysisMapNodeRenderer: (n: DataLayersListNode, d: TransformedVectorAnalysisMap) => ReactNode;
}

const createAssetNode = <T extends AssignableAsset>(
  asset: T,
  renderer: (node: DataLayersListNode, asset: T) => ReactNode,
): DataLayersListNode => {
  const node: DataLayersListNode = {
    id: asset.uuid,
    uuid: asset.uuid,
    type: 'asset',
  };

  return {
    ...node,
    getElement: () => renderer(node, asset),
  };
};

export const generateDataLayersList = (dataLayers: DataLayers): DataLayersListNode[] => {
  let result: DataLayersListNode[] = [];

  if (areSatelliteImages(dataLayers.assets)) {
    result = dataLayers.assets.map((image) => {
      return createAssetNode(image, dataLayers.satelliteImageNodeRenderer);
    });
  } else if (areSoilDatasets(dataLayers.assets)) {
    result = [...dataLayers.assets].sort(datasetsComparator).map((dataset) => {
      return createAssetNode(dataset, dataLayers.soilDatasetNodeRenderer);
    });
  } else if (areYieldDatasets(dataLayers.assets)) {
    result = [...dataLayers.assets].sort(datasetsComparator).map((dataset) => {
      return createAssetNode(dataset, dataLayers.yieldDatasetNodeRenderer);
    });
  } else if (areAsAppliedDatasets(dataLayers.assets)) {
    result = [...dataLayers.assets].sort(datasetsComparator).map((dataset) => {
      return createAssetNode(dataset, dataLayers.asAppliedDatasetNodeRenderer);
    });
  } else if (areTopographyMaps(dataLayers.assets)) {
    result = [...dataLayers.assets].sort(nameComparator).map((topographyMap) => {
      return createAssetNode(topographyMap, dataLayers.topographyMapNodeRenderer);
    });
  } else if (areVectorAnalysisMaps(dataLayers.assets)) {
    result = [...dataLayers.assets].sort(nameComparator).map((vamap) => {
      return createAssetNode(vamap, dataLayers.vectorAnalysisMapNodeRenderer);
    });
  } else {
    result = [];
  }

  return result;
};
