import React, {
  Suspense,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Panel from './Panel';
import Loading from '../../../../../../components/Loading';
import Button from '../../../../../../components/Button';
import GeneralPopup from '../../../../../../components/Popups/GeneralPopup';
import { isSatelliteImage } from '../../../../../../helpers/functions/entities/assets';
import { validateVariables } from '../../../../../../helpers/functions/entities/equation';
import {
  selectAsAppliedDatasets,
  selectSoilDatasets,
  selectTopographyMaps,
  selectYieldDatasets,
  selectSatelliteImages,
  selectEquationMaps,
  selectVectorAnalysisMaps,
} from '../../../../../field/fieldSelectors';
import { upsertDataVariable } from '../../../createEquationBasedAnalysisSlice';
import {
  selectDataVariables,
  selectActiveDataVariable,
} from '../../../createEquationBasedAnalysisSelectors';
import {
  prepareVariableAttributes,
  isVariableDataSelected,
  isVariableDataChanged,
  updateDataVariableDataset,
  updateDataVariableAttribute,
  getSelectedAsset,
  populateDataVariableWithAssetAndGeometryType,
} from '../../../helpers/functions/variables';
import { InputDataVariable } from '../../../../dataVariable/types/inputDataVariable';
import { AssignableAsset } from '../../../types/variable';
import { getDataLayerUseInterpolatedDataValue } from '../../../../../createAnalysis/helpers/functions/settings';
import {
  isAsAppliedDatasetVariable,
  isEquationMapVariable,
  isSatelliteImagesVariable,
  isSoilDatasetVariable,
  isTopographyMapVariable,
  isVectorAnalysisMapVariable,
  isYieldDatasetVariable,
} from '../../../../dataVariable/helpers/functions/dataVariable';
import './index.scss';

const Map = React.lazy(() => import('./Map'));

const SelectDataVariablePopup = ({
  onCancel = () => {},
  onConfirm = () => {},
}: {
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeDataVariable = useSelector(selectActiveDataVariable);
  const dataVariables: InputDataVariable[] = useSelector(selectDataVariables);
  const soilDatasets = useSelector(selectSoilDatasets);
  const yieldDatasets = useSelector(selectYieldDatasets);
  const asAppliedDatasets = useSelector(selectAsAppliedDatasets);
  const topographyMaps = useSelector(selectTopographyMaps);
  const satelliteImages = useSelector(selectSatelliteImages);
  const vectorAnalysisMaps = useSelector(selectVectorAnalysisMaps);
  const equationMaps = useSelector(selectEquationMaps);

  const [selectedAsset, setSelectedAsset] = useState<AssignableAsset>();
  const [dataVariable, setDataVariable] = useState<InputDataVariable>(activeDataVariable || { variable: '' });
  const [variableError, setVariableError] = useState('');

  const { datasetAttributes, selectedAttribute } = useMemo(() => prepareVariableAttributes({
    dataVariable,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
  }), [
    dataVariable,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
  ]);
  const isConfirmAvailable = dataVariable.variable
    && !variableError
    && isVariableDataChanged(activeDataVariable || {}, dataVariable)
    && isVariableDataSelected(dataVariable);

  useEffect(() => {
    if (isSoilDatasetVariable(dataVariable)) {
      const soilDataset = getSelectedAsset(soilDatasets, dataVariable.soilDatasetUuid);
      setSelectedAsset(soilDataset);
    } else if (isYieldDatasetVariable(dataVariable)) {
      const yieldDataset = getSelectedAsset(yieldDatasets, dataVariable.yieldDatasetUuid);
      setSelectedAsset(yieldDataset);
    } else if (isAsAppliedDatasetVariable(dataVariable)) {
      const asAppliedDataset = getSelectedAsset(asAppliedDatasets, dataVariable.asAppliedDatasetUuid);
      setSelectedAsset(asAppliedDataset);
    } else if (isTopographyMapVariable(dataVariable)) {
      const topographyMap = getSelectedAsset(topographyMaps, dataVariable.topographyMapUuid);
      setSelectedAsset(topographyMap);
    } else if (isVectorAnalysisMapVariable(dataVariable)) {
      const vectorAnalysisMap = getSelectedAsset(vectorAnalysisMaps, dataVariable.vectorAnalysisMapUuid);
      setSelectedAsset(vectorAnalysisMap);
    } else if (isEquationMapVariable(dataVariable)) {
      const equationMap = getSelectedAsset(equationMaps, dataVariable.equationMapUuid);
      setSelectedAsset(equationMap);
    }
  }, [
    dataVariable,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
    vectorAnalysisMaps,
    equationMaps,
  ]);

  const handleAssetItemClick = (asset: AssignableAsset) => {
    if (isSatelliteImage(asset)) {
      setSelectedAsset(asset);

      return;
    }

    const updatedDataVariable = updateDataVariableDataset(asset, dataVariable);

    if (updatedDataVariable) {
      setDataVariable(updatedDataVariable);
    }
  };

  const handleSatelliteImageCheck = (uuids: string[], selectedUuid?: string) => {
    const updatedDataVariable = {
      variable: dataVariable.variable,
      index: uuids.length && isSatelliteImagesVariable(dataVariable) ? dataVariable.index : '',
      satelliteImageUuids: uuids,
    };

    if (selectedUuid && uuids.find((uuid) => uuid === selectedUuid)) {
      const selectedSatelliteImage = getSelectedAsset(satelliteImages, selectedUuid) || satelliteImages?.[0];
      setSelectedAsset(selectedSatelliteImage);
    }

    setDataVariable(updatedDataVariable);
  };

  const handleAttributeChange = (attribute: string) => {
    const updatedDataVariable = updateDataVariableAttribute(attribute, dataVariable, datasetAttributes);
    setDataVariable(updatedDataVariable);
  };

  const validateUpdatedVariables = (newVariable: string) => {
    const variables = dataVariables
      .filter(({ variable }) => variable !== activeDataVariable?.variable)
      .map(({ variable }) => variable);

    return validateVariables([...variables, newVariable]);
  };

  const handleVariableChange = (variable = '') => {
    const error = validateUpdatedVariables(variable);
    setVariableError(error);
    setDataVariable((prevValue) => ({ ...prevValue, variable }));
  };

  const handleConfirm = () => {
    const extendedDataVariable = populateDataVariableWithAssetAndGeometryType(
      dataVariable,
      { yieldDatasets, asAppliedDatasets },
    );

    dispatch(upsertDataVariable({
      ...dataVariable,
      useInterpolatedData: getDataLayerUseInterpolatedDataValue(extendedDataVariable),
    }));
    onConfirm();
  };

  return (
    <GeneralPopup
      classes={{
        root: 'select-data-variable-popup',
        actions: 'select-data-variable-popup__actions-wrapper',
      }}
      title={t('zones-ops.equation-based.steps.4.popup.manage-variable')}
      onCancel={onCancel}
      onConfirm={handleConfirm}
    >
      <Panel
        key="panel"
        dataVariable={dataVariable}
        variableError={variableError}
        selectedDataset={selectedAsset}
        attributes={datasetAttributes}
        selectedAttribute={selectedAttribute}
        onVariableChange={handleVariableChange}
        onAttributeChange={handleAttributeChange}
        onAssetItemClick={handleAssetItemClick}
        onAssetItemCheck={handleSatelliteImageCheck}
      />
      <Suspense
        key="map"
        fallback={<Loading />}
      >
        <Map
          selectedDataset={selectedAsset}
          selectedAttribute={selectedAttribute}
          onAttributeChange={handleAttributeChange}
          onCancel={onCancel}
        />
      </Suspense>
      <div key="actions" className="select-data-variable-popup__actions-buttons">
        <Button
          onClick={onCancel}
          variant="outlined"
          color="primary"
        >
          {t('general.controls.cancel')}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          disabled={!isConfirmAvailable}
        >
          {t('general.controls.save')}
        </Button>
      </div>
    </GeneralPopup>
  );
};

export default SelectDataVariablePopup;
