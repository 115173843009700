import React, { useMemo } from 'react';

import { useAppSelector, useAppDispatch } from '../../../../../../app/store/helpers/functions';
import {
  setDataLayerSatelliteImageUuid,
  setDataLayerSatelliteIndex,
  selectDatasetDataLayer,
  setDataLayerDatasetAttribute,
} from '../../../createSingleLayerAnalysisSlice';
import { SATELLITE_INDEXES } from '../../../../../../helpers/satellite';
import { selectCreateAnalysisAssets } from '../../../../../field/fieldSelectors';
import { selectDataLayer, selectAssetType } from '../../../createSingleLayerAnalysisSelectors';
import { AssetType } from '../../../../../../helpers/constants/entities/asset';
import CreateAnalysisSatelliteImagesPanel from '../../../../dataLayersView/components/DataLayersList/Panels/CreateAnalysisSatelliteImagesPanel';
import CreateAnalysisDatasetsPanel from '../../../../dataLayersView/components/DataLayersList/Panels/CreateAnalysisDatasetsPanel';
import CreateAnalysisTopographyMapsPanel from '../../../../dataLayersView/components/DataLayersList/Panels/CreateAnalysisTopographyMapsPanel';
import useDataLayersList from '../../../../dataLayersView/hooks/useDataLayersList';
import { AssignableAsset } from '../../../../dataLayersView/types/dataLayersList';
import { findAttributeToPreselect } from '../../../../../../helpers/functions/entities/attribute';

import './index.scss';

export default function DataLayersPanel() {
  const dispatch = useAppDispatch();

  const {
    satelliteIndex: selectedSatelliteIndex,
    satelliteImageUuid: selectedSatelliteImageUuid,
    datasetAttribute: selectedDatasetAttribute,
    datasetUuid: selectedDatasetUuid,
  } = useAppSelector(selectDataLayer);
  const {
    satelliteImages,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
  } = useAppSelector(selectCreateAnalysisAssets);
  const assetType = useAppSelector(selectAssetType);

  const handleAssetClick = (asset: AssignableAsset) => {
    if (assetType === AssetType.satelliteImage) {
      dispatch(setDataLayerSatelliteImageUuid(asset.uuid));
    } else {
      const attribute = findAttributeToPreselect(asset);

      dispatch(selectDatasetDataLayer({
        uuid: asset.uuid,
        attribute,
      }));
    }
  };

  const handleSatelliteIndexChange = (index: string | null) => {
    if (!index) {
      return;
    }

    dispatch(setDataLayerSatelliteIndex(index));
  };

  const handleDatasetAttributeChange = (attribute: string | null) => {
    if (!attribute) {
      return;
    }

    dispatch(setDataLayerDatasetAttribute(attribute));
  };

  const { assets, selectedUuid } = useMemo(() => {
    let assignedAssets: AssignableAsset[] = [];
    let activeLayerSelectedUuid: string = '';

    if (assetType === AssetType.satelliteImage) {
      assignedAssets = satelliteImages;
      activeLayerSelectedUuid = selectedSatelliteImageUuid;
    } else if (assetType === AssetType.soilDataset) {
      assignedAssets = soilDatasets;
      activeLayerSelectedUuid = selectedDatasetUuid;
    } else if (assetType === AssetType.yieldDataset) {
      assignedAssets = yieldDatasets;
      activeLayerSelectedUuid = selectedDatasetUuid;
    } else if (assetType === AssetType.asAppliedDataset) {
      assignedAssets = asAppliedDatasets;
      activeLayerSelectedUuid = selectedDatasetUuid;
    } else if (assetType === AssetType.topographyMap) {
      assignedAssets = topographyMaps;
      activeLayerSelectedUuid = selectedDatasetUuid;
    }

    return {
      assets: assignedAssets,
      selectedUuid: activeLayerSelectedUuid,
    };
  }, [
    assetType,
    satelliteImages,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
    selectedSatelliteImageUuid,
    selectedDatasetUuid,
  ]);

  let panels;

  if (assetType === AssetType.satelliteImage) {
    panels = [
      <CreateAnalysisSatelliteImagesPanel
        key="top-panel"
        selectedIndex={selectedSatelliteIndex}
        indexes={SATELLITE_INDEXES}
        onIndexChange={handleSatelliteIndexChange}
      />,
    ];
  } else if (
    assetType === AssetType.soilDataset
    || assetType === AssetType.yieldDataset
    || assetType === AssetType.asAppliedDataset
  ) {
    panels = [
      <CreateAnalysisDatasetsPanel
        key="top-panel"
        selectedAttribute={selectedDatasetAttribute}
        onAttributeChange={handleDatasetAttributeChange}
      />,
    ];
  } else if (assetType === AssetType.topographyMap) {
    panels = [
      <CreateAnalysisTopographyMapsPanel
        key="top-panel"
        selectedAttribute={selectedDatasetAttribute}
        onAttributeChange={handleDatasetAttributeChange}
      />,
    ];
  }

  const { dataLayersListComponent } = useDataLayersList({
    collapsible: true,
    mode: 'default',
    assets,
    selectedUuid,
    panels,
    onAssetClick: handleAssetClick,
  });

  return (
    <div className="single-data-layer-panel">
      {dataLayersListComponent}
    </div>
  );
}
