import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { getAssetVariableDataAttributeName } from '../../../helpers/functions/variables';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store/helpers/functions';
import { selectCurrentEquation } from '../../../../../equations/equationsSelectors';
import { selectEquationMapData } from '../../../createBatchEquationBasedAnalysisSelectors';
import { POPUPS } from '../../../../popups/helpers/constants/popups';
import { openPopup } from '../../../../popups/popupsSlice';
import Button from '../../../../../../components/Button';
import UseInterpolationCheckbox from '../../../../../../components/OpsPanel/UseInterpolationCheckbox';
import { assignEquationMapData } from '../../../createBatchEquationBasedAnalysisSlice';

import './index.scss';

export default function PreviewTileDatasetsList({
  fieldUuid,
}: {
  fieldUuid: string,
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { dataVariables } = useAppSelector(selectCurrentEquation);
  const { assignedVariables } = useAppSelector((state) => selectEquationMapData(state, fieldUuid));

  const handleEditDataset = (variable: string) => {
    dispatch(openPopup({
      type: POPUPS.assignVariable,
      fieldUuid,
      variable,
    }));
  };

  const handleUseInterpolatedDataChange = (variable: string, useInterpolatedData: boolean) => {
    const assignedVariable = assignedVariables[variable];

    if (assignedVariable) {
      dispatch(assignEquationMapData({
        assignedVariable: {
          fieldUuid,
          variable,
          data: {
            ...assignedVariable,
            useInterpolatedData,
          },
        },
      }));
    }
  };

  return (
    <>
      <Typography className="preview-tile-datasets-list-title">
        {t('batch-equation-based.steps.preview.assigned-datasets')}
      </Typography>
      <div className="preview-tile-datasets-list">
        {dataVariables.map((variable, index) => {
          const assignedVariable = assignedVariables[variable];

          if (assignedVariable) {
            const datasetName = assignedVariable.name;
            const attributeName = getAssetVariableDataAttributeName(assignedVariable);

            return (
              <div
                key={index}
                className="preview-tile-datasets-list-item"
              >
                <div className="preview-tile-datasets-list-item__header">
                  <Typography
                    className="preview-tile-datasets-list-item__header-title"
                    title={variable}
                  >
                    {variable}
                  </Typography>
                  <Button
                    className="preview-tile-datasets-list-item__button"
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => handleEditDataset(variable)}
                  >
                    {t('general.popups.edit')}
                  </Button>
                </div>
                <Typography className="preview-tile-datasets-list-item__sep-text-container text_secondary">
                  <span
                    className="preview-tile-datasets-list-item__sep-text"
                    title={datasetName}
                  >
                    {datasetName}
                  </span>
                  {
                    attributeName
                    && (
                      <>
                        <span>|</span>
                        <span
                          className="preview-tile-datasets-list-item__sep-text"
                          title={attributeName}
                        >
                          {attributeName}
                        </span>
                      </>
                    )
                  }
                </Typography>
                <UseInterpolationCheckbox
                  classes={{
                    root: 'preview-tile-datasets-list-item__use-interpolation-checkbox',
                  }}
                  checked={assignedVariable.useInterpolatedData}
                  onChange={(value) => handleUseInterpolatedDataChange(variable, value)}
                />
              </div>
            );
          }

          return (
            <div
              key={index}
              className="preview-tile-datasets-list-item preview-tile-datasets-list-item_error"
            >
              <div className="preview-tile-datasets-list-item__header">
                <Typography
                  className="preview-tile-datasets-list-item__header-title"
                  title={variable}
                >
                  {variable}
                </Typography>
                <Button
                  className="preview-tile-datasets-list-item__button"
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={() => handleEditDataset(variable)}
                >
                  {t('batch-equation-based.steps.preview.settings-view.choose-dataset')}
                </Button>
              </div>
              <Typography className="preview-tile-datasets-list-item__sep-text-container text_error">
                {t('batch-equation-based.steps.preview.preview-tile.dataset-not-defined')}
              </Typography>
            </div>
          );
        })}
      </div>
    </>
  );
}
