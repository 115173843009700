import React, {
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import {
  isEquationMap,
  isSatelliteImage,
  isSoilDataset,
  isYieldDataset,
  isAsAppliedDataset,
  isTopographyMap,
  isVectorAnalysis,
} from '../../../../../helpers/functions/entities/assets';
import { getAssetByUuid } from '../../../../../helpers/functions/entities/lookup';
import {
  getFieldAssetItemLink,
  getZonesMapLink,
  openInNewTab,
  getZonesMapRatesLink,
  getZonesOpsLink,
  getFieldLink,
  getCompareLayersLink,
  PAGES_ROOTS,
} from '../../../../../helpers/navigation';
import {
  resetLegend,
  openLegend,
  exportZonesMap,
  setSelectedPinsInstrument,
  exportSourceData,
  exportEquationMap,
  exportOriginalData,
  setDataLayersTreeViewType,
  setMapSoilAttributes,
  setMapYieldAttributes,
  setMapAsAppliedAttributes,
  setMapEquationMapGeoMap,
  setMapTopographyMapViewType,
  setMapSatelliteViewType,
} from '../../fieldWorkflowSlice';
import {
  openPopupExportToJohnDeereAsYieldOperation,
  exportToJohnDeereAsMapsLayers,
  exportToJohnDeereAsFiles,
} from '../../../jdExport/jdExportSlice';
import { prepareCloneWorkflow } from '../../../cloneZonesMap/cloneZonesMapSlice';
import { setBackTo } from '../../../zonesMap/zonesMapSlice';
import { openPopup } from '../../../popups/popupsSlice';
import {
  selectField,
  selectJDField,
  selectAllLoading,
  selectSatelliteLoading,
  selectPinsGroups,
  selectVectorAnalysisMaps,
} from '../../../../field/fieldSelectors';
import { selectAreaUnit } from '../../../../user/userSelectors';
import {
  selectDataLayersTreeViewType,
  selectLegend,
  selectSelectedItemGroupType,
  selectSelectedItemUuid,
} from '../../fieldWorkflowSelectors';
import { ZonesOpsWorkflow } from '../../../zonesOps/helpers/constants/workflows';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';
import { isEdit } from '../../../../../helpers/functions/pages/Field';
import {
  deleteAsset,
  generatePdfReport,
  orderPlanetImage,
  renameAsset,
  fetchSynchronizedJohnDeereField,
} from '../../../../field/fieldSlice';
import { MenuAction } from '../../../dataLayersView/helpers/constants/dataLayersTree';
import useAllFieldAssets from '../../../../field/hooks/useAllFieldAssets';
import { fetchProfileJohnDeere } from '../../../../jdProfile/jdProfileSlice';
import { selectJDIntegrationData } from '../../../../jdProfile/jdProfileSelectors';
import LayersSelectedPanel from '../../../dataLayersView/components/DataLayersTree/Panels/LayersSelectedPanel';
import ExportLimitDataLayersTreePanel from '../../components/ExportLimitDataLayersTreePanel';
import LayersLimitDataLayersTreePanel from '../../../compareLayers/components/LayersLimitDataLayersTreePanel';
import { getAssetHasPins } from '../../../../pins/helpers/functions/pinsGroup';
import useDataLayersTree from '../../../dataLayersView/hooks/useDataLayersTree';
import { isFieldWorkflowAssetsGroupingEnabled } from '../../../../../helpers/functions/utils/appConfig';
import { POPUPS } from '../../../popups/helpers/constants/popups';
import { setDatasetUuid } from '../../../cleanCalibrate/cleanCalibrateSlice';
import {
  AssetGroupType,
  AssetType,
} from '../../../../../helpers/constants/entities/asset';
import { addLayers } from '../../../compareLayers/compareLayersSlice';
import { transformSelectedLayersToCompareLayersData } from '../../../compareLayers/helpers/functions/layers';
import { DataLayersTreeViewType } from '../../helpers/constants/viewType';
import { getDataLayersTreeMode } from '../../helpers/functions/mode';
import { getParentAssetId } from '../../../dataLayersView/helpers/functions/dataLayersTree';
import { getRatesData } from '../../../../../helpers/analysis';
import { openPopupExportToJohnDeereAsWorkPlan } from '../../../jdWorkPlan/jdWorkPlanSlice';
import { useGetFarmQuery } from '../../../../farms/farmsAPI';
import { getJDFieldOrgId } from '../../../../field/helpers/functions/field';
import { isZonesOpsWorkflow } from '../../../zonesOps/helpers/functions/workflowOptions';

const DataLayersPanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    search,
  } = useLocation();
  const {
    farmUuid: routerFarmUuid,
    fieldUuid: routerFieldUuid,
  } = useParams();
  const {
    jdProfileIsHealth,
    jdProfileIsAuthorized,
    jdWorkPlanExportAvailable,
    isSynchronizedJohnDeereField,
    jdProfileLoaded,
  } = useSelector(selectJDIntegrationData);
  const loading = useSelector(selectAllLoading);
  const satellitesLoading = useSelector(selectSatelliteLoading);
  const isLegendOpen = useSelector(selectLegend).uuid;
  const vectorAnalysisMaps = useSelector(selectVectorAnalysisMaps);
  const pinsGroups = useSelector(selectPinsGroups);
  const selectedItemUuid = useSelector(selectSelectedItemUuid);
  const selectedItemGroupType = useSelector(selectSelectedItemGroupType);
  const viewType = useSelector(selectDataLayersTreeViewType);
  const field = useSelector(selectField);
  const areaUnit = useSelector(selectAreaUnit);
  const jdField = useSelector(selectJDField);
  const orgId = getJDFieldOrgId(jdField);

  const {
    farmUuid,
    uuid: fieldUuid,
    satelliteImages,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
    equationMaps,
    threeDimensionalMaps,
  } = useAllFieldAssets({
    farmUuid: routerFarmUuid,
    fieldUuid: routerFieldUuid,
    requiredSatImages: selectedItemUuid && selectedItemGroupType === AssetGroupType.satelliteImages
      ? [selectedItemUuid]
      : undefined,
  }) || {};

  const {
    data: farm,
  } = useGetFarmQuery({ farmUuid });

  const [checkedLayers, setCheckedLayers] = useState({});
  const mode = getDataLayersTreeMode(viewType);
  const isCompareLayersViewType = viewType === DataLayersTreeViewType.compareLayers;

  useEffect(() => {
    setCheckedLayers({});
  }, [viewType, setCheckedLayers]);

  useEffect(() => {
    dispatch(fetchSynchronizedJohnDeereField({
      fieldUuid: routerFieldUuid,
    }));
  }, [routerFieldUuid, dispatch]);

  const handleAssetNodeClick = (type, item) => {
    if (item.uuid === selectedItemUuid && type === selectedItemGroupType) {
      return;
    }

    AmplitudeAnalytics.trackFieldAssetClicked({ type });
    history.push(getFieldAssetItemLink(farmUuid, fieldUuid, type, item.uuid, search));

    if (isLegendOpen) {
      dispatch(resetLegend());

      if (!isVectorAnalysis(item)) {
        return;
      }

      dispatch(openLegend(item));
    }

    dispatch(setSelectedPinsInstrument());
  };

  const handleMenuItemClick = (menuItem, item) => {
    if (menuItem === MenuAction.goToMap) {
      openInNewTab(`#${getZonesMapLink(farmUuid, fieldUuid, item.uuid)}`);
    } else if (menuItem === MenuAction.assignRates) {
      dispatch(setBackTo('field'));
      history.push(getZonesMapRatesLink(farmUuid, fieldUuid, item.uuid));
    } else if (menuItem === MenuAction.exportMap) {
      dispatch(exportZonesMap(item.uuid));
    } else if (menuItem === MenuAction.downloadEquationMap) {
      dispatch(exportEquationMap(item.uuid));
    } else if (menuItem === MenuAction.downloadSourceData) {
      dispatch(exportSourceData(item));
    } else if (menuItem === MenuAction.downloadOriginalData) {
      dispatch(exportOriginalData(item));
    } else if (menuItem === MenuAction.exportToJDAsFiles) {
      let entityType = '';

      if (isVectorAnalysis(item)) {
        dispatch(exportToJohnDeereAsFiles({
          orgId,
          vectorAnalysisMaps: [{ uuid: item.uuid, name: item.name, fieldUuid }],
        }));
        entityType = 'zonesMap';
      } else if (isEquationMap(item)) {
        dispatch(exportToJohnDeereAsFiles({
          orgId,
          equationMaps: [{ uuid: item.uuid, name: item.name, fieldUuid }],
        }));
        entityType = 'equationMap';
      }

      if (entityType) {
        AmplitudeAnalytics.trackEntityExportedToJD({
          page: PAGES_ROOTS.field,
          exportType: 'asFiles',
          entityType,
        });
      }
    } else if (menuItem === MenuAction.exportToJDAsMapsLayers) {
      let entityType = '';

      if (isVectorAnalysis(item)) {
        dispatch(exportToJohnDeereAsMapsLayers({ fieldUuid, vectorAnalysisMapUuid: item.uuid }));
        entityType = 'zonesMap';
      } else if (isEquationMap(item)) {
        dispatch(exportToJohnDeereAsMapsLayers({ fieldUuid, equationMapUuid: item.uuid }));
        entityType = 'equationMap';
      } else if (isSoilDataset(item)) {
        dispatch(exportToJohnDeereAsMapsLayers({ fieldUuid, soilDatasetUuid: item.uuid }));
        entityType = 'soilDataset';
      } else if (isYieldDataset(item)) {
        dispatch(exportToJohnDeereAsMapsLayers({ fieldUuid, yieldDatasetUuid: item.uuid }));
        entityType = 'yieldDataset';
      } else if (isTopographyMap(item)) {
        dispatch(exportToJohnDeereAsMapsLayers({ fieldUuid, topographyMapUuid: item.uuid }));
        entityType = 'topographyMap';
      } else if (isSatelliteImage(item)) {
        dispatch(exportToJohnDeereAsMapsLayers({ fieldUuid, satelliteImageUuid: item.uuid }));
        entityType = 'satelliteImage';
      }

      if (entityType) {
        AmplitudeAnalytics.trackEntityExportedToJD({
          page: PAGES_ROOTS.field,
          exportType: 'asMapsLayers',
          entityType,
        });
      }
    } else if (menuItem === MenuAction.exportToJDAsYieldOperation) {
      dispatch(openPopupExportToJohnDeereAsYieldOperation({
        items: [{
          uuid: item.uuid,
          name: item.name,
          fieldUuid,
        }],
        fieldName: field.name,
        farmName: farm?.name,
        onConfirm: () => {
          AmplitudeAnalytics.trackEntityExportedToJD({
            page: PAGES_ROOTS.field,
            entityType: 'yieldDataset',
            exportType: 'asYieldOperation',
          });
        },
      }));
    } else if (menuItem === MenuAction.cloneZonesMap) {
      dispatch(prepareCloneWorkflow({
        vamap: item,
        farmUuid,
        fieldUuid,
      }));
      history.push(getZonesOpsLink(
        farmUuid,
        fieldUuid,
        ZonesOpsWorkflow.cloneZonesMap,
        true,
      ));
    } else if (
      menuItem === MenuAction.renameZonesMap
      || menuItem === MenuAction.renameSoilDataset
      || menuItem === MenuAction.renameYieldDataset
      || menuItem === MenuAction.renameAsAppliedDataset
      || menuItem === MenuAction.renameTopographyMap
      || menuItem === MenuAction.rename3DMap
      || menuItem === MenuAction.renameEquationMap
    ) {
      dispatch(openPopup({
        type: menuItem,
        entityName: item.name,
        onConfirm: (name) => {
          dispatch(renameAsset({
            name,
            uuid: item.uuid,
          }));
        },
      }));
    } else if (
      menuItem === MenuAction.deleteZonesMap
      || menuItem === MenuAction.deleteSoilDataset
      || menuItem === MenuAction.deleteYieldDataset
      || menuItem === MenuAction.deleteAsAppliedDataset
      || menuItem === MenuAction.deleteTopographyMap
      || menuItem === MenuAction.delete3DMap
      || menuItem === MenuAction.deleteEquationMap
    ) {
      dispatch(openPopup({
        type: menuItem,
        entityName: item.name,
        shouldDisplayPinsWarning: getAssetHasPins(item.uuid, pinsGroups),
        onConfirm: () => {
          history.push(getFieldLink(farmUuid, fieldUuid));
          dispatch(deleteAsset(item.uuid));
        },
      }));
    } else if (menuItem === MenuAction.orderPlanetImage) {
      dispatch(orderPlanetImage({
        fieldUuid,
        satelliteImageUuid: item.uuid,
      }));
    } else if (menuItem === MenuAction.exportToJDAsWorkPlan) {
      const resourceToExport = {
        uuid: item.uuid,
        name: item.name,
        type: item.type,
        fieldUuid,
      };

      if (isVectorAnalysis(item)) {
        const ratesUnits = getRatesData(item.attributes, 'ratesUnits');

        dispatch(openPopupExportToJohnDeereAsWorkPlan({
          areaUnit,
          selectedAsset: {
            ...resourceToExport,
            resourceType: AssetType.vectorAnalysisMap,
            unit: ratesUnits[0],
          },
        }));
      } else if (isEquationMap(item)) {
        dispatch(openPopupExportToJohnDeereAsWorkPlan({
          areaUnit,
          selectedAsset: {
            ...resourceToExport,
            resourceType: AssetType.equationMap,
            unit: item.productUnit,
          },
        }));
      }
    }
  };

  const handleAttributeNodeClick = (itemNode) => {
    if (!itemNode || !itemNode.attributes) {
      return;
    }

    const { attributes, assetGroupType } = itemNode;
    const parentAssetId = getParentAssetId(itemNode);
    const parentAsset = getAssetByUuid(parentAssetId, field);

    if (parentAsset.uuid !== selectedItemUuid) {
      handleAssetNodeClick(assetGroupType, parentAsset);
    }

    if (isSoilDataset(parentAsset)) {
      dispatch(setMapSoilAttributes(attributes));
    } else if (isYieldDataset(parentAsset)) {
      dispatch(setMapYieldAttributes(attributes));
    } else if (isAsAppliedDataset(parentAsset)) {
      dispatch(setMapAsAppliedAttributes(attributes));
    } else if (isEquationMap(parentAsset)) {
      dispatch(setMapEquationMapGeoMap(attributes.attribute));
    } else if (isTopographyMap(parentAsset)) {
      dispatch(setMapTopographyMapViewType(attributes.attribute));
    } else if (isSatelliteImage(parentAsset)) {
      dispatch(setMapSatelliteViewType(attributes.attribute));
    }
  };

  const handlePlaceholderActionClick = (assetGroupType, action) => {
    let workflow = action;

    if (assetGroupType === AssetGroupType.threeDimensionalMaps) {
      workflow = ZonesOpsWorkflow.threeDMap;
    } else if (assetGroupType === AssetGroupType.equationMaps) {
      workflow = ZonesOpsWorkflow.equationBased;
    }

    if (isZonesOpsWorkflow(workflow)) {
      AmplitudeAnalytics.trackCreateAnalysisMapWorkflowSelected({
        page: PAGES_ROOTS.field,
        workflow,
      });
    }
  };

  const handleCheckedLayersChange = (newValue) => {
    setCheckedLayers(newValue);
  };

  const setDefaultDataLayersTreeViewType = () => {
    dispatch(setDataLayersTreeViewType({
      viewType: DataLayersTreeViewType.default,
    }));
  };

  const handleConfirmExportClick = (exportAssetsData) => {
    dispatch(generatePdfReport({
      exportAssetsData,
    }));
    setDefaultDataLayersTreeViewType();
  };

  const handleConfirmCompareLayersClick = (checkedAssetsData) => {
    setCheckedLayers({});
    const layers = transformSelectedLayersToCompareLayersData(checkedAssetsData, field);
    dispatch(addLayers({ layers }));
    history.push(getCompareLayersLink(
      farmUuid,
      fieldUuid,
    ));
    setDefaultDataLayersTreeViewType();
  };

  const handleCleanCalibrateDatasetClick = (dataset) => {
    dispatch(setDatasetUuid(dataset.uuid));
    dispatch(openPopup({
      type: POPUPS.cleanCalibrateYield,
    }));
  };

  useEffect(() => {
    if (!jdProfileLoaded) {
      dispatch(fetchProfileJohnDeere());
    }
  });

  const children = [
    ...(viewType !== DataLayersTreeViewType.default
      ? [<LayersSelectedPanel
          key="top-panel"
          checked={checkedLayers}
          mode={mode}
          onSelectAllChange={handleCheckedLayersChange}
         />]
      : []),
    ...(viewType === DataLayersTreeViewType.exportPdf
      ? [<ExportLimitDataLayersTreePanel
          key="bottom-panel"
          checked={checkedLayers}
          mode={mode}
          onCancelClick={setDefaultDataLayersTreeViewType}
          onConfirmClick={handleConfirmExportClick}
         />]
      : []),
    ...(viewType === DataLayersTreeViewType.compareLayers
      ? [<LayersLimitDataLayersTreePanel
          key="bottom-panel"
          checked={checkedLayers}
          mode={mode}
          onCancelClick={setDefaultDataLayersTreeViewType}
          onConfirmClick={handleConfirmCompareLayersClick}
         />]
      : []),
  ];

  const { dataLayersTreeComponent } = useDataLayersTree({
    assets: {
      satelliteImages,
      vectorAnalysisMaps,
      soilDatasets,
      yieldDatasets,
      asAppliedDatasets,
      topographyMaps,
      threeDimensionalMaps,
      equationMaps,
      pinsGroups,
    },
    farmUuid,
    fieldUuid,
    mode,
    withAttributes: true,
    withDatasetViewType: isCompareLayersViewType,
    withGeoMapAttributes: isCompareLayersViewType,
    satelliteImagesLoading: satellitesLoading && !loading,
    jdProfileIsHealth,
    jdProfileIsAuthorized,
    jdWorkPlanExportAvailable,
    isSynchronizedJohnDeereField,
    showItemMenu: !isEdit(search),
    showEmptyGroups: true,
    showCleanCalibrateDataset: true,
    publishedDatasetsClickable: true,
    collapsible: true,
    checked: checkedLayers,
    selectedAssetUuid: selectedItemUuid,
    selectedAssetGroupType: selectedItemGroupType,
    grouping: isFieldWorkflowAssetsGroupingEnabled(),
    children,
    onAssetNodeClick: handleAssetNodeClick,
    onMenuItemClick: handleMenuItemClick,
    onAttributeNodeClick: handleAttributeNodeClick,
    onGroupPlaceholderActionClick: handlePlaceholderActionClick,
    onCheckedChange: handleCheckedLayersChange,
    onCleanCalibrateDatasetClick: handleCleanCalibrateDatasetClick,
  });

  return (
    <>
      { dataLayersTreeComponent }
    </>
  );
};

export default DataLayersPanel;
